import * as types from './types';

// deleteMember
export const deleteMember = (payload) => ({
  type: types.DELETE_MEMBER,
  payload,
});
export const deleteCompanyMemberSuccess = (payload) => ({
  type: types.DELETE_MEMBER_SUCCESS,
  payload,
});
export const deleteCompanyMemberFail = (payload) => ({
  type: types.DELETE_MEMBER_FAIL,
  payload,
});

// getMembers
export const getMembers = (payload) => ({
  type: types.GET_MEMBERS,
  payload,
});
export const getMembersSuccess = (payload) => ({
  type: types.GET_MEMBERS_SUCCESS,
  payload,
});
export const getMembersFail = (payload) => ({
  type: types.GET_MEMBERS_FAIL,
  payload,
});

// getMembersById
export const getMemberById = (payload) => ({
  type: types.GET_MEMBER_BY_ID,
  payload,
});
export const getMemberByIdSuccess = (payload) => ({
  type: types.GET_MEMBER_BY_ID_SUCCESS,
  payload,
});
export const getMemberByIdFail = (payload) => ({
  type: types.GET_MEMBER_BY_ID_FAIL,
  payload,
});

// resetMemberField
export const resetMemberField = (payload) => ({
  type: types.RESET_MEMBER_FIELD,
  payload,
});

// createMember
export const createMember = (payload) => {
  // eslint-disable-next-line no-console
  console.log('createMember called...');
  return ({
    type: types.CREATE_MEMBER,
    payload,
  });
};
export const createMemberSuccess = (payload) => ({
  type: types.CREATE_MEMBER_SUCCESS,
  payload,
});
export const createMemberFail = (payload) => ({
  type: types.CREATE_MEMBER_FAIL,
  payload,
});

// updateMember
export const updateMember = (payload) => ({
  type: types.UPDATE_MEMBER,
  payload,
});
export const updateMemberSuccess = (payload) => ({
  type: types.UPDATE_MEMBER_SUCCESS,
  payload,
});
export const updateMemberFail = (payload) => ({
  type: types.UPDATE_MEMBER_FAIL,
  payload,
});
