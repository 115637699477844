/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { Route, Redirect } from 'react-router-dom';

import { Layout } from 'components/layout';

import { ADMIN_PREFIX, USER_PREFIX, HUNTER_PREFIX } from 'constants/roles';
import { URLS } from 'constants/index';
import { decodeJwt } from 'utils/decode-jwt';

const checkAccess = (jwt, cfg) => {
  if (!cfg) return true;
  return Object.keys(cfg).reduce((acc, el) => {
    if (acc) return acc;
    if (!jwt.id.includes(el)) return false;

    if (jwt.id.includes(el) && cfg[el].length === 0) return true;

    return cfg[el].some((e) => jwt.role.includes(e));
  }, false);
};

const getRedirectPath = (jwt) => {
  if (jwt.id.includes(ADMIN_PREFIX)) return URLS.adminLogin;
  if (jwt.id.includes(USER_PREFIX)) return URLS.companyAuth;
  if (jwt.id.includes(HUNTER_PREFIX)) return URLS.hunterAuth;

  return '/';
};

const nextJsPages = Object.freeze({
  '/': '/',
  '/continue-registration': '/continue-registration',
  '/aszf': '/aszf',
  '/adatvedelem': '/adatvedelem',
  '/reset-password': '/reset-password',
  '/search-results': '/search-results',
  '/thank-you': '/thank-you',
  '/thanks': '/thanks',
});

export const PrivateRoute = ({
  component: Component,
  exact = true,
  path,
  header,
  accessConfig,
  location,
  layoutConfig,
  ctx = {},
}) => {
  if (nextJsPages[location.pathname]) {
    window.location.href = process.env.REACT_APP_NEXT_APP_URL + nextJsPages[location.pathname];
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        const jwt = decodeJwt();
        if (!jwt && accessConfig !== null) return <Redirect to="/" />;

        if (!checkAccess(jwt, accessConfig)) return <Redirect to={getRedirectPath(jwt)} />;

        return (
          <Suspense fallback={null}>
            <I18nextProvider i18n={i18next}>
              <Layout header={header} {...layoutConfig}>
                <Component {...props} ctx={ctx} />
              </Layout>
            </I18nextProvider>
          </Suspense>
        );
      }}
    />
  );
};
