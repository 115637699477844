import * as types from './types';

export const orderCompanyAdminAction = (payload) => ({
  type: types.ORDER_COMPANY_ADMIN_ACTION,
  payload,
});

export const orderAdminAction = (payload) => ({
  type: types.ORDER_ADMIN_ACTION,
  payload,
});

export const addDocuments = (payload) => ({
  type: types.ADD_DOCUMENTS,
  payload,
});

export const removeDocument = (payload) => ({
  type: types.REMOVE_DOCUMENT,
  payload,
});

export const removeDocumentSuccess = (payload) => ({
  type: types.REMOVE_DOCUMENT_SUCCESS,
  payload,
});
