import * as types from './types';

// getAllPriceLists
export const getAllPriceLists = (payload) => ({
  type: types.GET_ALL_PRICE_LISTS,
  payload,
});
export const getAllPriceListsSuccess = (payload) => ({
  type: types.GET_ALL_PRICE_LISTS_SUCCESS,
  payload,
});
export const getAllPriceListsFail = (payload) => ({
  type: types.GET_ALL_PRICE_LISTS_FAIL,
  payload,
});

// getPriceListById
export const getPriceListById = (payload) => ({
  type: types.GET_PRICE_LIST_BY_ID,
  payload,
});
export const getPriceListByIdSuccess = (payload) => ({
  type: types.GET_PRICE_LIST_BY_ID_SUCCESS,
  payload,
});
export const getPriceListByIdFail = (payload) => ({
  type: types.GET_PRICE_LIST_BY_ID_FAIL,
  payload,
});

// createPriceList
export const createPriceList = (payload) => ({
  type: types.CREATE_PRICE_LIST,
  payload,
});
export const createPriceListSuccess = (payload) => ({
  type: types.CREATE_PRICE_LIST_SUCCESS,
  payload,
});
export const createPriceListFail = (payload) => ({
  type: types.CREATE_PRICE_LIST_FAIL,
  payload,
});

// updatePriceList
export const updatePriceList = (payload) => ({
  type: types.UPDATE_PRICE_LIST,
  payload,
});
export const updatePriceListSuccess = (payload) => ({
  type: types.UPDATE_PRICE_LIST_SUCCESS,
  payload,
});
export const updatePriceListFail = (payload) => ({
  type: types.UPDATE_PRICE_LIST_FAIL,
  payload,
});

// removePriceList
export const removePriceList = (payload) => ({
  type: types.REMOVE_PRICE_LIST,
  payload,
});
export const removePriceListSuccess = (payload) => ({
  type: types.REMOVE_PRICE_LIST_SUCCESS,
  payload,
});
export const removePriceListFail = (payload) => ({
  type: types.REMOVE_PRICE_LIST_FAIL,
  payload,
});

// other
export const resetPriceListFields = (payload) => ({
  type: types.RESET_PRICE_LIST_FIELDS,
  payload,
});

export const changePriceListFields = (payload) => ({
  type: types.CHANGE_PRICE_LIST_FIELDS,
  payload,
});

export const setInterval = (payload) => ({
  type: types.SET_INTERVAL,
  payload,
});

export const addNewInterval = (payload) => ({
  type: types.ADD_NEW_INTERVAL,
  payload,
});
