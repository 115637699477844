import * as types from './types';

// setPaymentConfirmedStatus
export const setPaymentConfirmedStatus = (payload) => ({
  type: types.SET_PAYMENT_CONFIRMED_STATUS,
  payload,
});

// setContractSignedStatus
export const setContractSignedStatus = (payload) => ({
  type: types.SET_CONTRACT_SIGNED_STATUS,
  payload,
});

// setWaitingForPaymentStatus
export const setWaitingForPaymentStatus = (payload) => ({
  type: types.SET_WAITING_FOR_PAYMENT_STATUS,
  payload,
});

// setMappingMeetingStatus
export const setMappingMeetingStatus = (payload) => ({
  type: types.SET_MAPPING_MEETING_STATUS,
  payload,
});

// setConfirmedStatus
export const setConfirmedStatus = (payload) => ({
  type: types.SET_CONFIRMED_STATUS,
  payload,
});

// setViewedCompany
export const setViewedCompany = (payload) => ({
  type: types.SET_VIEWED_COMPANY,
  payload,
});
export const setViewedCompanySuccess = (payload) => ({
  type: types.SET_VIEWED_COMPANY_SUCCESS,
  payload,
});
export const setViewedCompanyFail = (payload) => ({
  type: types.SET_VIEWED_COMPANY_FAIL,
  payload,
});
