import * as types from './types';
import { initialStore } from '../initial-store';

export const memberReducer = (state = initialStore.member, { type, payload }) => {
  switch (type) {
    case types.GET_MEMBERS: {
      const members = { ...state.members, loading: true, page: payload.page };
      return { ...state, members };
    }
    case types.GET_MEMBERS_SUCCESS: {
      const members = { ...state.members, ...payload, loading: false };
      return {
        ...state, members,
      };
    }
    case types.GET_MEMBERS_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_MEMBER_BY_ID: {
      return { ...state, isFetching: true, member: null };
    }
    case types.GET_MEMBER_BY_ID_SUCCESS: {
      return { ...state, isFetching: false, member: payload.data };
    }
    case types.GET_MEMBER_BY_ID_FAIL: {
      return { ...state };
    }

    case types.CREATE_MEMBER: {
      return { ...state };
    }
    case types.CREATE_MEMBER_SUCCESS: {
      return { ...state };
    }
    case types.CREATE_MEMBER_FAIL: {
      return { ...state };
    }

    case types.UPDATE_MEMBER: {
      return { ...state };
    }
    case types.UPDATE_MEMBER_SUCCESS: {
      return { ...state };
    }
    case types.UPDATE_MEMBER_FAIL: {
      return { ...state };
    }

    case types.RESET_MEMBER_FIELD: {
      return { ...state, member: null };
    }

    default:
      return state;
  }
};
