import * as types from './types';

// adminSignIn
export const adminSignIn = (payload) => ({
  type: types.ADMIN_SIGN_IN,
  payload,
});
export const adminSignInSuccess = (payload) => ({
  type: types.ADMIN_SIGN_IN_SUCCESS,
  payload,
});
export const adminSignInFail = (payload) => ({
  type: types.ADMIN_SIGN_IN_FAIL,
  payload,
});

// hunterSignIn
export const hunterSignIn = (payload) => ({
  type: types.HUNTER_SIGN_IN,
  payload,
});
export const hunterSignInSuccess = (payload) => ({
  type: types.HUNTER_SIGN_IN_SUCCESS,
  payload,
});
export const hunterSignInFail = (payload) => ({
  type: types.HUNTER_SIGN_IN_FAIL,
  payload,
});

// hunterSignUp
export const hunterSignUp = (payload) => ({
  type: types.HUNTER_SIGN_UP,
  payload,
});
export const hunterSignUpSuccess = (payload) => ({
  type: types.HUNTER_SIGN_UP_SUCCESS,
  payload,
});
export const hunterSignUpFail = (payload) => ({
  type: types.HUNTER_SIGN_UP_FAIL,
  payload,
});

// companySignIn
export const companySignIn = (payload) => ({
  type: types.COMPANY_SIGN_IN,
  payload,
});
export const companySignInSuccess = (payload) => ({
  type: types.COMPANY_SIGN_IN_SUCCESS,
  payload,
});
export const companySignInFail = (payload) => ({
  type: types.COMPANY_SIGN_IN_FAIL,
  payload,
});

// companySignUp
export const companySignUp = (payload) => ({
  type: types.COMPANY_SIGN_UP,
  payload,
});
export const companySignUpSuccess = (payload) => ({
  type: types.COMPANY_SIGN_UP_SUCCESS,
  payload,
});
export const companySignUpFail = (payload) => ({
  type: types.COMPANY_SIGN_UP_FAIL,
  payload,
});

// logOut
export const logOut = (payload) => ({
  type: types.LOG_OUT,
  payload,
});
export const logOutSuccess = (payload) => ({
  type: types.LOG_OUT_SUCCESS,
  payload,
});
export const logOutFail = (payload) => ({
  type: types.LOG_OUT_FAIL,
  payload,
});
