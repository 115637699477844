import {
  call, takeLatest, put, delay, select,
} from 'redux-saga/effects';

import { URLS } from 'constants/index';
import { setTokenToCookie } from 'utils/cookie-actions';

import * as types from './types';
import * as actions from './actions';
import { setToast, getMeSuccess } from '../app/actions';
import { adminApi } from './api';

function* getAdminById({ payload }) {
  try {
    const response = yield call(adminApi.getAdminByIdRequest, payload.id);

    yield put(actions.getAdminByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getAdminByIdFail(error));
  }
}

function* createAdmin({ payload }) {
  try {
    yield call(adminApi.createAdminRequest, payload.data);

    yield put(setToast({
      type: 'success',
      text: 'Admin created successfully',
      options: {},
    }));

    yield delay(500);
    yield payload.history.push(URLS.adminList);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* updateAdmin({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const currentAdmin = yield select((s) => s.admin.admin);
    const response = yield call(adminApi.updateAdminRequest, payload.data, currentAdmin.id);

    if (currentUser.id === currentAdmin.id) {
      yield setTokenToCookie(response.data.accessToken);
      yield put(getMeSuccess(response.data.user));
    }

    yield put(setToast({
      type: 'success',
      text: 'Admin updated successfully',
      options: {},
    }));

    yield delay(500);
    yield payload.history.push(URLS.adminList);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getAdminsList({ payload }) {
  try {
    const response = yield call(adminApi.getAdminsList, payload);

    yield put(actions.getAdminListSuccess(response.data));
  } catch (error) {
    yield put(actions.getAdminListFail(error));
  }
}

function* deleteAdmin({ payload }) {
  try {
    yield call(adminApi.deleteAdmin, payload.id);

    yield put(setToast({
      type: 'success',
      text: 'Admin was deleted successfully',
      options: {},
    }));

    yield put(actions.getAdminList({ page: 1 }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* admin() {
  yield takeLatest(types.GET_ADMIN_BY_ID, getAdminById);
  yield takeLatest(types.CREATE_ADMIN, createAdmin);
  yield takeLatest(types.UPDATE_ADMIN, updateAdmin);
  yield takeLatest(types.GET_ADMINS_LIST, getAdminsList);
  yield takeLatest(types.DELETE_ADMIN, deleteAdmin);
}

export default admin;
