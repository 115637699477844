// setPaymentConfirmedStatus
export const SET_PAYMENT_CONFIRMED_STATUS = 'companyActivation/SET_PAYMENT_CONFIRMED_STATUS';

// setPaymentConfirmedStatus
export const SET_CONTRACT_SIGNED_STATUS = 'companyActivation/SET_CONTRACT_SIGNED_STATUS';

// setWaitingForPaymentStatus
export const SET_WAITING_FOR_PAYMENT_STATUS = 'companyActivation/SET_WAITING_FOR_PAYMENT_STATUS';

// setMappingMeetingStatus
export const SET_MAPPING_MEETING_STATUS = 'companyActivation/SET_MAPPING_MEETING_STATUS';

// setConfirmedStatus
export const SET_CONFIRMED_STATUS = 'companyActivation/SET_CONFIRMED_STATUS';

// setViewed
export const SET_VIEWED_COMPANY = 'companyActivation/SET_VIEWED_COMPANY';
export const SET_VIEWED_COMPANY_SUCCESS = 'companyActivation/SET_VIEWED_COMPANY_SUCCESS';
export const SET_VIEWED_COMPANY_FAIL = 'companyActivation/SET_VIEWED_COMPANY_FAIL';
