import * as types from './types';

import { initialStore } from '../initial-store';

export const extraReducer = (state = initialStore.extra, { payload, type }) => {
  switch (type) {
    case types.GET_ALL_EXTRAS: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_EXTRAS_SUCCESS: {
      const { data } = payload;
      return {
        ...state, isFetching: false, count: data.count, extrasList: data.rows,
      };
    }
    case types.GET_ALL_EXTRAS_FAIL: {
      return { ...state, isFetching: false };
    }
    case types.GET_EXTRA_BY_ID:
    case types.GET_EXTRA_BY_ID_SUCCESS:
    case types.GET_EXTRA_BY_ID_FAIL:
    case types.CREATE_EXTRA:
    case types.CREATE_EXTRA_SUCCESS:
    case types.CREATE_EXTRA_FAIL:
    case types.UPDATE_EXTRA:
    case types.UPDATE_EXTRA_SUCCESS:
    case types.UPDATE_EXTRA_FAIL:
    case types.REMOVE_EXTRA:
    case types.REMOVE_EXTRA_SUCCESS:
    case types.REMOVE_EXTRA_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
