import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const companyUserApi = {
  getAllCompanyUsersRequest(id, params) {
    return http({ url: API_URLS.getListOfCompanyUsers(id), params });
  },
  getCompanyUserByIdRequest(companyId, userId) {
    return http({ url: API_URLS.getCompanyUserById(companyId, userId) });
  },
  createCompanyUserRequest({ companyId, data }) {
    return http({ url: API_URLS.createCompanyUser(companyId), data, method: 'POST' });
  },
  updateCompanyUserRequest({ companyId, userId, data }) {
    return http({ url: API_URLS.updateCompanyUser(companyId, userId), method: 'PUT', data });
  },
  removeCompanyUserRequest({ companyId, userId }) {
    return http({ url: API_URLS.removeCompanyUser(companyId, userId), method: 'DELETE' });
  },
};
