import {
  call, takeLatest, put, select, delay,
} from 'redux-saga/effects';

import { URLS } from 'constants/index';
import { setViewedProperty } from 'utils/calculate-viewed-property';
import * as types from './types';
import * as actions from './actions';
import { huntAdRequestApi } from './api';
import { setToast } from '../app/actions';
import { setViewedRequest } from '../hunt-ad-request-actions/actions';

function* getHuntAdRequestById({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, huntAdId: payload.id };
    const response = yield call(huntAdRequestApi.getHuntAdRequestById, data);

    yield put(actions.getHuntAdRequestByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getHuntAdRequestByIdFail(error));
  }
}

function* getHuntAdRequestByAdmin({ payload }) {
  const { id, setViewed } = payload;
  try {
    const response = yield call(huntAdRequestApi.getHuntAdRequestByAdminRequest, id);
    yield put(actions.getHuntAdRequestByAdminSuccess(response.data));

    const [r] = setViewedProperty(response.data.viewed);
    if (setViewed && r) {
      yield delay(1000);
      yield put(setViewedRequest({ id: response.data.id }));
    }
  } catch (error) {
    yield put(actions.getHuntAdRequestByAdminFail(error));
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getHuntAdRequestList({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, params: payload };
    const response = yield call(huntAdRequestApi.getHuntAdRequestList, data);

    yield put(actions.getHuntAdRequestListSuccess(response.data));
  } catch (error) {
    yield put(actions.getHuntAdRequestListFail(error));
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getHuntAdRequestListByAdmin({ payload }) {
  try {
    const response = yield call(huntAdRequestApi.getHuntAdRequestListByAdmin, payload);
    yield put(actions.getHuntAdRequestListByAdminSuccess(response.data));
  } catch (error) {
    yield put(actions.getHuntAdRequestListByAdminFail(error));
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* createHuntAdRequest({ payload }) {
  const { data, cb } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const requestData = { companyId: currentUser.company.id, data };
    const response = yield call(huntAdRequestApi.createHuntAdRequest, requestData);

    yield put(actions.createHuntAdRequestSuccess(response));
    if (cb && typeof cb === 'function') yield cb(response.data.id);
  } catch (error) {
    yield put(actions.createHuntAdRequestFail(error));
  }
}

function* createHuntAdDraft({ payload }) {
  const { data, cb } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const requestData = { companyId: currentUser.company.id, data };

    const response = yield call(huntAdRequestApi.createHuntAdRequestDraft, requestData);

    yield put(actions.createHuntAdDraftSuccess(response.data));
    if (cb && typeof cb === 'function') yield cb(response.data.id);
  } catch (error) {
    yield put(actions.createHuntAdDraftFail(error));
  }
}

function* updateHuntAdRequestDraft({ payload }) {
  const { data, cb, huntAdId } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const requestData = { companyId: currentUser.company.id, data, huntAdId };
    const response = yield call(huntAdRequestApi.updateHuntAdRequestDraft, requestData);

    yield put(actions.updateHuntAdDraftSuccess(response.data));
    if (cb && typeof cb === 'function') yield cb(response.data.id);
  } catch (error) {
    yield put(actions.updateHuntAdDraftSuccess(error));
  }
}

function* updateHuntAdRequest({ payload }) {
  const { data, cb } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const selectedHuntAd = yield select((s) => s.huntAdRequest.selectedHuntAd);

    const newData = { companyId: currentUser.company.id, huntAdId: selectedHuntAd.id, data };
    const response = yield call(huntAdRequestApi.updateHuntAdRequest, newData);
    yield put(actions.updateHuntAdRequestSuccess(response));
    if (cb && typeof cb === 'function') yield cb(response.data.id);
  } catch (error) {
    yield put(actions.updateHuntAdRequestFail(error));
  }
}

function* getHuntAdPreview({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const companyId = currentUser.company.id;
    const data = { companyId, data: payload.data };
    const response = yield call(huntAdRequestApi.getHuntAdPreview, data);
    yield put(actions.getHuntAdPreviewSuccess(response));

    payload.history.push(`${URLS.huntAdRequestItemFullPreviewPage}?id=${response.data.huntAdRequestId}`);
  } catch (error) {
    yield put(actions.getHuntAdPreviewFail(error));
  }
}

function* huntAdRequest() {
  yield takeLatest(types.GET_HUNT_AD_REQUEST_BY_ID, getHuntAdRequestById);
  yield takeLatest(types.CREATE_HUNT_AD_REQUEST, createHuntAdRequest);
  yield takeLatest(types.UPDATE_HUNT_AD_REQUEST, updateHuntAdRequest);
  yield takeLatest(types.GET_HUNT_AD_REQUEST_LIST, getHuntAdRequestList);
  yield takeLatest(types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN, getHuntAdRequestListByAdmin);
  yield takeLatest(types.GET_HUNT_AD_REQUEST_BY_ADMIN, getHuntAdRequestByAdmin);
  yield takeLatest(types.CREATE_HUNT_AD_DRAFT, createHuntAdDraft);
  yield takeLatest(types.UPDATE_HUNT_AD_REQUEST_DRAFT, updateHuntAdRequestDraft);
  yield takeLatest(types.GET_HUNT_AD_PREVIEW, getHuntAdPreview);
}

export default huntAdRequest;
