import {
  call, takeEvery, put, select,
} from 'redux-saga/effects';

import * as types from './types';
import * as actions from './actions';
import { URLS } from '../../constants';
import { diaryApi } from './api';

function* getAllDiary({ payload }) {
  try {
    const response = yield call(diaryApi.getAllDiaryRequest, payload);

    yield put(actions.getAllDiarySuccess(response.data));
  } catch (error) {
    yield put(actions.getAllDiaryFail(error));
  }
}

function* getDiaryById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(diaryApi.getDiaryByIdRequest, { id });

    yield put(actions.getDiaryByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getDiaryByIdFail(error));
  }
}

function* createDiary({ payload }) {
  try {
    const response = yield call(diaryApi.createDiaryRequest, payload);

    yield put(actions.createDiarySuccess(response));
    payload.history.push(URLS.hunterDiaryList);
  } catch (error) {
    yield put(actions.createDiaryFail(error));
  }
}

function* updateDiary({ payload }) {
  const { data, cb } = payload;
  try {
    const selectedDiary = yield select((s) => s.hunterDiary.selectedDiary);
    const response = yield call(diaryApi.updateDiaryRequest, { id: selectedDiary.id, data });

    yield put(actions.updateDiarySuccess(response));
    if (cb && typeof cb === 'function') cb(selectedDiary.id);
  } catch (error) {
    yield put(actions.updateDiaryFail(error));
  }
}

function* removeDiary({ payload }) {
  try {
    const response = yield call(diaryApi.removeDiaryRequest, payload);
    yield put(actions.removeDiarySuccess(response));
    yield put(actions.getAllDiary());
  } catch (error) {
    yield put(actions.removeDiaryFail(error));
  }
}

function* shareDiary({ payload }) {
  const { share } = payload;
  try {
    const selectedDiary = yield select((s) => s.hunterDiary.selectedDiary);
    const data = { id: selectedDiary.id, share };
    yield call(diaryApi.shareDiaryRequest, data);
    yield put(actions.shareDiarySuccess({ share }));
  } catch (error) {
    yield put(actions.shareDiaryFail());
  }
}

function* diary() {
  yield takeEvery(types.GET_ALL_DIARY, getAllDiary);
  yield takeEvery(types.GET_DIARY_BY_ID, getDiaryById);
  yield takeEvery(types.CREATE_DIARY, createDiary);
  yield takeEvery(types.UPDATE_DIARY, updateDiary);
  yield takeEvery(types.REMOVE_DIARY, removeDiary);
  yield takeEvery(types.SHARE_DIARY, shareDiary);
}

export default diary;
