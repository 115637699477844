import * as types from './types';

// getHuntAdRequestById
export const getHuntAdRequestById = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_BY_ID,
  payload,
});
export const getHuntAdRequestByIdSuccess = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_BY_ID_SUCCESS,
  payload,
});
export const getHuntAdRequestByIdFail = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_BY_ID_FAIL,
  payload,
});

// getHuntAdRequestByAdmin
export const getHuntAdRequestByAdmin = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_BY_ADMIN,
  payload,
});
export const getHuntAdRequestByAdminSuccess = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_BY_ADMIN_SUCCESS,
  payload,
});
export const getHuntAdRequestByAdminFail = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_BY_ADMIN_FAIL,
  payload,
});

// getHuntAdRequestList
export const getHuntAdRequestList = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_LIST,
  payload,
});
export const getHuntAdRequestListSuccess = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_LIST_SUCCESS,
  payload,
});
export const getHuntAdRequestListFail = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_LIST_FAIL,
  payload,
});

// getHuntAdRequestListByAdmin
export const getHuntAdRequestListByAdmin = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN,
  payload,
});
export const getHuntAdRequestListByAdminSuccess = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_SUCCESS,
  payload,
});
export const getHuntAdRequestListByAdminFail = (payload) => ({
  type: types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_FAIL,
  payload,
});

// createHuntAdRequest
export const createHuntAdRequest = (payload) => ({
  type: types.CREATE_HUNT_AD_REQUEST,
  payload,
});
export const createHuntAdRequestSuccess = (payload) => ({
  type: types.CREATE_HUNT_AD_REQUEST_SUCCESS,
  payload,
});
export const createHuntAdRequestFail = (payload) => ({
  type: types.CREATE_HUNT_AD_REQUEST_FAIL,
  payload,
});

// createHuntAdDraft
export const createHuntAdDraft = (payload) => ({
  type: types.CREATE_HUNT_AD_DRAFT,
  payload,
});
export const createHuntAdDraftSuccess = (payload) => ({
  type: types.CREATE_HUNT_AD_DRAFT_SUCCESS,
  payload,
});
export const createHuntAdDraftFail = (payload) => ({
  type: types.CREATE_HUNT_AD_DRAFT_FAIL,
  payload,
});

// updateHuntAdDraft
export const updateHuntAdDraft = (payload) => ({
  type: types.UPDATE_HUNT_AD_REQUEST_DRAFT,
  payload,
});
export const updateHuntAdDraftSuccess = (payload) => ({
  type: types.UPDATE_HUNT_AD_REQUEST_DRAFT_SUCCESS,
  payload,
});
export const updateHuntAdDraftFail = (payload) => ({
  type: types.UPDATE_HUNT_AD_REQUEST_DRAFT_FAIL,
  payload,
});

// updateHuntAdRequest
export const updateHuntAdRequest = (payload) => ({
  type: types.UPDATE_HUNT_AD_REQUEST,
  payload,
});
export const updateHuntAdRequestSuccess = (payload) => ({
  type: types.UPDATE_HUNT_AD_REQUEST_SUCCESS,
  payload,
});
export const updateHuntAdRequestFail = (payload) => ({
  type: types.UPDATE_HUNT_AD_REQUEST_FAIL,
  payload,
});

// getHuntAdPreview
export const getHuntAdPreview = (payload) => ({
  type: types.GET_HUNT_AD_PREVIEW,
  payload,
});
export const getHuntAdPreviewSuccess = (payload) => ({
  type: types.GET_HUNT_AD_PREVIEW_SUCCESS,
  payload,
});
export const getHuntAdPreviewFail = (payload) => ({
  type: types.GET_HUNT_AD_PREVIEW_FAIL,
  payload,
});

// other
export const changeFetchParams = (payload) => ({
  type: types.CHANGE_FETCH_PARAMS,
  payload,
});

export const resetSelectedHuntAd = () => ({
  type: types.RESET_SELECTED_HUNT_AD,
});
