import { fork } from 'redux-saga/effects';

import auth from './authenticate/saga';
import app from './app/saga';
import hunter from './hunter/saga';
import restorePassword from './restore-password/saga';
import company from './company/saga';
import admin from './admin/saga';
import companyActivation from './company-activation/saga';
import member from './member/saga';
import companyUser from './company-user/saga';
import extra from './extra/saga';
import packages from './package/saga';
import priceList from './price-list/saga';
import huntAdRequest from './hunt-ad-request/saga';
import huntAdRequestActionsSaga from './hunt-ad-request-actions/saga';
import hunterDiarySaga from './diary/saga';
import order from './orders/saga';
import orderActions from './order-actions/saga';
import hunterMessageSaga from './message/saga';
import markedPlaceSaga from './marked-place/saga';

const saga = function* () {
  yield fork(auth);
  yield fork(app);
  yield fork(hunter);
  yield fork(restorePassword);
  yield fork(company);
  yield fork(admin);
  yield fork(companyActivation);
  yield fork(companyUser);
  yield fork(member);
  yield fork(extra);
  yield fork(packages);
  yield fork(priceList);
  yield fork(huntAdRequest);
  yield fork(huntAdRequestActionsSaga);
  yield fork(hunterDiarySaga);
  yield fork(hunterMessageSaga);
  yield fork(order);
  yield fork(orderActions);
  yield fork(markedPlaceSaga);
};

export default saga;
