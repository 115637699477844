export const URLS = Object.freeze({
  main: '/main',

  adminLogin: '/administrator',
  adminList: '/administrator/admins',
  adminView: '/administrator/admins/user',
  adminMap: '/administrator/map',
  adminSignUps: '/administrator/sign-ups',
  adminSignUpsCompanyView: '/administrator/sign-ups/:companyId',
  adminFinanceRegistration: '/administrator/f-registration',
  adminFinanceRegistrationCompanyView: '/administrator/f-registration/:companyId',
  adminMapMapSignUps: '/administrator/cartographer',
  adminMapMapSignUpsCompanyView: '/administrator/cartographer/:companyId',
  adminMapManCompanyList: '/administrator/companies',
  adminHuntAdRequestManagement: '/administrator/hunt-ad-request',
  adminHuntAdRequestManagementDetailView: '/administrator/hunt-ad-request/:requestId',
  adminHuntAdRequestManagementPreviewPage: '/administrator/hunt-ad/preview',
  adminContactOrder: '/administrator/orders',
  adminContactOrderDetail: '/administrator/orders/:id',
  adminFinanceOrder: '/administrator/order-references',
  adminFinanceOrderDetail: '/administrator/order-references/:id',

  companyAuth: '/company',
  companySuccessRegistration: '/company/success-registration',
  companyOnboarding: '/company/onboarding',
  companySuccessOnboarding: '/company/success-onboarding',
  companySummary: '/company/summary',
  companyAdmin: '/company/admin',
  companyAdminView: '/company/admin/user',
  companyWardenNotifications: '/company/notifications',
  companyWardenNotificationsDetail: '/company/notifications/:id',
  companyWardenMap: '/company/game-warden-map',
  companyOrder: '/company/orders',
  companyOrderDetail: '/company/orders/:id',
  companyMapMan: '/company/map-man',
  companyMemberList: '/company/members',
  memberView: '/company/members/member',
  editMember: '/company/member',
  companyPriceList: '/company/price-list',
  companyPackage: '/company/price-list/package',
  companyExtras: '/company/price-list/extras',
  companyPriceListItems: '/company/price-list/items',
  createPriceList: '/company/price-list/items/:id',
  huntAdRequest: '/company/huntAdRequest',
  huntAdRequestItem: '/company/huntAdRequest/item',
  huntAdRequestItemDetail: '/company/huntAdRequest/item/detail/:id',
  huntAdRequestItemFullPreviewPage: '/company/hunt-ad/preview',
  companyMessages: '/company/messages',
  companyCreateMessage: '/company/messages/create',
  companyViewMessage: '/company/messages/view/:id',

  mapMarker: '/map/:companyId',
  offers: '/offers/:offerId',
  TEMP_searchPage: '/search-results',

  hunterAuth: '/hunter',
  hunterSuccessRegistration: '/hunter/success-registration',
  hunterOnboarding: '/hunter/onboarding',
  hunterDetails: '/hunter/details',
  hunterProfileOrders: '/hunter/details/orders',
  hunterOrderDetail: '/hunter/order/detail/:id',
  hunterOfferPreview: '/hunter/hunt-ad/preview/:id',
  hunterDiaryList: '/hunter/details/diary-list',
  hunterDiaryView: '/hunter/details/diary-list/diary',
  hunterMessageList: '/hunter/details/messages',
  hunterMessageView: '/hunter/details/messages/:id',
  hunterDiaryPreview: '/hunter/details/diary-list/preview',
  hunterMarkedPlacesList: '/hunter/details/marked-places',
  hunterMarkedPlacesPreview: '/hunter/details/marked-places/preview',
  hunterMarkedPlacesView: '/hunter/details/marked-places/view',
});

export const hunterStatuses = Object.freeze({
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
});

export const companyStatuses = Object.freeze({
  PENDING: 'PENDING',
  NEW: 'NEW',
  SUBMITTED: 'SUBMITTED',
  WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  MAPPING_MEETING: 'MAPPING_MEETING',
  CONTRACT_SIGNED: 'CONTRACT_SIGNED',
  CONFIRMED: 'CONFIRMED',
});

export const huntAdRequestStatuses = Object.freeze({
  REVIEW: 'REVIEW',
  CANCELED: 'CANCELED',
  PUBLISHED: 'PUBLISHED',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  DRAFT: 'DRAFT',
});

export const orderStatuses = Object.freeze({
  NEW: 'NEW',
  REJECTED: 'REJECTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  READY_FOR_INVOICE1: 'READY_FOR_INVOICE1',
  ORDERED: 'ORDERED',
  READY_FOR_INVOICE2: 'READY_FOR_INVOICE2',
  PAYED: 'PAYED',
  HUNT_COMPLETED: 'HUNT_COMPLETED',
  HUNT_APPROVED: 'HUNT_APPROVED',
  READY_FOR_INVOICE3: 'READY_FOR_INVOICE3',
  PAYOUT: 'PAYOUT',
  COMPLETED: 'COMPLETED',
  HUNT_REJECTED: 'HUNT_REJECTED',
  RECEIVED: 'RECEIVED',
  CANCEL: 'CANCEL',
});

export const DEFAULT_PAGINATION_PARAMS = Object.freeze({
  page: 1,
  limit: 30,
});

export const PRICE_LIST_PREFIX = 'plist::';
export const EXTRAS_PREFIX = 'ext::';
