import {
  ADMIN_PREFIX, USER_PREFIX, HUNTER_PREFIX, HUNTER, ADMIN, CONTACT, FINANCE, MAPMAN, GAME_WARDEN,
} from 'constants/roles';
import { URLS, hunterStatuses, companyStatuses } from 'constants/index';

export class Links {
  constructor(user, t) {
    this.user = user;
    this.separator = '::';
    this.links = new Map([
      [HUNTER_PREFIX, [
        {
          role: HUNTER,
          links: [
            {
              // id: 24,
              id: 1,
              name: t('nav.hunter.personal_details'),
              link: URLS.hunterDetails,
              subLinks: [URLS.hunterDiaryPreview],
            },
            {
              id: 2,
              name: t('nav.hunter.orders'),
              link: URLS.hunterProfileOrders,
              subLinks: [URLS.hunterOrderDetail, URLS.hunterOfferPreview],
            },
            {
              id: 3,
              name: t('nav.hunter.diary'),
              link: URLS.hunterDiaryList,
              subLinks: [URLS.hunterDiaryPreview, URLS.hunterDiaryView],
            },
            {
              id: 4,
              name: t('nav.hunter.marked_places'),
              link: URLS.hunterMarkedPlacesList,
              subLinks: [URLS.hunterMarkedPlacesPreview, URLS.hunterMarkedPlacesView],
            },
            {
              id: 5,
              name: t('nav.hunter.messages'),
              link: URLS.hunterMessageList,
              subLinks: [URLS.hunterMessageView],
            },
          ],
        },
      ]],
      [ADMIN_PREFIX, [
        {
          role: ADMIN,
          links: [
            { id: 4, name: t('nav.admin.users'), link: URLS.adminList },
          ],
        },
        {
          role: CONTACT,
          links: [
            { id: 5, name: t('nav.admin.company_sign_ups'), link: URLS.adminSignUps },
            {
              id: 6,
              name: t('nav.admin.hunt_ads'),
              link: URLS.adminHuntAdRequestManagement,
              subLinks: [URLS.adminHuntAdRequestManagementPreviewPage],
            },
            { id: 7, name: t('nav.admin.orders'), link: URLS.adminContactOrder },
          ],
        },
        {
          role: MAPMAN,
          links: [
            {
              id: 11,
              name: t('nav.admin.sign_ups_map'),
              link: URLS.adminMapMapSignUps,
              subLinks: [URLS.mapMarker.replace('/:companyId', '')],
            },
            { id: 12, name: t('nav.admin.company_list'), link: URLS.adminMapManCompanyList },
          ],
        },
        {
          role: FINANCE,
          links: [
            { id: 8, name: t('nav.admin.sign_up_transfers'), link: URLS.adminFinanceRegistration },
            { id: 9, name: t('nav.admin.order_transfers'), link: URLS.adminFinanceOrder },
          ],
        },
      ]],
      [USER_PREFIX, [
        {
          role: ADMIN,
          links: [
            { id: 12, name: t('nav.user.users'), link: URLS.companyAdmin },
          ],
        },
        {
          role: CONTACT,
          links: [
            {
              id: 13,
              name: t('nav.user.hunting'),
              link: URLS.huntAdRequest,
              subLinks: [URLS.huntAdRequestItemFullPreviewPage],
            },
            { id: 14, name: t('nav.user.orders'), link: URLS.companyOrder },
            { id: 15, name: t('nav.user.members'), link: URLS.companyMemberList },
            { id: 16, name: t('nav.user.price_lists'), link: URLS.companyPriceList },
            { id: 18, name: t('nav.user.contact_user'), link: URLS.companyMessages },
          ],
        },
        {
          role: MAPMAN,
          links: [
            {
              id: 19,
              name: t('nav.user.edit_map'),
              link: URLS.companyMapMan,
              subLinks: [URLS.mapMarker.replace('/:companyId', '')],
            },
          ],
        },
        {
          role: GAME_WARDEN,
          links: [
            {
              id: 22,
              name: t('nav.user.gw_map'),
              link: URLS.companyWardenMap,
              subLinks: [URLS.mapMarker.replace('/:companyId', '')],
            },
            { id: 23, name: t('nav.user.notifications'), link: URLS.companyWardenNotifications },
          ],
        },
      ]],
    ]);
  }

  get getLinks() {
    if (this.user.id.includes(HUNTER_PREFIX) && this.user.status === hunterStatuses.NEW) {
      return [];
    }

    if (this.user.id.includes(USER_PREFIX) && this.user.company.status !== companyStatuses.CONFIRMED) {
      return [];
    }

    return this.links.get(`${this.user.id.split(this.separator)[0]}${this.separator}`);
  }

  get getMergedLinks() {
    const links = this.getLinks;
    if (links.length === 0) return [];

    return links.reduce((acc, item) => {
      if (this.user.role.includes(item.role)) {
        return acc.concat(item.links);
      }

      return acc;
    }, []);
  }
}
