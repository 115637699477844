import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const extraApi = {
  getAllExtrasRequest({ companyId, params }) {
    return http({ url: API_URLS.getExtra(companyId), params });
  },
  getExtraByIdRequest({ companyId, extraId }) {
    return http({ url: `${API_URLS.getExtra(companyId)}/${extraId}` });
  },
  createExtraRequest({ companyId, data }) {
    return http({ url: API_URLS.getExtra(companyId), data, method: 'POST' });
  },
  updateExtraRequest({ companyId, extraId, data }) {
    return http({ url: `${API_URLS.getExtra(companyId)}/${extraId}`, data, method: 'PUT' });
  },
  removeExtraRequest({ companyId, extraId }) {
    return http({ url: `${API_URLS.getExtra(companyId)}/${extraId}`, method: 'DELETE' });
  },
};
