import * as types from './types';

// updateHunter
export const updateHunter = (payload) => ({
  type: types.UPDATE_HUNTER,
  payload,
});
export const updateHunterSuccess = (payload) => ({
  type: types.UPDATE_HUNTER_SUCCESS,
  payload,
});
export const updateHunterFail = (payload) => ({
  type: types.UPDATE_HUNTER_FAIL,
  payload,
});

// getCompanyList
export const getCompanyList = (payload) => ({
  type: types.GET_COMPANY_LIST,
  payload,
});
export const getCompanyListSuccess = (payload) => ({
  type: types.GET_COMPANY_LIST_SUCCESS,
  payload,
});
export const getCompanyListFail = (payload) => ({
  type: types.GET_COMPANY_LIST_FAIL,
  payload,
});

// updateCompletedHunter
export const updateCompletedHunter = (payload) => ({
  type: types.UPDATE_COMPLETED_HUNTER,
  payload,
});
export const updateCompletedHunterSuccess = (payload) => ({
  type: types.UPDATE_COMPLETED_HUNTER_SUCCESS,
  payload,
});
export const updateCompletedHunterFail = (payload) => ({
  type: types.UPDATE_COMPLETED_HUNTER_FAIL,
  payload,
});
