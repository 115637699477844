import * as types from './types';

// getAllDiarys
export const getAllDiary = (payload) => ({
  type: types.GET_ALL_DIARY,
  payload,
});
export const getAllDiarySuccess = (payload) => ({
  type: types.GET_ALL_DIARY_SUCCESS,
  payload,
});
export const getAllDiaryFail = (payload) => ({
  type: types.GET_ALL_DIARY_FAIL,
  payload,
});

// getDiaryById
export const getDiaryById = (payload) => ({
  type: types.GET_DIARY_BY_ID,
  payload,
});
export const getDiaryByIdSuccess = (payload) => ({
  type: types.GET_DIARY_BY_ID_SUCCESS,
  payload,
});
export const getDiaryByIdFail = (payload) => ({
  type: types.GET_DIARY_BY_ID_FAIL,
  payload,
});

// createDiary
export const createDiary = (payload) => ({
  type: types.CREATE_DIARY,
  payload,
});
export const createDiarySuccess = (payload) => ({
  type: types.CREATE_DIARY_SUCCESS,
  payload,
});
export const createDiaryFail = (payload) => ({
  type: types.CREATE_DIARY_FAIL,
  payload,
});

// updateDiary
export const updateDiary = (payload) => ({
  type: types.UPDATE_DIARY,
  payload,
});
export const updateDiarySuccess = (payload) => ({
  type: types.UPDATE_DIARY_SUCCESS,
  payload,
});
export const updateDiaryFail = (payload) => ({
  type: types.UPDATE_DIARY_FAIL,
  payload,
});

// removeDiary
export const removeDiary = (payload) => ({
  type: types.REMOVE_DIARY,
  payload,
});
export const removeDiarySuccess = (payload) => ({
  type: types.REMOVE_DIARY_SUCCESS,
  payload,
});
export const removeDiaryFail = (payload) => ({
  type: types.REMOVE_DIARY_FAIL,
  payload,
});

// shareDiary
export const shareDiary = (payload) => ({
  type: types.SHARE_DIARY,
  payload,
});
export const shareDiarySuccess = (payload) => ({
  type: types.SHARE_DIARY_SUCCESS,
  payload,
});
export const shareDiaryFail = (payload) => ({
  type: types.SHARE_DIARY_FAIL,
  payload,
});

// resetSelectedDiary
export const resetSelectedDiary = (payload) => ({
  type: types.RESET_SELECTED_DIARY,
  payload,
});
