import { CookieBanner } from '@palmabit/react-cookie-law';

export const CookieBannerComponent = ({ policyLink }) => (
  <CookieBanner
    message="A weboldalunkon cookie-kat használunk, hogy a legjobb felhasználói élményt nyújthassuk."
    wholeDomain
    policyLink={policyLink}
    privacyPolicyLinkText="Adatkezelési nyilatkozat"
    acceptButtonText="Elfogad"
    declineButtonText="Elutasít"
    managePreferencesButtonText="Kezelje a sütiket"
    savePreferencesButtonText="Mentés és bezárás"
    showPreferencesOption={false}
    statisticsDefaultChecked
    marketingDefaultChecked
    onAccept={() => {}}
    onAcceptPreferences={() => {}}
    onAcceptStatistics={() => {}}
    onAcceptMarketing={() => {}}
  />
);
