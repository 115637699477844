import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import * as types from './types';
import * as actions from './actions';
import { extraApi } from './api';

function* getAllExtras({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, params: payload };
    const response = yield call(extraApi.getAllExtrasRequest, data);

    yield put(actions.getAllExtrasSuccess(response));
  } catch (error) {
    yield put(actions.getAllExtrasFail(error));
  }
}

function* getExtraById({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, extraId: payload.id };
    const response = yield call(extraApi.getExtraByIdRequest, data);

    yield put(actions.getExtraByIdSuccess(response));
  } catch (error) {
    yield put(actions.getExtraByIdFail(error));
  }
}

function* createExtra({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, data: payload };
    const response = yield call(extraApi.createExtraRequest, data);

    yield put(actions.createExtraSuccess(response));
    yield put(actions.getAllExtras(payload.fetchListParams));
  } catch (error) {
    yield put(actions.createExtraFail(error));
  }
}

function* updateExtra({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, extraId: payload.id, data: payload.data };
    const response = yield call(extraApi.updateExtraRequest, data);

    yield put(actions.updateExtraSuccess(response));
    yield put(actions.getAllExtras(payload.fetchListParams));
  } catch (error) {
    yield put(actions.updateExtraFail(error));
  }
}

function* removeExtra({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, extraId: payload.id };
    const response = yield call(extraApi.removeExtraRequest, data);

    yield put(actions.removeExtraSuccess(response));
    yield put(actions.getAllExtras(payload.fetchListParams));
  } catch (error) {
    yield put(actions.removeExtraFail(error));
  }
}

function* extra() {
  yield takeLatest(types.GET_ALL_EXTRAS, getAllExtras);
  yield takeLatest(types.GET_EXTRA_BY_ID, getExtraById);
  yield takeLatest(types.CREATE_EXTRA, createExtra);
  yield takeLatest(types.UPDATE_EXTRA, updateExtra);
  yield takeLatest(types.REMOVE_EXTRA, removeExtra);
}

export default extra;
