import { forwardRef } from 'react';

import { PlusIcon, MinusIcon } from 'assets/svg';
import { classNames } from 'utils/classNames';

const CustomNumberInput = forwardRef(({
  inputType = 'secondary',
  size = 'sm',
  className = '',
  label,
  id = Math.random(),
  placeholder,
  onChange,
  value,
  error,
  touched,
  disabled,
  step = 1,
  canBeNegative = false,
  ...rest
}, ref) => {
  const plusClick = () => {
    if (!disabled && typeof onChange === 'function') {
      const currentValue = value || 0;
      onChange(parseInt(currentValue, 10) + step);
    }
  };
  const minusClick = () => {
    if (!disabled && typeof onChange === 'function') {
      const currentValue = parseInt(value || 0, 10) - step;
      if (!canBeNegative && currentValue < 0) return;
      onChange(currentValue);
    }
  };

  const changeEvent = (e) => {
    if (typeof onChange === 'function') {
      const { value: currentValue } = e.target;
      if (!canBeNegative && currentValue < 0) return;
      onChange(currentValue);
    }
  };

  return (
    <div
      className={classNames({
        'number-input': true,
        [`number-input__${size}`]: true,
        [`number-input__${inputType}`]: true,
        'number-input__error': !!(touched && error),
        'number-input__disabled': disabled,
        [className]: true,
      })}
      ref={ref}
    >
      {label && <label className="input-container__label" htmlFor={id}>{label}</label>}
      <div className={classNames({
        'number-input__buttons': true,
        'number-input__buttons--minus': true,
        'cursor-pointer': !disabled,
      })}
      >
        <MinusIcon width={14} height={14} fill="var(--deer-gold)" onClick={minusClick} />
      </div>
      <input
        type="number"
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        onChange={changeEvent}
        value={value}
        {...rest}
      />
      <div className={classNames({
        'number-input__buttons': true,
        'number-input__buttons--plus': true,
        'cursor-pointer': !disabled,
      })}
      >
        <PlusIcon width={14} height={14} fill="var(--deer-gold)" onClick={plusClick} />
      </div>
      {touched && error && <div className="number-input__error-popup"><span>{error}</span></div>}
    </div>
  );
});

export default CustomNumberInput;
