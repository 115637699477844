import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const diaryApi = {
  getAllDiaryRequest(params) {
    return http({ url: API_URLS.getHunterDiary, params });
  },
  getDiaryByIdRequest({ id }) {
    return http({ url: `${API_URLS.getHunterDiary}/${id}` });
  },
  createDiaryRequest({ data }) {
    return http({ url: API_URLS.getHunterDiary, method: 'POST', data });
  },
  updateDiaryRequest({ id, data }) {
    return http({ url: `${API_URLS.getHunterDiary}/${id}`, method: 'PATCH', data });
  },
  removeDiaryRequest({ id }) {
    return http({ url: `${API_URLS.getHunterDiary}/${id}`, method: 'DELETE' });
  },
  shareDiaryRequest({ id, share }) {
    return http({ url: `${API_URLS.getHunterDiary}/${id}/${share ? 'share' : 'unshare'}` });
  },
};
