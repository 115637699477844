import { initialStore } from 'store/initial-store';
import * as types from './types';

export const appReducer = (state = initialStore.app, { type, payload }) => {
  switch (type) {
    case types.INITIAL_APP: {
      return { ...state, loading: true };
    }
    case types.INITIAL_APP_SUCCESS: {
      return { ...state, loading: false };
    }
    case types.INITIAL_APP_FAIL: {
      return { ...state, loading: false };
    }

    case types.SET_TOAST: {
      return { ...state, toast: payload };
    }

    case types.GET_GLOBAL_CONFIG_SUCCESS: {
      const {
        areaType,
        gameType,
        huntType,
        measureUnits,
        POITType,
        diaryGameType,
      } = payload;

      return {
        ...state,
        config: {
          areaType: areaType.map((el) => ({ value: el.value, label: el.name })),
          gameType: gameType.map((el) => ({ value: el.value, label: el.name })),
          diaryGameType: diaryGameType.map((el) => ({ value: el.value, label: el.name })),
          huntType: huntType.map((el) => ({ value: el.value, label: el.name })),
          measureUnits: measureUnits.map((el) => ({ value: el.value, label: el.name })),
          POITType: POITType.map((el) => ({ value: el.value, label: el.name, icon: el.icon })),
        },
      };
    }

    case types.GET_GLOBAL_CONFIG:
    case types.GET_GLOBAL_CONFIG_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
