/* eslint-disable no-underscore-dangle */
import * as types from './types';

import { initialStore } from '../initial-store';

export const priceListReducer = (state = initialStore.priceList, { payload, type }) => {
  switch (type) {
    case types.GET_PRICE_LIST_BY_ID:
    case types.GET_ALL_PRICE_LISTS: {
      return { ...state, isFetching: true };
    }

    case types.GET_ALL_PRICE_LISTS_SUCCESS: {
      const { count, rows } = payload;
      return {
        ...state, isFetching: false, count, priceList: rows,
      };
    }

    case types.GET_PRICE_LIST_BY_ID_SUCCESS: {
      return { ...state, isFetching: false, selectedPriceList: payload };
    }

    case types.GET_PRICE_LIST_BY_ID_FAIL:
    case types.GET_ALL_PRICE_LISTS_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.UPDATE_PRICE_LIST_SUCCESS: {
      return { ...state, selectedPriceList: payload };
    }

    case types.RESET_PRICE_LIST_FIELDS: {
      return { ...state, selectedPriceList: initialStore.priceList.selectedPriceList };
    }

    case types.CHANGE_PRICE_LIST_FIELDS: {
      return { ...state, selectedPriceList: { ...state.selectedPriceList, ...payload } };
    }

    case types.SET_INTERVAL: {
      return { ...state, interval: payload };
    }

    case types.ADD_NEW_INTERVAL: {
      const formatted = Object.keys(payload).reduce((acc, el) => {
        if (el === '_id') return { ...acc, [el]: payload[el] };

        return { ...acc, [el]: parseFloat(payload[el]) };
      }, {});

      const item = state.selectedPriceList.intervals.find((e) => e._id === formatted._id);
      return {
        ...state,
        selectedPriceList: {
          ...state.selectedPriceList,
          intervals: item
            ? state.selectedPriceList.intervals.map((el) => (el._id === formatted._id ? formatted : el))
            : state.selectedPriceList.intervals.concat(formatted),
        },
      };
    }

    case types.CREATE_PRICE_LIST:
    case types.CREATE_PRICE_LIST_SUCCESS:
    case types.CREATE_PRICE_LIST_FAIL:
    case types.UPDATE_PRICE_LIST:
    case types.UPDATE_PRICE_LIST_FAIL:
    case types.REMOVE_PRICE_LIST:
    case types.REMOVE_PRICE_LIST_SUCCESS:
    case types.REMOVE_PRICE_LIST_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
