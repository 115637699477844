import { combineReducers } from 'redux';

// reducers
import { authenticateReducer } from './authenticate/reducer';
import { hunterReducer } from './hunter/reducer';
import { appReducer as global } from './app/reducer';
import { companyReducer } from './company/reducer';
import { restorePasswordReducer } from './restore-password/reducer';
import { adminReducer } from './admin/reducer';
import { memberReducer } from './member/reducer';
import { companyUserReducer } from './company-user/reducer';
import { extraReducer } from './extra/reducer';
import { packageReducer } from './package/reducer';
import { priceListReducer } from './price-list/reducer';
import { huntAdRequestReducer } from './hunt-ad-request/reducer';
import { huntAdOfferReducer } from './hunt-ad-offer/reducer';
import { diaryReducer } from './diary/reducer';
import { ordersReducer } from './orders/reducer';
import { messageReducer } from './message/reducer';
import { markedplaceReducer } from './marked-place/reducer';

const appReducer = combineReducers({
  auth: authenticateReducer,
  app: global,
  hunter: hunterReducer,
  company: companyReducer,
  restorePassword: restorePasswordReducer,
  admin: adminReducer,
  companyUsers: companyUserReducer,
  member: memberReducer,
  extra: extraReducer,
  package: packageReducer,
  priceList: priceListReducer,
  huntAdRequest: huntAdRequestReducer,
  huntAdOffer: huntAdOfferReducer,
  hunterDiary: diaryReducer,
  orders: ordersReducer,
  messages: messageReducer,
  markedPlace: markedplaceReducer,
});

export const rootReducer = (state, action) => appReducer(state, action);
