import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const companyApi = {
  updateCompanyRequest(id, data) {
    return http({ url: API_URLS.updateCompany(id), method: 'PUT', data });
  },
  updateCompanyStatusRequest(companyId) {
    return http({ url: API_URLS.updateCompanyStatusSubmitted(companyId), method: 'PATCH' });
  },
  getCompanyRequest(companyId) {
    return http({ url: API_URLS.getCompany(companyId) });
  },
  getCompanyMapRequest(companyId) {
    return http({ url: API_URLS.getCompanyMap(companyId), method: 'GET' });
  },
  updateCompanyMapRequest(companyId, data) {
    return http({ url: API_URLS.getCompanyMap(companyId), method: 'PUT', data });
  },
  getCompanyListRequest(params) {
    return http({ url: API_URLS.getCompanyList, params });
  },
  getCompanyUnViewedCountsRequest(params) {
    return http({ url: `${API_URLS.getCompanyList}/un-viewed`, params });
  },
};
