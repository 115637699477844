// getAllCompanyMessages
export const GET_ALL_COMPANY_MESSAGES = 'message/GET_ALL_COMPANY_MESSAGES';
export const GET_ALL_COMPANY_MESSAGES_SUCCESS = 'message/GET_ALL_COMPANY_MESSAGES_SUCCESS';
export const GET_ALL_COMPANY_MESSAGES_FAIL = 'message/GET_ALL_COMPANY_MESSAGES_FAIL';
// getAllHunterMessages
export const GET_ALL_HUNTER_MESSAGES = 'message/GET_ALL_HUNTER_MESSAGES';
export const GET_ALL_HUNTER_MESSAGES_SUCCESS = 'message/GET_ALL_HUNTER_MESSAGES_SUCCESS';
export const GET_ALL_HUNTER_MESSAGES_FAIL = 'message/GET_ALL_HUNTER_MESSAGES_FAIL';
// getMessageById
export const GET_MESSAGE_BY_ID = 'message/GET_MESSAGE_BY_ID';
export const GET_MESSAGE_BY_ID_SUCCESS = 'message/GET_MESSAGE_BY_ID_SUCCESS';
export const GET_MESSAGE_BY_ID_FAIL = 'message/GET_MESSAGE_BY_ID_FAIL';
// createMessage
export const CREATE_MESSAGE = 'message/CREATE_MESSAGE';
export const CREATE_MESSAGE_SUCCESS = 'message/CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAIL = 'message/CREATE_MESSAGE_FAIL';

// getAllCompanyMessages
export const GET_ALL_MESSAGES_FOR_COMPANY = 'message/GET_ALL_MESSAGES_FOR_COMPANY';
export const GET_ALL_MESSAGES_FOR_COMPANY_SUCCESS = 'message/GET_ALL_MESSAGES_FOR_COMPANY_SUCCESS';
export const GET_ALL_MESSAGES_FOR_COMPANY_FAIL = 'message/GET_ALL_MESSAGES_FOR_COMPANY_FAIL';

// getRecipientInfo
export const GET_RECIPIENT_INFO = 'message/GET_RECIPIENT_INFO';
export const GET_RECIPIENT_INFO_SUCCESS = 'message/GET_RECIPIENT_INFO_SUCCESS';
export const GET_RECIPIENT_INFO_FAIL = 'message/GET_RECIPIENT_INFO_FAIL';

export const VIEW_BY_COMPANY_USER = 'message/VIEW_BY_COMPANY_USER';
export const VIEW_BY_HUNTER = 'message/VIEW_BY_HUNTER';
