import { memo } from 'react';
import { Spinner } from 'assets/svg';

export default memo(({ blur = true }) => (
  <>
    {blur && <div className="blur-bg" />}
    <div className="spinner">
      <Spinner />
    </div>
  </>
));
