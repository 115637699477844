import * as types from './types';

import { initialStore } from '../initial-store';

export const packageReducer = (state = initialStore.package, action) => {
  switch (action.type) {
    case types.GET_ALL_PACKAGES:
      return { ...state, isFetching: true };
    case types.GET_ALL_PACKAGES_SUCCESS: {
      const { rows, count } = action.payload.data;
      return {
        ...state, isFetching: false, count, packageList: rows,
      };
    }
    case types.GET_ALL_PACKAGES_FAIL:
    case types.GET_PACKAGE_BY_ID:
    case types.GET_PACKAGE_BY_ID_SUCCESS:
    case types.GET_PACKAGE_BY_ID_FAIL:
    case types.CREATE_PACKAGE:
    case types.CREATE_PACKAGE_SUCCESS:
    case types.CREATE_PACKAGE_FAIL:
    case types.UPDATE_PACKAGE:
    case types.UPDATE_PACKAGE_SUCCESS:
    case types.UPDATE_PACKAGE_FAIL:
    case types.REMOVE_PACKAGE:
    case types.REMOVE_PACKAGE_SUCCESS:
    case types.REMOVE_PACKAGE_FAIL: {
      return { ...state, isFetching: false };
    }

    default:
      return state;
  }
};
