import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const adminApi = {
  getAdminByIdRequest(id) {
    return http({ url: API_URLS.getAdminById(id) });
  },
  createAdminRequest(data) {
    return http({ url: API_URLS.createAdmin, method: 'POST', data });
  },
  updateAdminRequest(data, id) {
    return http({ url: API_URLS.updateAdmin(id), method: 'PATCH', data });
  },
  getAdminsList(params) {
    return http({ url: API_URLS.getAdminsList, params });
  },
  deleteAdmin(id) {
    return http({ url: API_URLS.deleteAdmin(id), method: 'DELETE' });
  },
};
