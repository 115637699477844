import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classNames } from 'utils/classNames';

import { Text } from 'components';

export const NavigationLink = ({
  dropdown,
  id,
  link,
  name,
  subLinks,
}) => {
  const location = useLocation();
  const active = useMemo(() => {
    if (
      !subLinks
      || !Array.isArray(subLinks)
      || subLinks.length === 0
    ) {
      return location.pathname.includes(link);
    }
    const linksArray = subLinks.concat(link);
    return linksArray.some((e) => location.pathname.includes(e));
  }, [subLinks, link]);

  return (
    <Link
      id={id}
      to={link}
      data-show={!dropdown}
      className={classNames({
        dropdown__link: dropdown,
        nav__link: !dropdown,
        hidden: dropdown,
      })}
    >
      <Text color={active ? 'gold' : ''}>{name}</Text>
    </Link>
  );
};
