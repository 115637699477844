import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { ROUTES } from 'route';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import { PrivateRoute } from 'components/private-route';
import { CookieBannerComponent } from 'components/cookie-banner';

import 'react-toastify/dist/ReactToastify.min.css';

import '../../styles/index.scss';

export default () => {
  const loading = useSelector((s) => s.app.loading);
  const toastConfig = useSelector((s) => s.app.toast);

  useEffect(() => {
    if (toastConfig) {
      toast[toastConfig.type](toastConfig.text, toastConfig.options);
    }
  }, [toastConfig]);

  if (loading) return 'Loading ...';

  return (
    <div className="container">
      <Switch>
        {ROUTES.map((el) => (
          <PrivateRoute
            key={el.id}
            exact={el.exact}
            path={el.path}
            component={el.component}
            header={el.header}
            accessConfig={el.accessConfig}
            layoutConfig={el.layoutConfig}
            ctx={el.ctx}
          />
        ))}
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <CookieBannerComponent policyLink={`${process.env.REACT_APP_NEXT_APP_URL}/aszf`} />
    </div>
  );
};
