export default ({ width = 220, height = 150 }) => (
  <svg width={width} height={height} viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="0" y="0" width="100%" height="100%" rx="5" fill="#D3E3D5" />
      <path d="M109.201 121.216C108.604 121.216 108.177 121.451 107.921 121.92C107.665 122.389 107.494 123.029 107.409 123.84C107.324 125.035 107.025 126.08 106.513 126.976C106.044 127.829 105.126 128.256 103.761 128.256C102.652 128.256 101.841 127.957 101.329 127.36C100.817 126.763 100.561 126.059 100.561 125.248C100.561 123.755 101.372 122.517 102.993 121.536C104.657 120.512 106.726 120 109.201 120C111.974 120 114.3 120.661 116.177 121.984C118.097 123.307 119.057 125.184 119.057 127.616C119.057 129.237 118.652 130.581 117.841 131.648C117.03 132.672 115.836 133.824 114.257 135.104L112.849 136.256C111.526 137.408 110.566 138.624 109.969 139.904C109.372 141.141 109.073 142.549 109.073 144.128C109.073 144.768 109.18 145.899 109.393 147.52C109.478 147.648 109.35 147.755 109.009 147.84C108.668 147.925 108.454 147.904 108.369 147.776C107.857 146.624 107.452 145.301 107.153 143.808C106.854 142.315 106.705 141.056 106.705 140.032C106.705 138.752 106.897 137.707 107.281 136.896C107.708 136.043 108.326 135.104 109.137 134.08C110.033 132.971 110.716 131.904 111.185 130.88C111.697 129.813 111.953 128.448 111.953 126.784C111.953 125.163 111.697 123.84 111.185 122.816C110.716 121.749 110.054 121.216 109.201 121.216ZM108.433 160.704C107.238 160.704 106.278 160.363 105.553 159.68C104.87 158.955 104.529 157.995 104.529 156.8C104.529 155.691 104.87 154.795 105.553 154.112C106.278 153.429 107.238 153.088 108.433 153.088C109.628 153.088 110.545 153.429 111.185 154.112C111.868 154.752 112.209 155.648 112.209 156.8C112.209 158.037 111.868 158.997 111.185 159.68C110.545 160.363 109.628 160.704 108.433 160.704Z" fill="#AEB8AF" />
      <path d="M146.284 93.2247C143.233 84.9856 112.594 107.128 85.3213 111.941C153.216 111.941 151.633 107.666 146.289 93.2373L146.284 93.2247Z" fill="#AEB8AF" />
      <path d="M84.7861 110.872C79.1059 112.134 65 113.011 65 108.733C74.2153 104.808 76.4283 103.502 79.4385 99.6419C81.4037 97.1218 88.5913 98.4899 90.1338 96.4333C91.738 94.2942 81.5776 95.8985 83.7166 92.69C86.9252 86.8076 87.052 79.7124 91.738 75.5776C96.8417 71.0744 110.455 71.8343 121.15 68.091C131.845 64.3477 130.776 80.3904 135.054 90.5509C109.385 98.5723 104.038 106.594 84.7861 110.872Z" fill="#AEB8AF" />
      <path d="M154.875 83.4419C150.369 84.4135 141.809 87.7852 137.86 89.1753L136.643 86.4457L154.18 81.4675C154.373 81.8547 154.319 81.8624 154.875 83.4419Z" fill="#AEB8AF" />
      <path d="M148.936 75.6287C148.731 74.4032 149.706 74.1557 150.397 73.7253C150.778 74.2076 151.488 74.9092 151.017 75.7981C151.993 75.2658 152.197 76.4912 151.897 77.36C152.702 76.8479 152.975 78.4817 152.17 78.9939C152.606 78.942 152.879 80.5759 151.201 81.1917C152.647 81.8532 150.027 83.2611 148.36 84.2023C147.217 82.8982 145.387 81.0486 146.629 80.4846C146.056 79.7196 145.851 78.494 146.793 78.7989C146.22 78.0338 146.452 76.7566 147.462 77.4699C147.257 76.2445 147.926 74.9154 148.936 75.6287Z" fill="#AEB8AF" />
      <path d="M160.151 78.6406C161.051 77.7844 161.803 78.4531 162.546 78.7841C162.36 79.3694 162.177 80.3509 161.177 80.4596C162.165 80.969 161.265 81.8252 160.377 82.0634C161.252 82.4432 160.052 83.5847 159.176 83.2049C159.464 83.5375 158.264 84.6791 156.813 83.6341C157.076 85.2028 154.442 83.8221 152.727 82.9686C153.167 81.2916 153.673 78.7393 154.837 79.4519C155.149 78.5483 156.049 77.6921 156.325 78.6429C156.637 77.7394 157.825 77.2157 157.8 78.4518C158.7 77.5955 160.176 77.4045 160.151 78.6406Z" fill="#AEB8AF" />
      <path d="M156.064 89.3035C157.246 89.6866 157.016 90.666 157.08 91.4775C156.476 91.5928 155.526 91.8999 154.954 91.0728C154.977 92.1837 153.795 91.8005 153.163 91.1337C153.246 92.0843 151.67 91.5733 151.587 90.6227C151.432 91.0341 149.856 90.5233 150.083 88.7497C148.829 89.7287 148.787 86.7547 148.72 84.841C150.404 84.4281 152.889 83.6563 152.817 85.0186C153.76 84.8623 154.942 85.2456 154.238 85.9409C155.181 85.7848 156.207 86.5793 155.109 87.1468C156.291 87.5299 157.162 88.736 156.064 89.3035Z" fill="#AEB8AF" />
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="220" height="220" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feMorphology radius="5" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
        <feOffset />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>

);
