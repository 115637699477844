import * as types from './types';

import { initialStore } from '../initial-store';

export const diaryReducer = (state = initialStore.hunterDiary, { payload, type }) => {
  switch (type) {
    case types.GET_ALL_DIARY: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_DIARY_SUCCESS: {
      const { count, rows } = payload;
      return {
        ...state,
        isFetching: false,
        count,
        diaryList: rows,
      };
    }
    case types.GET_ALL_DIARY_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_DIARY_BY_ID: {
      return { ...state, isFetching: true };
    }
    case types.GET_DIARY_BY_ID_SUCCESS: {
      return { ...state, selectedDiary: payload, isFetching: false };
    }
    case types.GET_DIARY_BY_ID_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.SHARE_DIARY_SUCCESS: {
      return {
        ...state,
        selectedDiary: {
          ...state.selectedDiary,
          shared: payload.share,
        },
      };
    }

    case types.SHARE_DIARY:
    case types.SHARE_DIARY_FAIL:
    case types.CREATE_DIARY:
    case types.CREATE_DIARY_SUCCESS:
    case types.CREATE_DIARY_FAIL:
    case types.UPDATE_DIARY:
    case types.UPDATE_DIARY_SUCCESS:
    case types.UPDATE_DIARY_FAIL:
    case types.REMOVE_DIARY:
    case types.REMOVE_DIARY_SUCCESS:
    case types.REMOVE_DIARY_FAIL: {
      return { ...state };
    }

    case types.RESET_SELECTED_DIARY: {
      return { ...state, selectedDiary: null };
    }

    default:
      return state;
  }
};
