import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import { USER_PREFIX } from 'constants/roles';
import * as types from './types';
import * as actions from './actions';
import { ordersApi } from './api';

function* getAllOrders({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);

    const data = { params: payload, id: currentUser.id.includes(USER_PREFIX) ? currentUser.company.id : null };

    const func = currentUser.id.includes(USER_PREFIX)
      ? ordersApi.getAllOrdersByCompanyRequest
      : ordersApi.getAllOrdersRequest;
    const response = yield call(func, data);

    yield put(actions.getAllOrdersSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllOrdersFail(error));
  }
}

function* getOrderById({ payload }) {
  const { id } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.id.includes(USER_PREFIX) ? currentUser.company.id : null, id };

    const func = currentUser.id.includes(USER_PREFIX)
      ? ordersApi.getOrderByIdByCompanyRequest
      : ordersApi.getOrderByIdRequest;
    const response = yield call(func, data);

    yield put(actions.getOrderByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getOrderByIdFail(error));
  }
}

function* getUnViewedCount() {
  try {
    const response = yield call(ordersApi.getUnViewedCountRequest);

    yield put(actions.getUnViewedCountSuccess(response.data));
  } catch (error) {
    yield put(actions.getUnViewedCountFail(error));
  }
}

function* createOrder({ payload }) {
  const { data, cb } = payload;
  try {
    const response = yield call(ordersApi.createOrderRequest, data);

    yield put(actions.createOrderSuccess(response.data));
    if (cb && typeof cb === 'function') yield cb(response.data);
  } catch (error) {
    yield put(actions.createOrderFail(error));
  }
}

function* updateOrder({ payload }) {
  const { cb } = payload;
  try {
    const currentOrder = yield select((s) => s.orders.order);
    const data = { id: currentOrder.id, data: payload.data };
    const response = yield call(ordersApi.updateOrderRequest, data);

    yield put(actions.updateOrderSuccess(response.data));
    if (cb && typeof cb === 'function') yield cb(response.data);
  } catch (error) {
    yield put(actions.updateOrderFail(error));
  }
}

function* orders() {
  yield takeLatest(types.GET_ALL_ORDERS, getAllOrders);
  yield takeLatest(types.GET_ORDER_BY_ID, getOrderById);
  yield takeLatest(types.CREATE_ORDER, createOrder);
  yield takeLatest(types.UPDATE_ORDER, updateOrder);
  yield takeLatest(types.GET_UN_VIEWED_COUNT_FOR_ORDER, getUnViewedCount);
}

export default orders;
