import { useState, useRef, useEffect } from 'react';
import { classNames } from 'utils/classNames';
import Text from '../typography';
import Button from '../button';

// Expects an array of filters(objects) with following fields {name:string, text:string, active:boolean}

export default function Dropdown({ filters, onFilterChange, isLogin = false }) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current) {
      if (event.target instanceof HTMLElement && !dropdownRef.current.contains(event.target)) setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = (name) => {
    setIsDropDownOpen(!isDropDownOpen);
    onFilterChange(name);
  };

  const getMenuComponent = ({ text, name, active }) => (
    <div
      className={classNames('dropdown__link', { login: isLogin })}
      key={name}
    >
      <div tabIndex={0} className="dropdown__menu-btn" role="button" onClick={() => handleClick(name)}>
        <Text className={classNames({ login: isLogin })} color={active ? 'gold' : ''}>{text}</Text>
      </div>
    </div>
  );

  const activeFilter = filters.find((el) => el.active === true);

  return (
    <Button
      postFix={isLogin ? null : 'arrowDownGold'}
      className={classNames('dropdown__btn', { login: isLogin })}
      variant={isLogin ? 'primary' : 'menu'}
      onClick={() => setIsDropDownOpen(!isDropDownOpen)}
    >
      <div className={classNames('dropdown__menu-wrapper', { login: isLogin })}>
        {activeFilter.text}
        <div className={classNames('dropdown__menu', { open: isDropDownOpen, login: isLogin })} ref={dropdownRef}>
          {filters.map(getMenuComponent)}
        </div>
      </div>
    </Button>
  );
}
