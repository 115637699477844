import { initialStore } from 'store/initial-store';

import * as types from './types';
import { GET_ME_SUCCESS } from '../app/types';

export const authenticateReducer = (state = initialStore.auth, { type, payload }) => {
  switch (type) {
    case types.COMPANY_SIGN_IN:
    case types.HUNTER_SIGN_IN:
    case types.ADMIN_SIGN_IN: {
      return { ...state, isFetching: true };
    }
    case types.COMPANY_SIGN_IN_SUCCESS:
    case types.HUNTER_SIGN_IN_SUCCESS:
    case types.ADMIN_SIGN_IN_SUCCESS: {
      return { ...state, isFetching: false, currentUser: payload.user };
    }
    case types.COMPANY_SIGN_IN_FAIL:
    case types.HUNTER_SIGN_IN_FAIL:
    case types.ADMIN_SIGN_IN_FAIL: {
      return { ...state, error: payload.message, isFetching: false };
    }

    case types.HUNTER_SIGN_UP: {
      return { ...state, isFetching: true, hunterSignUpSuccess: false };
    }

    case types.HUNTER_SIGN_UP_SUCCESS: {
      return { ...state, isFetching: false, hunterSignUpSuccess: true };
    }

    case types.HUNTER_SIGN_UP_FAIL: {
      return { ...state, isFetching: false, hunterSignUpSuccess: false };
    }

    case types.COMPANY_SIGN_UP: {
      return { ...state, isFetching: true, companySignUpSuccess: false };
    }
    case types.COMPANY_SIGN_UP_SUCCESS: {
      return { ...state, isFetching: false, companySignUpSuccess: true };
    }
    case types.COMPANY_SIGN_UP_FAIL: {
      return { ...state, isFetching: false, companySignUpSuccess: false };
    }

    case GET_ME_SUCCESS: {
      return { ...state, currentUser: payload };
    }
    case types.LOG_OUT:
    case types.LOG_OUT_SUCCESS:
    case types.LOG_OUT_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
