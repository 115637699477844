// updateCompany
export const UPDATE_COMPANY = 'company/UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = 'company/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'company/UPDATE_COMPANY_FAIL';

// submitCompany
export const SUBMIT_COMPANY = 'company/SUBMIT_COMPANY';
export const SUBMIT_COMPANY_SUCCESS = 'company/SUBMIT_COMPANY_SUCCESS';
export const SUBMIT_COMPANY_FAIL = 'company/SUBMIT_COMPANY_FAIL';

// getCompany
export const GET_COMPANY = 'company/GET_COMPANY';
export const GET_COMPANY_SUCCESS = 'company/GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'company/GET_COMPANY_FAIL';

// getCompanyMap
export const GET_COMPANY_MAP = 'company/GET_COMPANY_MAP';
export const GET_COMPANY_MAP_SUCCESS = 'company/GET_COMPANY_MAP_SUCCESS';
export const GET_COMPANY_MAP_FAIL = 'company/GET_COMPANY_MAP_FAIL';

// updateCompanyMap
export const UPDATE_COMPANY_MAP = 'company/UPDATE_COMPANY_MAP';
export const UPDATE_COMPANY_MAP_SUCCESS = 'company/UPDATE_COMPANY_SUCCESS_MAP';
export const UPDATE_COMPANY_MAP_FAIL = 'company/UPDATE_COMPANY_FAIL_MAP';

// getCompanyList
export const GET_COMPANY_LIST = 'company/GET_COMPANY_LIST';
export const GET_COMPANY_LIST_SUCCESS = 'company/GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAIL = 'company/GET_COMPANY_LIST_FAIL';

// getCompanyUnViewedCounts
export const GET_COMPANY_UN_VIEWED_COUNTS = 'company/GET_COMPANY_UN_VIEWED_COUNTS';
export const GET_COMPANY_UN_VIEWED_COUNTS_SUCCESS = 'company/GET_COMPANY_UN_VIEWED_COUNTS_SUCCESS';
export const GET_COMPANY_UN_VIEWED_COUNTS_FAIL = 'company/GET_COMPANY_UN_VIEWED_COUNTS_FAIL';

// other
export const RESET_SELECTED_COMPANY = 'company/RESET_SELECTED_COMPANY';
