// getAllPackages
export const GET_ALL_PACKAGES = 'package/GET_ALL_PACKAGES';
export const GET_ALL_PACKAGES_SUCCESS = 'package/GET_ALL_PACKAGES_SUCCESS';
export const GET_ALL_PACKAGES_FAIL = 'package/GET_ALL_PACKAGES_FAIL';
// getPackageById
export const GET_PACKAGE_BY_ID = 'package/GET_PACKAGE_BY_ID';
export const GET_PACKAGE_BY_ID_SUCCESS = 'package/GET_PACKAGE_BY_ID_SUCCESS';
export const GET_PACKAGE_BY_ID_FAIL = 'package/GET_PACKAGE_BY_ID_FAIL';
// createPackage
export const CREATE_PACKAGE = 'package/CREATE_PACKAGE';
export const CREATE_PACKAGE_SUCCESS = 'package/CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAIL = 'package/CREATE_PACKAGE_FAIL';
// updatePackage
export const UPDATE_PACKAGE = 'package/UPDATE_PACKAGE';
export const UPDATE_PACKAGE_SUCCESS = 'package/UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAIL = 'package/UPDATE_PACKAGE_FAIL';
// removePackage
export const REMOVE_PACKAGE = 'package/REMOVE_PACKAGE';
export const REMOVE_PACKAGE_SUCCESS = 'package/REMOVE_PACKAGE_SUCCESS';
export const REMOVE_PACKAGE_FAIL = 'package/REMOVE_PACKAGE_FAIL';
