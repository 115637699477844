// getAllCompanyUsers
export const GET_ALL_COMPANY_USERS = 'company-user/GET_ALL_COMPANY_USERS';
export const GET_ALL_COMPANY_USERS_SUCCESS = 'company-user/GET_ALL_COMPANY_USERS_SUCCESS';
export const GET_ALL_COMPANY_USERS_FAIL = 'company-user/GET_ALL_COMPANY_USERS_FAIL';
// getCompanyUserById
export const GET_COMPANY_USER_BY_ID = 'company-user/GET_COMPANY_USER_BY_ID';
export const GET_COMPANY_USER_BY_ID_SUCCESS = 'company-user/GET_COMPANY_USER_BY_ID_SUCCESS';
export const GET_COMPANY_USER_BY_ID_FAIL = 'company-user/GET_COMPANY_USER_BY_ID_FAIL';
// createCompanyUser
export const CREATE_COMPANY_USER = 'company-user/CREATE_COMPANY_USER';
export const CREATE_COMPANY_USER_SUCCESS = 'company-user/CREATE_COMPANY_USER_SUCCESS';
export const CREATE_COMPANY_USER_FAIL = 'company-user/CREATE_COMPANY_USER_FAIL';
// updateCompanyUser
export const UPDATE_COMPANY_USER = 'company-user/UPDATE_COMPANY_USER';
export const UPDATE_COMPANY_USER_SUCCESS = 'company-user/UPDATE_COMPANY_USER_SUCCESS';
export const UPDATE_COMPANY_USER_FAIL = 'company-user/UPDATE_COMPANY_USER_FAIL';
// removeCompanyUser
export const REMOVE_COMPANY_USER = 'company-user/REMOVE_COMPANY_USER';
export const REMOVE_COMPANY_USER_SUCCESS = 'company-user/REMOVE_COMPANY_USER_SUCCESS';
export const REMOVE_COMPANY_USER_FAIL = 'company-user/REMOVE_COMPANY_USER_FAIL';

// other
export const RESET_COMPANY_USER_FIELDS = 'admin/RESET_COMPANY_USER_FIELDS';
