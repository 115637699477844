import { orderStatuses } from 'constants/index';

import * as types from './types';
import { REMOVE_DOCUMENT_SUCCESS, ADD_DOCUMENTS } from '../order-actions/types';

import { initialStore } from '../initial-store';

const statusOrdering = [
  orderStatuses.NEW,
  orderStatuses.REJECTED,
  orderStatuses.ACKNOWLEDGED,
  orderStatuses.READY_FOR_INVOICE1,
  orderStatuses.ORDERED,
  orderStatuses.READY_FOR_INVOICE2,
  orderStatuses.PAYED,
  orderStatuses.HUNT_COMPLETED,
  orderStatuses.HUNT_APPROVED,
  orderStatuses.READY_FOR_INVOICE3,
  orderStatuses.PAYOUT,
  orderStatuses.COMPLETED,
  orderStatuses.HUNT_REJECTED,
  orderStatuses.RECEIVED,
  orderStatuses.CANCEL,
];

export const ordersReducer = (state = initialStore.orders, { payload, type }) => {
  switch (type) {
    case types.GET_ALL_ORDERS: {
      return { ...state, isFetching: true };
    }

    case types.GET_ALL_ORDERS_SUCCESS: {
      const { rows, count } = payload;

      const sortedByStatuses = statusOrdering.reduce((acc, item) => {
        const records = rows.filter((el) => el.status === item);
        if (records.length === 0) return acc;
        return acc.concat([[item, records]]);
      }, []);

      return {
        ...state,
        isFetching: false,
        count,
        orders: rows,
        sortedByStatuses,
      };
    }

    case types.GET_ALL_ORDERS_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_ORDER_BY_ID: {
      return { ...state, isFetching: true };
    }
    case types.GET_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        order: {
          ...payload,
          // status: 'HUNT_APPROVED',
          logs: payload.logs
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
        },
      };
    }
    case types.GET_ORDER_BY_ID_FAIL: {
      return { ...state, isFetching: false };
    }

    case REMOVE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        order: {
          ...state.order,
          documents: state.order.documents.filter((el) => el !== payload.document),
        },
      };
    }

    case ADD_DOCUMENTS: {
      return {
        ...state,
        order: {
          ...state.order,
          documents: state.order.documents.concat(payload.documents),
        },
      };
    }

    case types.GET_UN_VIEWED_COUNT_FOR_ORDER_SUCCESS: {
      return {
        ...state,
        unViewedCount: payload.count,
      };
    }

    case types.RESET_ORDER: {
      return { ...state, order: null };
    }

    case types.GET_UN_VIEWED_COUNT_FOR_ORDER:
    case types.GET_UN_VIEWED_COUNT_FOR_ORDER_FAIL:
    case types.CREATE_ORDER:
    case types.CREATE_ORDER_FAIL:
    case types.CREATE_ORDER_SUCCESS: {
      return state;
    }

    case types.UPDATE_ORDER:
    case types.UPDATE_ORDER_FAIL:
    case types.UPDATE_ORDER_SUCCESS: {
      return state;
    }

    default:
      return state;
  }
};
