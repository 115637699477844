// deleteMembers
export const DELETE_MEMBER = 'company/DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'company/DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAIL = 'company/DELETE_MEMBER_FAIL';

// getMembers
export const GET_MEMBERS = 'company/GET_MEMBERS';
export const GET_MEMBERS_SUCCESS = 'company/GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAIL = 'company/GET_MEMBERS_FAIL';

// getMembersById
export const GET_MEMBER_BY_ID = 'member/GET_MEMBER_BY_ID';
export const GET_MEMBER_BY_ID_SUCCESS = 'member/GET_MEMBER_BY_ID_SUCCESS';
export const GET_MEMBER_BY_ID_FAIL = 'member/GET_MEMBER_BY_ID_FAIL';

// createMember
export const CREATE_MEMBER = 'member/CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'member/CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAIL = 'member/CREATE_MEMBER_FAIL';

// updateMember
export const UPDATE_MEMBER = 'member/UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'member/UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAIL = 'member/UPDATE_MEMBER_FAIL';

// reseMemberField
export const RESET_MEMBER_FIELD = 'member/RESET_MEMBER_FILED';
