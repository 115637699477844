export const RESTORE_PASSWORD_HUNTER = 'restorePassword/RESTORE_PASSWORD_HUNTER';
export const RESTORE_PASSWORD_HUNTER_SUCCESS = 'restorePassword/RESTORE_PASSWORD_HUNTER_SUCCESS';
export const RESTORE_PASSWORD_HUNTER_FAIL = 'restorePassword/RESTORE_PASSWORD_HUNTER_FAIL';

export const RESTORE_PASSWORD_ADMIN = 'restorePassword/RESTORE_PASSWORD_ADMIN';
export const RESTORE_PASSWORD_ADMIN_SUCCESS = 'restorePassword/RESTORE_PASSWORD_ADMIN_SUCCESS';
export const RESTORE_PASSWORD_ADMIN_FAIL = 'restorePassword/RESTORE_PASSWORD_ADMIN_FAIL';

export const RESTORE_PASSWORD_USER = 'restorePassword/RESTORE_PASSWORD_USER';
export const RESTORE_PASSWORD_USER_SUCCESS = 'restorePassword/RESTORE_PASSWORD_USER_SUCCESS';
export const RESTORE_PASSWORD_USER_FAIL = 'restorePassword/RESTORE_PASSWORD_USER_FAIL';
