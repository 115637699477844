import { getTokenFromCookie } from './cookie-actions';

export const getAvatarImage = (key, timestamp) => `${
  process.env.REACT_APP_BASE_API_URL
}/images/avatars?key=${key}&token=${getTokenFromCookie()}&timestamp=${timestamp}`;

export const getTemporaryImage = (companyId, key) => `${
  process.env.REACT_APP_BASE_API_URL
}/images/company/${companyId}/tmp/${key}?token=${getTokenFromCookie()}`;

export const getHuntAdRequestImage = (id, key) => `${
  process.env.REACT_APP_BASE_API_URL
}/images/hunt-ad-request/${id}/${key}?token=${process.env.REACT_APP_MASTER_KEY}`;

export const getHuntRequestImageNextJS = (id, key) => `${
  process.env.NEXT_PUBLIC_BASE_API_URL
}/images/hunt-ad-request/${id}/${key}?token=${process.env.NEXT_PUBLIC_MASTER_KEY}`;

export const getPublicDiaryImageNextJS = (key) => `${
  process.env.NEXT_PUBLIC_BASE_API_URL
}/images/diary/${key}/public?token=${process.env.NEXT_PUBLIC_MASTER_KEY}`;

export const getPublicMarkedPlaceImageNextJS = (key) => `${
  process.env.NEXT_PUBLIC_BASE_API_URL
}/images/place/${key}/public?token=${process.env.NEXT_PUBLIC_MASTER_KEY}`;

export const getDiaryImage = (key) => `${
  process.env.REACT_APP_BASE_API_URL
}/images/diary/${key}?token=${getTokenFromCookie()}`;

export const getAvatarImageNext = (key, timestamp) => `${
  process.env.NEXT_PUBLIC_BASE_API_URL
}/images/avatars?key=${key}&token=${getTokenFromCookie()}&timestamp=${timestamp}`;

export const getMarkedPlaceImage = (key) => `${
  process.env.REACT_APP_BASE_API_URL
}/images/place/${key}?token=${getTokenFromCookie()}`;
