// all possible roles
export const ADMIN = 'ADMIN';
export const CONTACT = 'CONTACT';
export const MAPMAN = 'MAPMAN';
export const FINANCE = 'FINANCE';
export const GAME_WARDEN = 'GAME_WARDEN';
export const HUNTER = 'HUNTER';

// the order is very important
export const adminRoles = [ADMIN, CONTACT, MAPMAN, FINANCE];
export const companyUserRoles = [ADMIN, CONTACT, MAPMAN, FINANCE, GAME_WARDEN];
export const hunterRoles = [HUNTER];

// prefixes
export const ADMIN_PREFIX = 'adm::';
export const USER_PREFIX = 'usr::';
export const HUNTER_PREFIX = 'hunt::';
