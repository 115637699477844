export const setViewedProperty = (viewed) => {
  if (!viewed) return [true, null];

  try {
    const now = new Date();
    const futureDate = now.setDate(now.getDate() + 14);
    const lastView = new Date(viewed).getTime();
    return [lastView > futureDate, null];
  } catch (error) {
    return [undefined, true];
  }
};
