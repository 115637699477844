import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const markedPlaceApi = {
  getAllMarkedPlacesRequest(params) {
    return http({ url: API_URLS.getMarkedPlaces, params });
  },
  getMarkedPlaceByIdRequest({ id }) {
    return http({ url: `${API_URLS.getMarkedPlaces}/${id}` });
  },
  createMarkedPlaceRequest(data) {
    return http({ url: API_URLS.getMarkedPlaces, method: 'POST', data });
  },
  updateMarkedPlaceRequest({ id, data }) {
    return http({ url: `${API_URLS.getMarkedPlaces}/${id}`, method: 'PATCH', data });
  },
  removeMarkedPlaceRequest({ id }) {
    return http({ url: `${API_URLS.getMarkedPlaces}/${id}`, method: 'DELETE' });
  },
  shareMarkedPlaceRequest({ id, share }) {
    return http({ url: `${API_URLS.getMarkedPlaces}/${id}/${share ? 'share' : 'unshare'}` });
  },
};
