import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { initialStore } from './initial-store';
import { rootReducer } from './global-reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();
let middleWares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true,
  });
  middleWares = [...middleWares, logger];
}

export default createStore(
  rootReducer,
  initialStore,
  applyMiddleware(...middleWares),
);

sagaMiddleware.run(rootSaga);
