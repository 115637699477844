// getAllExtras
export const GET_ALL_EXTRAS = 'extra/GET_ALL_EXTRAS';
export const GET_ALL_EXTRAS_SUCCESS = 'extra/GET_ALL_EXTRAS_SUCCESS';
export const GET_ALL_EXTRAS_FAIL = 'extra/GET_ALL_EXTRAS_FAIL';
// getExtraById
export const GET_EXTRA_BY_ID = 'extra/GET_EXTRA_BY_ID';
export const GET_EXTRA_BY_ID_SUCCESS = 'extra/GET_EXTRA_BY_ID_SUCCESS';
export const GET_EXTRA_BY_ID_FAIL = 'extra/GET_EXTRA_BY_ID_FAIL';
// createExtra
export const CREATE_EXTRA = 'extra/CREATE_EXTRA';
export const CREATE_EXTRA_SUCCESS = 'extra/CREATE_EXTRA_SUCCESS';
export const CREATE_EXTRA_FAIL = 'extra/CREATE_EXTRA_FAIL';
// updateExtra
export const UPDATE_EXTRA = 'extra/UPDATE_EXTRA';
export const UPDATE_EXTRA_SUCCESS = 'extra/UPDATE_EXTRA_SUCCESS';
export const UPDATE_EXTRA_FAIL = 'extra/UPDATE_EXTRA_FAIL';
// removeExtra
export const REMOVE_EXTRA = 'extra/REMOVE_EXTRA';
export const REMOVE_EXTRA_SUCCESS = 'extra/REMOVE_EXTRA_SUCCESS';
export const REMOVE_EXTRA_FAIL = 'extra/REMOVE_EXTRA_FAIL';
