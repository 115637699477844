import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

// import { URLS } from 'constants/index';
import * as types from './types';
import * as actions from './actions';
import { setToast } from '../app/actions';
import { priceListApi } from './api';

function* getAllPriceLists({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, params: payload };
    const response = yield call(priceListApi.getAllPriceListsRequest, data);

    yield put(actions.getAllPriceListsSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllPriceListsFail(error));
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getPriceListById({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, listId: payload.id };
    const response = yield call(priceListApi.getPriceListByIdRequest, data);

    yield put(actions.getPriceListByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getPriceListByIdFail(error));
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* createPriceList({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, data: payload };
    yield call(priceListApi.createPriceListRequest, data);

    yield put(setToast({
      type: 'success',
      text: 'Price list created successfully',
      options: {},
    }));

    // yield delay(500);
    // yield payload.history.push(URLS.companyPriceListItems);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* updatePriceList({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, listId: payload.id, data: payload.data };
    const response = yield call(priceListApi.updatePriceListRequest, data);

    yield put(setToast({
      type: 'success',
      text: 'Price list updated successfully',
      options: {},
    }));

    yield put(actions.updatePriceListSuccess(response.data));
  } catch (error) {
    yield put(actions.updatePriceListFail(error));
  }
}

function* removePriceList({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, listId: payload.id };
    yield call(priceListApi.removePriceListRequest, data);

    yield put(setToast({
      type: 'success',
      text: 'Price list was deleted successfully',
      options: {},
    }));

    yield put(actions.getAllPriceLists(payload.fetchListParams));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* priceList() {
  yield takeLatest(types.GET_ALL_PRICE_LISTS, getAllPriceLists);
  yield takeLatest(types.GET_PRICE_LIST_BY_ID, getPriceListById);
  yield takeLatest(types.CREATE_PRICE_LIST, createPriceList);
  yield takeLatest(types.UPDATE_PRICE_LIST, updatePriceList);
  yield takeLatest(types.REMOVE_PRICE_LIST, removePriceList);
}

export default priceList;
