import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import { hunterStatuses } from 'constants/index';
import * as types from './types';
import { getMeSuccess, setToast } from '../app/actions';
import * as actions from './actions';
import { hunterApi } from './api';

function* updateHunter({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(hunterApi.updateHunterRequest, currentUser.id, payload);

    yield call(hunterApi.verifyHunterRequest, currentUser.id);
    yield put(getMeSuccess({ ...response.data, status: hunterStatuses.COMPLETED }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getCompanyList() {
  try {
    const response = yield call(hunterApi.getCompanyListRequest);

    yield put(actions.getCompanyListSuccess(response));
  } catch (error) {
    yield put(actions.getCompanyListFail(error));
  }
}

function* updateCompletedHunter({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(hunterApi.updateHunterRequest, currentUser.id, payload);

    yield put(getMeSuccess({ ...response.data, status: hunterStatuses.COMPLETED }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* hunter() {
  yield takeLatest(types.UPDATE_HUNTER, updateHunter);
  yield takeLatest(types.GET_COMPANY_LIST, getCompanyList);
  yield takeLatest(types.UPDATE_COMPLETED_HUNTER, updateCompletedHunter);
}

export default hunter;
