import { lazy } from 'react';

import { URLS } from 'constants/index';
import {
  ADMIN, MAPMAN, FINANCE, CONTACT, ADMIN_PREFIX, HUNTER_PREFIX, HUNTER, USER_PREFIX, GAME_WARDEN,
} from 'constants/roles';
import { breakpoints } from 'constants/breakpoints';

/**
 * when you add new route, it should use lazy from React
 * 'main' route is just for testing!
 *
 * path - [string] which user will see in url
 * exact - react-router-dom property for replace all (see doc for get more detail)
 * header - [boolean | object] switcher for header component, and we can pass some parameters to the header
 * accessConfig - [object | null] if value is 'null' that means it public route,
 * ctx - [object] context object will be passed to component
 * component - [ReactNode] component which will be rendered
 */

export const ROUTES = [
  {
    id: 1,
    path: URLS.main,
    exact: true,
    header: true,
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./main')),
  },
  {
    id: 2,
    path: URLS.adminLogin,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./admin/admin-auth')),
  },
  {
    id: 3,
    path: URLS.adminList,
    exact: true,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./admin/admin-list')),
  },
  {
    id: 4,
    path: URLS.companyWardenMap,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [GAME_WARDEN] },
    layoutConfig: null,
    component: lazy(() => import('./company/map')),
  },
  {
    id: 5,
    path: URLS.adminFinanceRegistration,
    exact: false,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [FINANCE] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.adminFinanceRegistration,
          exact: true,
          component: lazy(() => import('./admin/finance-registrations/list')),
        },
        {
          id: 2,
          path: URLS.adminFinanceRegistrationCompanyView,
          exact: true,
          component: lazy(() => import('./admin/finance-registrations/view-detail')),
        },
      ],
    },
    component: lazy(() => import('./admin/finance-registrations')),
  },
  {
    id: 6,
    path: URLS.adminSignUps,
    exact: false,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [CONTACT] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.adminSignUps,
          exact: true,
          component: lazy(() => import('./admin/sign-ups/list')),
        },
        {
          id: 2,
          path: URLS.adminSignUpsCompanyView,
          exact: true,
          component: lazy(() => import('./admin/sign-ups/view-details')),
        },
      ],
    },
    component: lazy(() => import('./admin/sign-ups')),
  },
  {
    id: 7,
    path: URLS.hunterAuth,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./hunter/auth')),
  },
  {
    id: 8,
    path: URLS.hunterSuccessRegistration,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./hunter/success-registration')),
  },
  {
    id: 9,
    path: URLS.hunterOnboarding,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: { [HUNTER_PREFIX]: [HUNTER] },
    layoutConfig: { layoutWrapperClassName: 'hunter-onboarding-container' },
    component: lazy(() => import('./hunter/hunter-onboarding')),
  },
  {
    id: 10,
    path: URLS.companyAuth,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./company/auth')),
  },
  {
    id: 11,
    path: URLS.companySuccessRegistration,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./company/success-registration')),
  },
  {
    id: 12,
    path: URLS.companyOnboarding,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: { [USER_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./company/company-onboarding')),
  },
  {
    id: 13,
    path: URLS.companySuccessOnboarding,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: { [USER_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./company/success-onboarding')),
  },
  {
    id: 14,
    path: URLS.companySummary,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./company/summary')),
  },
  {
    id: 15,
    path: URLS.companyAdmin,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./company/admin')),
  },
  {
    id: 16,
    path: URLS.companyAdminView,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./company/admin-view')),
  },
  {
    id: 17,
    path: URLS.companyOrder,
    exact: false,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.companyOrder,
          exact: true,
          component: lazy(() => import('./company/custom-orders/order-list')),
        },
        {
          id: 2,
          path: URLS.companyOrderDetail,
          exact: true,
          component: lazy(() => import('./company/custom-orders/detail-page')),
        },
      ],
    },
    component: lazy(() => import('./company/custom-orders')),
  },
  {
    id: 18,
    path: URLS.companyMapMan,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [MAPMAN] },
    layoutConfig: null,
    component: lazy(() => import('./company/map')),
  },
  {
    id: 19,
    path: URLS.companyWardenNotifications,
    exact: false,
    header: true,
    accessConfig: { [USER_PREFIX]: [GAME_WARDEN] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.companyWardenNotifications,
          exact: true,
          component: lazy(() => import('./company/game-warden-notifications/notification-list')),
        },
        {
          id: 2,
          path: URLS.companyWardenNotificationsDetail,
          exact: true,
          component: lazy(() => import('./company/game-warden-notifications/notification-detail')),
        },
      ],
    },
    component: lazy(() => import('./company/game-warden-notifications')),
  },
  {
    id: 20,
    path: URLS.adminView,
    exact: true,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [ADMIN] },
    layoutConfig: null,
    component: lazy(() => import('./admin/admin-view')),
  },
  {
    id: 21,
    path: URLS.adminMapManCompanyList,
    exact: true,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [MAPMAN] },
    layoutConfig: null,
    component: lazy(() => import('./admin/company-list-mapman')),
  },
  {
    id: 22,
    path: URLS.adminMapMapSignUps,
    exact: false,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [MAPMAN] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.adminMapMapSignUps,
          exact: true,
          component: lazy(() => import('./admin/sign-ups-mapman/list')),
        },
        {
          id: 2,
          path: URLS.adminMapMapSignUpsCompanyView,
          exact: true,
          component: lazy(() => import('./admin/sign-ups-mapman/view-detail')),
        },
      ],
    },
    component: lazy(() => import('./admin/sign-ups-mapman')),
  },
  {
    id: 23,
    path: URLS.companyMemberList,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/member-list')),
  },
  {
    id: 24,
    path: URLS.memberView,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/member-view')),
  },
  {
    id: 25,
    path: URLS.editMember,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/edit-member')),
  },
  {
    id: 26,
    path: URLS.companyPriceList,
    exact: false,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/price-list')),
    ctx: {
      nestedRouter: [
        {
          id: 2,
          path: URLS.companyPriceListItems,
          exact: false,
          component: lazy(() => import('./company/price-list/price-list-wrapper')),
          ctx: {
            nestedRouter: [
              {
                id: 1,
                path: URLS.companyPriceListItems,
                exact: true,
                component: lazy(() => import('./company/price-list/price-list-view')),
              },
              {
                id: 2,
                path: URLS.createPriceList,
                exact: true,
                component: lazy(() => import('./company/price-list/create-update-price-list')),
              },
            ],
          },
        },
        {
          id: 1,
          path: URLS.companyPriceList,
          exact: false,
          component: lazy(() => import('./company/price-list/package-wrapper')),
        },
      ],
    },
  },
  {
    id: 27,
    path: URLS.huntAdRequest,
    exact: false,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/hunt-ad')),
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.huntAdRequest,
          exact: true,
          component: lazy(() => import('./company/hunt-ad/hunt-ad-list-view')),
        },
        {
          id: 2,
          path: URLS.huntAdRequestItem,
          exact: true,
          component: lazy(() => import('./company/hunt-ad/create-update-hunt-ad')),
        },
        {
          id: 2,
          path: URLS.huntAdRequestItemDetail,
          exact: true,
          component: lazy(() => import('./company/hunt-ad/detail-page')),
        },
      ],
    },
  },
  {
    id: 28,
    path: URLS.huntAdRequestItemFullPreviewPage,
    exact: false,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/hunt-ad/hunt-ad-preview')),
  },
  {
    id: 29,
    path: URLS.adminHuntAdRequestManagement,
    exact: false,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./admin/hunt-ad-request-management')),
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.adminHuntAdRequestManagement,
          exact: true,
          component: lazy(() => import('./admin/hunt-ad-request-management/list-page')),
        },
        {
          id: 2,
          path: URLS.adminHuntAdRequestManagementDetailView,
          exact: true,
          component: lazy(() => import('./admin/hunt-ad-request-management/detail-page')),
        },
      ],
    },
  },
  {
    id: 30,
    path: URLS.adminHuntAdRequestManagementPreviewPage,
    exact: true,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./admin/hunt-ad-request-management/preview-page')),
  },
  {
    id: 31,
    path: URLS.mapMarker,
    exact: true,
    header: { hideBreakpoints: breakpoints.sm },
    accessConfig: { [ADMIN_PREFIX]: [MAPMAN], [USER_PREFIX]: [MAPMAN, GAME_WARDEN] },
    layoutConfig: null,
    component: lazy(() => import('./mapMaker')),
  },
  {
    id: 32,
    path: URLS.hunterDetails,
    exact: false,
    header: true,
    accessConfig: { [HUNTER_PREFIX]: [HUNTER] },
    layoutConfig: null,
    component: lazy(() => import('./hunter/detail')),
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.hunterDetails,
          exact: true,
          component: lazy(() => import('./hunter/detail/hunter-details')),
        },
        {
          id: 2,
          path: URLS.hunterProfileOrders,
          exact: true,
          component: lazy(() => import('./hunter/detail/hunter-orders')),
        },
        {
          id: 3,
          path: URLS.hunterDiaryList,
          exact: true,
          component: lazy(() => import('./hunter/detail/diary-list')),
        },
        {
          id: 4,
          path: URLS.hunterDiaryView,
          exact: true,
          component: lazy(() => import('./hunter/detail/diary-view')),
        },
        {
          id: 5,
          path: URLS.hunterDiaryPreview,
          exact: true,
          component: lazy(() => import('./hunter/detail/diary-preview')),
        },
        {
          id: 6,
          path: URLS.hunterMessageList,
          exact: true,
          component: lazy(() => import('./hunter/detail/hunter-messages')),
        },
        {
          id: 7,
          path: URLS.hunterMessageView,
          exact: true,
          component: lazy(() => import('./hunter/detail/hunter-messages/hunter-message-preview')),
        },
        {
          id: 8,
          path: URLS.hunterMarkedPlacesList,
          exact: true,
          component: lazy(() => import('./hunter/detail/marked-places-list')),
        },
        {
          id: 9,
          path: URLS.hunterMarkedPlacesPreview,
          exact: true,
          component: lazy(() => import('./hunter/detail/marked-places-preview')),
        },
        {
          id: 10,
          path: URLS.hunterMarkedPlacesView,
          exact: true,
          component: lazy(() => import('./hunter/detail/marked-places-view')),
        },
      ],
    },
  },
  {
    id: 33,
    path: URLS.hunterOrderDetail,
    exact: true,
    header: true,
    accessConfig: { [HUNTER_PREFIX]: [HUNTER] },
    layoutConfig: null,
    component: lazy(() => import('./hunter/detail/hunter-orders/hunter-order-detail')),
  },
  {
    id: 34,
    path: URLS.hunterOfferPreview,
    exact: true,
    header: true,
    accessConfig: { [HUNTER_PREFIX]: [HUNTER] },
    layoutConfig: null,
    component: lazy(() => import('./hunter/detail/hunter-orders/hunter-offer-preview')),
  },
  {
    id: 35,
    path: URLS.adminContactOrder,
    exact: false,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [CONTACT] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.adminContactOrder,
          exact: true,
          component: lazy(() => import('./admin/orders/order-list')),
        },
        {
          id: 2,
          path: URLS.adminContactOrderDetail,
          exact: true,
          component: lazy(() => import('./admin/orders/order-detail')),
        },
      ],
    },
    component: lazy(() => import('./admin/orders/contact-order-page')),
  },
  {
    id: 36,
    path: URLS.adminFinanceOrder,
    exact: false,
    header: true,
    accessConfig: { [ADMIN_PREFIX]: [FINANCE] },
    layoutConfig: null,
    ctx: {
      nestedRouter: [
        {
          id: 1,
          path: URLS.adminFinanceOrder,
          exact: true,
          component: lazy(() => import('./admin/orders/order-list')),
        },
        {
          id: 2,
          path: URLS.adminFinanceOrderDetail,
          exact: true,
          component: lazy(() => import('./admin/orders/order-detail')),
        },
      ],
    },
    component: lazy(() => import('./admin/orders/finance-order-page')),
  },
  {
    id: 37,
    path: URLS.companyMessages,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/messages/message-list')),
  },
  {
    id: 38,
    path: URLS.companyCreateMessage,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/messages/create-message')),
  },
  {
    id: 39,
    path: URLS.companyViewMessage,
    exact: true,
    header: true,
    accessConfig: { [USER_PREFIX]: [CONTACT] },
    layoutConfig: null,
    component: lazy(() => import('./company/messages/message-view')),
  },
  {
    id: 1005001,
    path: URLS.error,
    exact: true,
    header: false,
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./error-page')),
  },
  {
    id: 1005002,
    exact: true,
    header: false,
    accessConfig: null,
    layoutConfig: null,
    component: lazy(() => import('./error-page')),
  },
];
