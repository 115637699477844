import { memo } from 'react';
import { classNames } from 'utils/classNames';

import {
  DownloadIcon,
  ArrowRightWhiteIcon,
  ArrowLeft,
  ArrowDownGold,
  MobileLeftArrowGold,
  MenuIcon,
  Ellipsis,
} from 'assets/svg';

const predefinedIcons = {
  downloadIcon: (
    <div className="postFix_icon">
      <DownloadIcon />
    </div>
  ),
  arrowDownGold: (
    <ArrowDownGold className="postFix_arrow_down_gold" />
  ),
  arrowRightWhiteIcon: (
    <div className="postFix_arrow">
      <ArrowRightWhiteIcon />
    </div>
  ),
  arrowLeft: (
    <ArrowLeft className="prefix_arrow_left" />
  ),
  arrowLeftMobileGold: (
    <MobileLeftArrowGold className="prefix_arrow_left" />
  ),
  hamburgerIcon: (
    <MenuIcon />
  ),
  ellipsis: (
    <Ellipsis />
  ),
};

/**
 * variant = "primary" | "secondary" | "toggle" | "toggleActive" | "menu" | "icon"
 * postFix = "downloadIcon" | "arrowRightWhiteIcon" |  "arrowDownGold" | arrowLeft | arrowLeftMobileGold | hamburgerIcon | ellipsis or react component
 */
export default memo(
  (
    {
      type = 'button',
      onClick,
      variant = 'primary',
      children,
      disabled,
      className = '',
      postFix,
      prefix,
      oval,
    },
  ) => {
    const handleOnClick = (event) => {
      if (typeof onClick === 'function') onClick(event);
    };

    const setComponent = (p) => (typeof p === 'string' ? predefinedIcons[p] : p);

    return (
      <button
        type={type}
        onClick={handleOnClick}
        disabled={disabled}
        className={classNames('btn', {
          [variant]: true,
          [className]: true,
          btn__oval: oval,
        })}
      >
        {prefix && setComponent(prefix)}
        {children}
        {postFix && setComponent(postFix)}
      </button>
    );
  },
);
