import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const huntAdRequestActionsApi = {
  cancelHuntAdOfferRequest({ id, data }) {
    return http({ url: `${API_URLS.huntAdRequest}/${id}/cancel`, method: 'POST', data });
  },
  rejectHuntAdOfferRequest({ id, data }) {
    return http({ url: `${API_URLS.huntAdRequest}/${id}/reject`, method: 'POST', data });
  },
  confirmHuntAdOfferRequest(id) {
    return http({ url: `${API_URLS.huntAdRequest}/${id}/confirm`, method: 'POST' });
  },
  setViewedRequest(id) {
    return http({ url: `${API_URLS.huntAdRequest}/${id}/viewed`, method: 'PATCH' });
  },
  getOfferByRequestIdRequest(url) {
    return http({ url });
  },
  getDraftPreviewRequest({ id, companyId }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/preview/${id}` });
  },
  getUnViewedCountsRequest(params) {
    return http({ url: `${API_URLS.huntAdRequest}/un-viewed`, params });
  },
};
