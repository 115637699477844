import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const authenticateApi = {
  adminSignInRequest(encodedUserData) {
    return http({
      url: API_URLS.adminSignIn,
      method: 'POST',
      headers: {
        Authorization: `Basic ${encodedUserData}`,
      },
    });
  },
  hunterSignInRequest(encodedUserData) {
    return http({
      url: API_URLS.hunterSignIn,
      method: 'POST',
      headers: {
        Authorization: `Basic ${encodedUserData}`,
      },
    });
  },
  hunterSignUpRequest(data) {
    return http({
      url: API_URLS.hunterSignUp,
      method: 'POST',
      data,
    });
  },
  companySignInRequest(encodedUserData) {
    return http({
      url: API_URLS.companySignIn,
      method: 'POST',
      headers: {
        Authorization: `Basic ${encodedUserData}`,
      },
    });
  },
  companySignUpRequest(data) {
    return http({
      url: API_URLS.companySignUp,
      method: 'POST',
      data,
    });
  },
  logOutRequest() {
    return () => new Promise((resolve) => setTimeout(() => resolve([]), 1000));
  },
};
