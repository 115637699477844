import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import * as types from './types';
import * as actions from './actions';
import { packageApi } from './api';

function* getAllPackages({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, params: payload };
    const response = yield call(packageApi.getAllPackagesRequest, data);

    yield put(actions.getAllPackagesSuccess(response));
  } catch (error) {
    yield put(actions.getAllPackagesFail(error));
  }
}

function* getPackageById({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, packageId: payload.id };
    const response = yield call(packageApi.getPackageByIdRequest, data);

    yield put(actions.getPackageByIdSuccess(response));
  } catch (error) {
    yield put(actions.getPackageByIdFail(error));
  }
}

function* createPackage({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, data: payload };
    const response = yield call(packageApi.createPackageRequest, data);

    yield put(actions.createPackageSuccess(response));
    yield put(actions.getAllPackages(payload.fetchListParams));
  } catch (error) {
    yield put(actions.createPackageFail(error));
  }
}

function* updatePackage({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, packageId: payload.id, data: payload.data };
    const response = yield call(packageApi.updatePackageRequest, data);

    yield put(actions.updatePackageSuccess(response));
    yield put(actions.getAllPackages(payload.fetchListParams));
  } catch (error) {
    yield put(actions.updatePackageFail(error));
  }
}

function* removePackage({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, packageId: payload.id };
    const response = yield call(packageApi.removePackageRequest, data);

    yield put(actions.removePackageSuccess(response));
    yield put(actions.getAllPackages(payload.fetchListParams));
  } catch (error) {
    yield put(actions.removePackageFail(error));
  }
}

function* packages() {
  yield takeLatest(types.GET_ALL_PACKAGES, getAllPackages);
  yield takeLatest(types.GET_PACKAGE_BY_ID, getPackageById);
  yield takeLatest(types.CREATE_PACKAGE, createPackage);
  yield takeLatest(types.UPDATE_PACKAGE, updatePackage);
  yield takeLatest(types.REMOVE_PACKAGE, removePackage);
}

export default packages;
