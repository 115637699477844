import hookForm from '@laszloblum/hook-easy-form';

import {
  Modal, Text, Button, TextArea,
} from 'components';

import styles from './style.module.scss';

const generateForm = (translations) => [
  {
    name: 'message',
    value: '',
    options: {
      placeholder: translations.placeholder,
    },
    validate: {
      required: (v) => {
        if (!v) {
          // eslint-disable-next-line no-console
          console.log('translations.required');
          return translations.required;
        } if (v.trim().length === 0) {
          // eslint-disable-next-line no-console
          console.log('translations.invalid');
          return translations.invalid;
        }
        // eslint-disable-next-line no-console
        console.log('translations.  empty');
        return '';
      },
    },
  },
];

export const DialogModal = ({
  closeEvent,
  translations,
  successAction,
  denyAction,
  className = '',
  text = false,
}) => {
  const { formObject, updateEvent, submitEvent } = hookForm({ initialForm: generateForm(translations) });
  const { message } = formObject;

  const accept = () => {
    successAction();
    closeEvent();
  };

  const submit = (v) => {
    successAction(v);
    closeEvent();
  };

  return (
    <Modal closeEvent={closeEvent} className={`${styles['dialog-modal']} ${className}`}>
      <Text type="subtitle" as="h3" className={styles['dialog-modal__title']}>
        {translations.modalTitle}
      </Text>

      {text && (
        <TextArea
          name={message.name}
          value={message.value}
          error={message.error}
          touched={message.touched}
          onChange={updateEvent}
          className={styles['dialog-modal__text-area']}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...message.options}
        />
      )}
      <div className={styles.btn_group}>
        <Button onClick={text ? submitEvent(submit) : accept} variant="primary">
          {translations.modalAccept}
        </Button>
        <Button onClick={denyAction} variant="secondary">
          {translations.modalDeny}
        </Button>
      </div>
    </Modal>
  );
};
