import I18Next from 'i18next';
import { getLocaleFromCookie, LOCALE } from './cookie-actions';

/**
 * comment out backend property for upload local json from public folder
 * after update public locale, please update json files on s3
 */

const config = {
  // backend: {
  //   loadPath: 'https://huntengo-public-bucket.s3.eu-central-1.amazonaws.com/locales/{{lng}}/{{ns}}.json',
  //   crossDomain: true,
  // },
  lng: getLocaleFromCookie(),
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupCookie: LOCALE,
    cookieOptions: { path: '/', sameSite: 'strict' },
  },
  fallbackLng: 'hu',
  debug: process.env.NODE_ENV === 'development',
  whitelist: ['hu', 'en'],
  nonExplicitWhitelist: true,
  load: 'languageOnly',

  ns: ['translate', 'form', 'publicOffer', 'header', 'searchResults', 'signUps'],
  defaultNS: 'translate',

  interpolation: { escapeValue: false },

  react: { wait: true },
};

export const i18nInit = () => I18Next.init(config);

export const i18nUse = (data) => I18Next.use(data);
