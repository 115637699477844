import {
  call, takeLatest, put, select, delay,
} from 'redux-saga/effects';

import { orderStatuses } from 'constants/index';
import * as types from './types';
import * as actions from './actions';
import { setToast } from '../app/actions';
import { ordersActionsApi } from './api';

const getFunction = (status) => {
  if (status === orderStatuses.CANCEL) return ordersActionsApi.cancelOrderRequest;
  if (status === orderStatuses.READY_FOR_INVOICE1) return ordersActionsApi.readyForInvoice1OrderRequest;
  if (status === orderStatuses.ORDERED) return ordersActionsApi.orderedOrderRequest;
  if (status === orderStatuses.PAYED) return ordersActionsApi.payedOrderRequest;
  if (status === orderStatuses.READY_FOR_INVOICE2) return ordersActionsApi.readyForInvoice2OrderRequest;
  if (status === orderStatuses.HUNT_REJECTED) return ordersActionsApi.huntRejectedRequest;
  if (status === orderStatuses.HUNT_APPROVED) return ordersActionsApi.huntApprovedRequest;
  if (status === orderStatuses.READY_FOR_INVOICE3) return ordersActionsApi.readyForInvoice3OrderRequest;
  if (status === orderStatuses.PAYOUT) return ordersActionsApi.payoutOrderRequest;
  if (status === orderStatuses.COMPLETED) return ordersActionsApi.completedOrderRequest;
  if (status === orderStatuses.RECEIVED) return ordersActionsApi.receivedOrderRequest;

  if (status === orderStatuses.ACKNOWLEDGED) return ordersActionsApi.acknowledgedOrderRequest;
  if (status === orderStatuses.REJECTED) return ordersActionsApi.rejectOrderRequest;
  if (status === orderStatuses.HUNT_COMPLETED) return ordersActionsApi.huntCompletedOrderRequest;

  return () => {};
};

function* orderCompanyAdminAction({ payload }) {
  const { cb, status, ...rest } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const order = yield select((s) => s.orders.order);
    const requestData = { companyId: currentUser.company.id, id: order.id, ...rest };

    const func = getFunction(status);
    yield call(func, requestData);

    if (cb && typeof cb === 'function') {
      yield delay(500);
      yield cb();
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* orderAdminAction({ payload }) {
  const { cb, status, ...rest } = payload;
  try {
    const order = yield select((s) => s.orders.order);

    // status is status what we want to achieve
    const func = getFunction(status);
    yield call(func, { id: order.id, ...rest });

    if (cb && typeof cb === 'function') {
      yield delay(500);
      yield cb();
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* removeDocument({ payload }) {
  const { key } = payload;
  try {
    const order = yield select((s) => s.orders.order);
    const requestData = { id: order.id, key };
    yield call(ordersActionsApi.removeDocumentRequest, requestData);

    yield put(actions.removeDocumentSuccess({ document: key }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* orderActions() {
  yield takeLatest(types.ORDER_COMPANY_ADMIN_ACTION, orderCompanyAdminAction);
  yield takeLatest(types.ORDER_ADMIN_ACTION, orderAdminAction);
  yield takeLatest(types.REMOVE_DOCUMENT, removeDocument);
}

export default orderActions;
