import { call, put, takeLatest } from 'redux-saga/effects';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { i18nInit, i18nUse } from 'utils/i18n';

import { getTokenFromCookie } from 'utils/cookie-actions';
import * as types from './types';
import * as actions from './actions';
import { appApi } from './api';

function* getMe() {
  try {
    const response = yield call(appApi.getMeRequest);

    yield put(actions.getMeSuccess(response.data));
  } catch (error) {
    yield put(actions.getMeFail(error));
  }
}

function* getGlobalConfig() {
  try {
    const response = yield call(appApi.getGlobalConfigRequest);
    yield put(actions.getGlobalConfigSuccess(response.data));
  } catch (error) {
    yield put(actions.getGlobalConfigFail(error));
  }
}

function* initialApp() {
  try {
    // initialize i18next
    yield call(i18nUse, LanguageDetector);
    yield call(i18nUse, XHR);
    yield call(i18nInit);

    // initialize user
    const token = yield getTokenFromCookie();
    if (token) yield call(getMe);

    yield put(actions.initialAppSuccess());
  } catch (error) {
    yield put(actions.initialAppFail(error));
  }
}

function* app() {
  yield takeLatest(types.INITIAL_APP, initialApp);
  yield takeLatest(types.GET_ME, getMe);
  yield takeLatest(types.GET_GLOBAL_CONFIG, getGlobalConfig);
}

export default app;
