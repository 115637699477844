import * as types from './types';

import { initialStore } from '../initial-store';

export const restorePasswordReducer = (state = initialStore.restorePassword, action) => {
  switch (action.type) {
    case types.RESTORE_PASSWORD_USER:
    case types.RESTORE_PASSWORD_USER_FAIL:
    case types.RESTORE_PASSWORD_USER_SUCCESS:
    case types.RESTORE_PASSWORD_ADMIN:
    case types.RESTORE_PASSWORD_ADMIN_FAIL:
    case types.RESTORE_PASSWORD_ADMIN_SUCCESS:
    case types.RESTORE_PASSWORD_HUNTER:
    case types.RESTORE_PASSWORD_HUNTER_SUCCESS:
    case types.RESTORE_PASSWORD_HUNTER_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
