import jwtDecode from 'jwt-decode';
import { getTokenFromCookie } from 'utils/cookie-actions';

export const decodeJwt = () => {
  const token = getTokenFromCookie();
  if (!token) return null;

  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('error', error);
    return null;
  }
};
