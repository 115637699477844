import * as types from './types';
import { SET_VIEWED_COMPANY_SUCCESS } from '../company-activation/types';
import { initialStore } from '../initial-store';

export const companyReducer = (state = initialStore.company, { type, payload }) => {
  switch (type) {
    case types.SUBMIT_COMPANY_SUCCESS: {
      return { ...state, payload };
    }

    case types.UPDATE_COMPANY_SUCCESS: {
      return { ...state, ...payload };
    }

    case types.UPDATE_COMPANY_MAP_SUCCESS: {
      return { ...state, map: payload };
    }

    case types.GET_COMPANY_MAP_SUCCESS: {
      const { map, name } = payload;
      return { ...state, map, selectedCompany: { ...state.selectedCompany, name } };
    }

    case types.UPDATE_COMPANY:
    case types.SUBMIT_COMPANY:
    case types.UPDATE_COMPANY_FAIL:
    case types.SUBMIT_COMPANY_FAIL: {
      return { ...state };
    }

    case types.GET_COMPANY:
    case types.GET_COMPANY_LIST: {
      return { ...state, isFetching: true };
    }
    case types.GET_COMPANY_FAIL:
    case types.GET_COMPANY_LIST_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_COMPANY_LIST_SUCCESS: {
      return {
        ...state, isFetching: false, companyList: payload.rows, count: payload.count,
      };
    }

    case types.GET_COMPANY_SUCCESS: {
      return { ...state, isFetching: false, selectedCompany: payload };
    }

    case types.GET_COMPANY_UN_VIEWED_COUNTS_SUCCESS: {
      return { ...state, unViewedCounts: payload };
    }

    case types.RESET_SELECTED_COMPANY: {
      return { ...state, selectedCompany: initialStore.company.selectedCompany };
    }

    case SET_VIEWED_COMPANY_SUCCESS: {
      const selectedCompany = state.selectedCompany && state.selectedCompany.id === payload.id
        ? { ...state.selectedCompany, viewed: new Date().toISOString() }
        : null;
      return { ...state, selectedCompany };
    }

    default:
      return state;
  }
};
