import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const messageApi = {
  getAllCompanyMessagesRequest(params) {
    return http({ url: API_URLS.getAllCompanyMessages, params });
  },
  getAllHunterMessagesRequest(params) {
    return http({ url: API_URLS.getAllHunterMessages, params });
  },
  getMessageByIdHunterRequest({ id, received }) {
    return http({ url: `${API_URLS.getMessageHunter}/${id}`, params: { received } });
  },
  getMessageByIdCompanyUserRequest({ id, received }) {
    return http({ url: `${API_URLS.getAllMessagesForCompany}/${id}`, params: { received } });
  },
  createCompanyMessageRequest({ data }) {
    return http({ url: API_URLS.getAllMessagesForCompany, method: 'POST', data });
  },
  getAllMessagesForCompanyRequest(params) {
    return http({ url: API_URLS.getAllMessagesForCompany, params });
  },
  getAllReceivedMessagesForCompanyRequest(params) {
    return http({ url: API_URLS.getAllReceivedMessagesForCompany, params });
  },
  getRecipientInfoRequest({ id }) {
    return http({ url: `${API_URLS.getAllMessagesForCompany}/${id}/recipient-info` });
  },
  viewByCompanyUserRequest({ id }) {
    return http({ url: `${API_URLS.getAllMessagesForCompany}/seen/${id}` });
  },
  viewByHunterRequest({ id }) {
    return http({ url: `${API_URLS.getMessageHunter}/seen/${id}` });
  },
};
