import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const ordersActionsApi = {
  acknowledgedOrderRequest({ companyId, id }) {
    return http({ url: `${API_URLS.getHuntOrdersByCompany(companyId)}/${id}/acknowledged`, method: 'POST' });
  },
  rejectOrderRequest({ companyId, id, data }) {
    return http({ url: `${API_URLS.getHuntOrdersByCompany(companyId)}/${id}/reject`, method: 'POST', data });
  },
  huntCompletedOrderRequest({ companyId, id }) {
    return http({ url: `${API_URLS.getHuntOrdersByCompany(companyId)}/${id}/hunt-completed`, method: 'POST' });
  },
  cancelOrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/cancel`, method: 'POST' });
  },
  readyForInvoice1OrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/invoice-1`, method: 'POST' });
  },
  orderedOrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/ordered`, method: 'POST' });
  },
  payedOrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/payed`, method: 'POST' });
  },
  readyForInvoice2OrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/invoice-2`, method: 'POST' });
  },
  huntRejectedRequest({ id, data }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/hunt-rejected`, method: 'POST', data });
  },
  huntApprovedRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/hunt-approved`, method: 'POST' });
  },
  readyForInvoice3OrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/invoice-3`, method: 'POST' });
  },
  payoutOrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/payout`, method: 'POST' });
  },
  receivedOrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/received`, method: 'POST' });
  },
  completedOrderRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}/completed`, method: 'POST' });
  },
  removeDocumentRequest({ id, key }) {
    return http({ url: `${API_URLS.orderDocuments}/${id}/${key}`, method: 'DELETE' });
  },
};
