import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const priceListApi = {
  getAllPriceListsRequest({ companyId, params }) {
    return http({ url: API_URLS.getPriceList(companyId), params });
  },
  getPriceListByIdRequest({ companyId, listId }) {
    return http({ url: `${API_URLS.getPriceList(companyId)}/${listId}` });
  },
  createPriceListRequest({ companyId, data }) {
    return http({ url: API_URLS.getPriceList(companyId), data, method: 'POST' });
  },
  updatePriceListRequest({ companyId, listId, data }) {
    return http({ url: `${API_URLS.getPriceList(companyId)}/${listId}`, data, method: 'PUT' });
  },
  removePriceListRequest({ companyId, listId }) {
    return http({ url: `${API_URLS.getPriceList(companyId)}/${listId}`, method: 'DELETE' });
  },
};
