import { http } from '../../utils/axios';
import { API_URLS } from '../../constants/api-endpoints';

export const memberApi = {

  getMembersRequest({ companyId, params }) {
    return http({ url: API_URLS.getMembers(companyId), params });
  },

  deleteMemberRequest(payload) {
    return http({ url: API_URLS.deleteMember(payload), method: 'DELETE' });
  },

  getMemberByIdRequest(payload) {
    return http({ url: API_URLS.getMemberById(payload) });
  },

  createMemberRequest({ companyId, data }) {
    return http({ url: API_URLS.createMember(companyId), method: 'POST', data });
  },

  updateMemberRequest({ companyId, memberId, data }) {
    return http({ url: API_URLS.updateMember({ companyId, memberId }), method: 'PATCH', data });
  },
};
