// getAdminById
export const GET_ADMIN_BY_ID = 'admin/GET_ADMIN_BY_ID';
export const GET_ADMIN_BY_ID_SUCCESS = 'admin/GET_ADMIN_BY_ID_SUCCESS';
export const GET_ADMIN_BY_ID_FAIL = 'admin/GET_ADMIN_BY_ID_FAIL';
// createAdmin
export const CREATE_ADMIN = 'admin/CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'admin/CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'admin/CREATE_ADMIN_FAIL';
// updateAdmin
export const UPDATE_ADMIN = 'admin/UPDATE_ADMIN';
export const UPDATE_ADMIN_SUCCESS = 'admin/UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'admin/UPDATE_ADMIN_FAIL';

// adminList
export const GET_ADMINS_LIST = 'admin/GET_ADMINS_LIST';
export const GET_ADMINS_LIST_SUCCESS = 'admin/GET_ADMINS_LIST_SUCCESS';
export const GET_ADMINS_LIST_FAIL = 'admin/GET_ADMINS_LIST_FAIL';

// deleteAdmin
export const DELETE_ADMIN = 'admin/DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'admin/DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'admin/DELETE_ADMIN_FAIL';

// other
export const RESET_ADMIN_FIELD = 'admin/RESET_ADMIN_FIELD';
