import { useMemo } from 'react';

import { Header } from 'components/header';
import { classNames } from 'utils/classNames';

const defaultConfig = {};

export const Layout = ({ header, children, ...layoutConfig }) => {
  const conf = useMemo(() => ({ ...defaultConfig, ...layoutConfig }), [layoutConfig]);
  return (
    <>
      {header && <Header config={{ ...header }} />}
      <main className={classNames('layout', conf.layoutWrapperClassName)}>{children}</main>
    </>
  );
};
