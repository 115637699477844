import { memo, createElement } from 'react';
import { classNames } from 'utils/classNames';

/**
 * types = callout | m-callout | title | subtitle | paragraph | m-paragraph | sm-paragraph | paragraph-light
 * | highlight | text | text-sans | m-caption | lg-caption | mobile
 * color: gold | meat | moss-green
 */

export default memo(({
  type = 'text',
  uppercase = false,
  underline = false,
  ellipsis,
  as = 'span',
  children,
  style,
  className = '',
  onClick,
  color,
}) => {
  const props = {
    className: classNames(type, {
      uppercase, underline, ellipsis, [className]: true, [color]: !!color,
    }),
    style,
    onClick,
  };

  return createElement(as, props, children);
});
