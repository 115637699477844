import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { initialApp } from 'store/app/actions';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import App from 'route/app';
import reportWebVitals from 'reportWebVitals';

import store from 'store';

// styles
import 'styles/index.scss';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';

const isMobile = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

store.dispatch(initialApp());

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={isMobile() ? TouchBackend : HTML5Backend}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </DndProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
