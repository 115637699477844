const Svg = ({ width = 272, height = 202 }) => (
  <svg width={width} height={height} viewBox="0 0 272 202" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.88454 68.353L50.8281 30.0337L90.1274 163.052L46.1838 201.371L6.88454 68.353Z"
      fill="#F3ECE0"
    />
    <path
      d="M108.541 38.3193L152.484 2.06908e-05L191.784 133.018L147.84 171.337L108.541 38.3193Z"
      fill="#F3ECE0"
    />
    <path
      d="M108.541 38.3193L50.8282 30.0337L90.1274 163.052L147.84 171.337L108.541 38.3193Z"
      fill="#E8DCC9"
    />
    <path
      d="M245.535 149.728C259.484 129.107 254.183 101.088 233.694 87.146C213.205 73.2038 185.287 78.6178 171.338
      99.2385C157.388 119.859 162.69 147.878 183.179 161.82C203.668 175.763 231.586 170.349 245.535 149.728Z"
      fill="white"
      stroke="#2C3831"
      strokeWidth="7"
      strokeMiterlimit="10"
    />
    <path
      d="M211.213 116.246L176.223 113.825L205.53 133.093L240.527 135.517L211.213 116.246Z"
      fill="#EBEBE8"
    />
    <path
      d="M236.657 74.8664L216.034 67.9097L212.537 78.2761L233.16 85.2328L236.657 74.8664Z"
      fill="#2C3831"
    />
    <path
      d="M199.658 150.504C185.387 145.69 177.712 130.097 182.552 115.751C187.391 101.405 202.943 93.6467
      217.213 98.4605C231.484 103.274 239.159 118.867 234.319 133.213C229.478 147.566 213.934 155.32
      199.658 150.504ZM216.659 100.104C203.288 95.5936 188.721 102.86 184.187 116.302C179.652 129.745
      186.841 144.35 200.212 148.86C213.583 153.371 228.15 146.104 232.684 132.662C237.219 119.22 230.03 104.615 216.659 100.104Z"
      fill="#2C3831"
    />
    <path
      d="M200.056 121.656L219.338 92.1619L216.814 127.308L200.056 121.656Z"
      fill="#BF8F60"
    />
    <path
      d="M216.814 127.308L197.531 156.808L200.057 121.656L216.814 127.308Z"
      fill="#AEB8AF"
    />
    <path
      d="M216.931 127.348C215.341 132.061 210.249 134.606 205.554 133.023C200.866
      131.441 198.348 126.336 199.94 121.616C201.53 116.903 206.622 114.358 211.316
      115.941C216.009 117.531 218.521 122.634 216.931 127.348Z"
      fill="#AEB8AF"
    />
    <path
      d="M213.679 126.251C212.697 129.162 209.553 130.73 206.657 129.753C203.761
      128.776 202.21 125.625 203.192 122.713C204.174 119.802 207.317 118.234 210.214
      119.211C213.108 120.194 214.659 123.345 213.679 126.251Z"
      fill="white"
    />
    <path
      d="M76 139L75.6297 140.454L76 139ZM112 86.5L112.538 85.0997L112 86.5ZM96.0001
      111L96.0001 109.5L96.0001 111ZM110.08 186.502C108.451 186.415 106.876 186.266
      105.355 186.06L104.952 189.033C106.556 189.25 108.212 189.406 109.92 189.498L110.08
      186.502ZM96.1738 183.945C93.0486 182.892 90.2187 181.554 87.6717 179.988L86.0999
      182.543C88.844 184.231 91.8804 185.664 95.2158 186.788L96.1738 183.945ZM80.3864
      174.169C78.1694 171.895 76.293 169.393 74.7442 166.735L72.1522 168.246C73.8195
      171.107 75.8433 173.807 78.2385 176.263L80.3864 174.169ZM71.003 158.159C70.0762
      155.142 69.492 152.041 69.2364 148.943L66.2466 149.189C66.5194 152.496 67.1432
      155.81 68.1352 159.04L71.003 158.159ZM69.4655 139.558C69.8878 136.392 70.6583
      133.337 71.7554 130.491L68.9563 129.412C67.7701 132.488 66.9436 135.774 66.4919
      139.162L69.4655 139.558ZM76.2358 122.38C78.1307 119.901 80.3751 117.793 82.942
      16.171L81.3391 113.635C78.4455 115.464 75.9433 117.822 73.8521 120.559L76.2358
      122.38ZM91.4079 112.898C92.8687 112.637 94.3998 112.5 96.0001 112.5L96.0001
      109.5C94.2278 109.5 92.5203 109.652 90.8811 109.944L91.4079 112.898ZM96.0001
      112.5C98.0469 112.5 99.8686 112.652 101.478 112.927L101.983 109.97C100.186 109.662
      98.1932 109.5 96.0001 109.5L96.0001 112.5ZM110.357 117.6C112.123 120.142 112.137
      123.51 110.355 126.969L113.022 128.343C115.163 124.186 115.386 119.582 112.822
      115.889L110.357 117.6ZM102.998 134.537C100.192 136.278 96.7698 137.642 92.8273
      138.351L93.3585 141.303C97.6624 140.529 101.443 139.032 104.579 137.087L102.998
      134.537ZM81.8374 138.585C80.0745 138.367 78.2504 138.025 76.3703 137.546L75.6297
      140.454C77.6293 140.963 79.5773 141.328 81.4681 141.563L81.8374 138.585ZM76.3703
      137.546C74.8321 137.155 73.3532 136.716 71.9332 136.236L70.9713 139.077C72.4659
      139.583 74.0187 140.043 75.6297 140.454L76.3703 137.546ZM63.5175 132.55C60.6791
      130.979 58.1852 129.209 56.0304 127.3L54.0409 129.546C56.3673 131.607 59.0426
      133.502 62.0648 135.174L63.5175 132.55ZM50.0902 120.49C48.3611 117.852 47.1303
      115.097 46.3866 112.328L43.4893 113.106C44.319 116.195 45.6846 119.241 47.5811
      122.135L50.0902 120.49ZM45.6585 103.491C45.9724 100.547 46.8559 97.7017 48.3045
      95.0816L45.6791 93.63C44.0334 96.6064 43.0309 99.8377 42.6754 103.173L45.6585
      103.491ZM53.9946 88.3006C56.1496 86.5263 58.7267 84.9915 61.7334 83.7848L60.6159
      81.0006C57.3419 82.3147 54.495 84.0026 52.0878 85.9845L53.9946 88.3006ZM70.5356
      81.4433C73.3512 81.0166 76.4109 80.8184 79.7155 80.8873L79.778 77.8879C76.3154
      77.8158 73.0838 78.0229 70.0861 78.4771L70.5356 81.4433ZM88.935 81.7047C91.8455
      82.1452 94.9038 82.7684 98.1096 83.5922L98.8562 80.6866C95.5584 79.8392 92.4007
      79.1951 89.3839 78.7385L88.935 81.7047ZM107.006 86.2761C108.466 86.7788 109.951
      87.3197 111.462 87.9002L112.538 85.0997C110.995 84.5069 109.476 83.954 107.983
      83.4396L107.006 86.2761ZM111.462 87.9002C113.264 88.5922 115.033 89.1397 116.767
      89.5505L117.459 86.6314C115.861 86.2528 114.22 85.7459 112.538 85.0997L111.462
      87.9002ZM127.902 90.3379C131.78 89.9288 135.362 88.7862 138.596 87.0644L137.187
      84.4162C134.282 85.9625 131.07 86.987 127.587 87.3544L127.902 90.3379ZM147.357
      80.1557C149.812 77.4387 151.873 74.314 153.504 70.9351L150.802 69.6314C149.286
      72.773 147.38 75.6554 145.131 78.1445L147.357 80.1557ZM157.011 60.4626C157.74
      56.8651 158.038 53.1543 157.867 49.4603L154.87 49.5993C155.03 53.0409 154.752
      56.505 154.071 59.8672L157.011 60.4626ZM155.907 38.5724C154.768 35.0777 153.129
      31.724 150.948 28.6428L148.499 30.3762C150.496 33.1968 152.004 36.2766 153.055
      39.5017L155.907 38.5724ZM143.191 20.6844C140.387 18.5808 137.163 16.7844 133.503
      15.373L132.423 18.1722C135.836 19.488 138.815 21.1525 141.39 23.0842L143.191
      20.6844ZM122.864 12.6023C119.489 12.0871 115.854 11.8337 111.951 11.8744L111.982
      14.8743C115.736 14.8351 119.209 15.0791 122.412 15.5679L122.864 12.6023ZM101.162
      12.6678C99.3969 12.9002 97.5866 13.185 95.7304 13.5244L96.2701 16.4754C98.0788
      16.1447 99.8397 15.8678 101.554 15.6421L101.162 12.6678Z"
      fill="#BF8F60"
    />
    <circle cx="33" cy="81" r="4" fill="#BF8F60" />
    <circle cx="92" cy="52" r="4" fill="#BF8F60" />
    <circle cx="144" cy="109" r="4" fill="#BF8F60" />
  </svg>
);

export default Svg;
