import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const packageApi = {
  getAllPackagesRequest({ companyId, params }) {
    return http({ url: API_URLS.getPackage(companyId), params });
  },
  getPackageByIdRequest({ companyId, packageId }) {
    return http({ url: `${API_URLS.getPackage(companyId)}/${packageId}` });
  },
  createPackageRequest({ companyId, data }) {
    return http({ url: API_URLS.getPackage(companyId), data, method: 'POST' });
  },
  updatePackageRequest({ companyId, packageId, data }) {
    return http({ url: `${API_URLS.getPackage(companyId)}/${packageId}`, data, method: 'PUT' });
  },
  removePackageRequest({ companyId, packageId }) {
    return http({ url: `${API_URLS.getPackage(companyId)}/${packageId}`, method: 'DELETE' });
  },
};
