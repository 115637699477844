import * as types from './types';

import { initialStore } from '../initial-store';

export const markedplaceReducer = (state = initialStore.markedPlace, { payload, type }) => {
  switch (type) {
    case types.GET_ALL_MARKEDPLACES: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_MARKEDPLACES_SUCCESS: {
      const { count, rows } = payload;
      return {
        ...state,
        isFetching: false,
        count,
        rows,
      };
    }
    case types.GET_ALL_MARKEDPLACES_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_MARKEDPLACE_BY_ID: {
      return { ...state, isFetching: true };
    }
    case types.GET_MARKEDPLACE_BY_ID_SUCCESS: {
      return { ...state, selectedMarkedPlace: payload, isFetching: false };
    }
    case types.GET_MARKEDPLACE_BY_ID_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.SHARE_MARKED_PLACE_SUCCESS: {
      return {
        ...state,
        selectedMarkedPlace: {
          ...state.selectedMarkedPlace,
          shared: payload.share,
        },
      };
    }

    case types.SHARE_MARKED_PLACE:
    case types.SHARE_MARKED_PLACE_FAIL:
    case types.CREATE_MARKEDPLACE:
    case types.CREATE_MARKEDPLACE_SUCCESS:
    case types.CREATE_MARKEDPLACE_FAIL:
    case types.UPDATE_MARKEDPLACE:
    case types.UPDATE_MARKEDPLACE_SUCCESS:
    case types.UPDATE_MARKEDPLACE_FAIL:
    case types.REMOVE_MARKEDPLACE:
    case types.REMOVE_MARKEDPLACE_SUCCESS:
    case types.REMOVE_MARKEDPLACE_FAIL: {
      return { ...state };
    }

    case types.RESET_SELECTED_MARKEDPLACE: {
      return { ...state, selectedMarkedPlace: null };
    }

    default:
      return state;
  }
};
