// allOrders
export const GET_ALL_ORDERS = 'order/GET_ALL_ORDERS';
export const GET_ALL_ORDERS_SUCCESS = 'order/GET_ALL_ORDERS_SUCCESS';
export const GET_ALL_ORDERS_FAIL = 'order/GET_ALL_ORDERS_FAIL';

// orderById
export const GET_ORDER_BY_ID = 'order/GET_ORDER_BY_ID';
export const GET_ORDER_BY_ID_SUCCESS = 'order/GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_FAIL = 'order/GET_ORDER_BY_ID_FAIL';

// getUnViewedCount
export const GET_UN_VIEWED_COUNT_FOR_ORDER = 'order/GET_UN_VIEWED_COUNT_FOR_ORDER';
export const GET_UN_VIEWED_COUNT_FOR_ORDER_SUCCESS = 'order/GET_UN_VIEWED_COUNT_FOR_ORDER_SUCCESS';
export const GET_UN_VIEWED_COUNT_FOR_ORDER_FAIL = 'order/GET_UN_VIEWED_COUNT_FOR_ORDER_FAIL';

// createOrder
export const CREATE_ORDER = 'order/CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'order/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'order/CREATE_ORDER_FAIL';

// updateOrder
export const UPDATE_ORDER = 'order/UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'order/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'order/UPDATE_ORDER_FAIL';
// other
export const RESET_ORDER = 'order/RESET_ORDER';
