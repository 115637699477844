const Svg = () => (
  <svg width="88" height="96" viewBox="0 0 88 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <rect x="28" y="32" width="32" height="32" rx="16" fill="#2C3831" />
      <path d="M44.4608 39.3829C44.289 38.975 43.711 38.9749 43.5392 39.3829L37.3617 54.0545C37.1914 54.4589 37.5873 54.8708 37.998 54.7167L43.4733 52.6635C43.8129 52.5362 44.1871 52.5362 44.5267 52.6635L50.002 54.7167C50.4127 54.8708 50.8086 54.4589 50.6383 54.0545L44.4608 39.3829Z" fill="white" stroke="#AEB8AF" />
      <path d="M67.9434 51.0004V44.1914H72.0684V45.3134H69.2964V46.8424H71.7054V47.9644H69.2964V49.8784H72.3104V51.0004H67.9434Z" fill="#2C3831" />
      <path d="M43.8024 79.0101C44.0298 79.0101 44.2168 78.9881 44.3634 78.9441C44.5174 78.8928 44.6384 78.8268 44.7264 78.7461C44.8144 78.6581 44.8731 78.5628 44.9024 78.4601C44.9391 78.3501 44.9574 78.2401 44.9574 78.1301C44.9574 77.9908 44.9171 77.8661 44.8364 77.7561C44.7558 77.6461 44.6531 77.5508 44.5284 77.4701C44.4038 77.3821 44.2644 77.3051 44.1104 77.2391C43.9564 77.1658 43.8024 77.0998 43.6484 77.0411C43.4431 76.9678 43.2304 76.8834 43.0104 76.7881C42.7904 76.6928 42.5888 76.5718 42.4054 76.4251C42.2294 76.2784 42.0828 76.0988 41.9654 75.8861C41.8481 75.6661 41.7894 75.3948 41.7894 75.0721C41.7894 74.4341 41.9948 73.9354 42.4054 73.5761C42.8234 73.2168 43.3954 73.0371 44.1214 73.0371C44.5394 73.0371 44.9024 73.0848 45.2104 73.1801C45.5258 73.2754 45.7898 73.3818 46.0024 73.4991L45.6064 74.5441C45.4231 74.4414 45.2178 74.3534 44.9904 74.2801C44.7704 74.2068 44.5138 74.1701 44.2204 74.1701C43.5018 74.1701 43.1424 74.4304 43.1424 74.9511C43.1424 75.0831 43.1791 75.2004 43.2524 75.3031C43.3258 75.3984 43.4174 75.4864 43.5274 75.5671C43.6448 75.6404 43.7731 75.7064 43.9124 75.7651C44.0591 75.8238 44.2021 75.8788 44.3414 75.9301C44.5541 76.0108 44.7741 76.1024 45.0014 76.2051C45.2361 76.3004 45.4488 76.4324 45.6394 76.6011C45.8374 76.7624 45.9988 76.9678 46.1234 77.2171C46.2481 77.4591 46.3104 77.7671 46.3104 78.1411C46.3104 78.7791 46.1014 79.2741 45.6834 79.6261C45.2728 79.9708 44.6458 80.1431 43.8024 80.1431C43.2378 80.1431 42.7868 80.0844 42.4494 79.9671C42.1121 79.8424 41.8591 79.7288 41.6904 79.6261L42.0864 78.5261C42.2844 78.6434 42.5191 78.7534 42.7904 78.8561C43.0691 78.9588 43.4064 79.0101 43.8024 79.0101Z" fill="#2C3831" />
      <path d="M18.5059 46.6004C18.5353 46.7397 18.5793 46.9121 18.6379 47.1174C18.6966 47.3227 18.7589 47.5501 18.8249 47.7994C18.8983 48.0487 18.9753 48.3164 19.0559 48.6024C19.1439 48.8884 19.2283 49.1817 19.3089 49.4824C19.3089 49.1011 19.3053 48.6941 19.2979 48.2614C19.2906 47.8214 19.2833 47.3741 19.2759 46.9194C19.2686 46.4647 19.2613 46.0064 19.2539 45.5444C19.2466 45.0751 19.2429 44.6241 19.2429 44.1914H20.4969C20.4749 44.7487 20.4529 45.3244 20.4309 45.9184C20.4089 46.5051 20.3833 47.0917 20.3539 47.6784C20.3246 48.2577 20.2879 48.8297 20.2439 49.3944C20.2073 49.9591 20.1633 50.4944 20.1119 51.0004H18.9899C18.8286 50.5384 18.6673 50.0434 18.5059 49.5154C18.3446 48.9801 18.1833 48.4704 18.0219 47.9864C17.8606 48.4484 17.6919 48.9507 17.5159 49.4934C17.3399 50.0361 17.1786 50.5384 17.0319 51.0004H15.9099C15.8513 50.4944 15.7999 49.9591 15.7559 49.3944C15.7119 48.8224 15.6716 48.2467 15.6349 47.6674C15.6056 47.0807 15.5799 46.4941 15.5579 45.9074C15.5359 45.3134 15.5176 44.7414 15.5029 44.1914H16.7569C16.7496 44.6241 16.7423 45.0714 16.7349 45.5334C16.7276 45.9954 16.7203 46.4574 16.7129 46.9194C16.7056 47.3741 16.6983 47.8214 16.6909 48.2614C16.6836 48.6941 16.6799 49.1011 16.6799 49.4824C16.7459 49.2477 16.8193 48.9911 16.8999 48.7124C16.9879 48.4337 17.0686 48.1587 17.1419 47.8874C17.2226 47.6161 17.2959 47.3667 17.3619 47.1394C17.4279 46.9121 17.4793 46.7324 17.5159 46.6004H18.5059Z" fill="#2C3831" />
      <path d="M45.1549 23.0004C44.8469 22.2451 44.5095 21.4934 44.1429 20.7454C43.7835 19.9901 43.3949 19.2347 42.9769 18.4794V23.0004H41.7559V16.1914H42.8449C43.0502 16.5141 43.2555 16.8661 43.4609 17.2474C43.6662 17.6214 43.8642 17.9954 44.0549 18.3694C44.2529 18.7434 44.4325 19.1064 44.5939 19.4584C44.7625 19.8104 44.9055 20.1184 45.0229 20.3824V16.1914H46.2439V23.0004H45.1549Z" fill="#2C3831" />
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="88" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feMorphology radius="5" operator="dilate" in="SourceAlpha" result="effect1_dropShadow" />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Svg;
