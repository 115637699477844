import * as types from './types';

// getAllMarkedPlaces
export const getAllMarkedPlaces = (payload) => ({
  type: types.GET_ALL_MARKEDPLACES,
  payload,
});
export const getAllMarkedPlacesSuccess = (payload) => ({
  type: types.GET_ALL_MARKEDPLACES_SUCCESS,
  payload,
});
export const getAllMarkedPlacesFail = (payload) => ({
  type: types.GET_ALL_MARKEDPLACES_FAIL,
  payload,
});

// getMarkedPlaceById
export const getMarkedPlaceById = (payload) => ({
  type: types.GET_MARKEDPLACE_BY_ID,
  payload,
});
export const getMarkedPlaceByIdSuccess = (payload) => ({
  type: types.GET_MARKEDPLACE_BY_ID_SUCCESS,
  payload,
});
export const getMarkedPlaceByIdFail = (payload) => ({
  type: types.GET_MARKEDPLACE_BY_ID_FAIL,
  payload,
});

// createMarkedPlace
export const createMarkedPlace = (payload) => ({
  type: types.CREATE_MARKEDPLACE,
  payload,
});
export const createMarkedPlaceSuccess = (payload) => ({
  type: types.CREATE_MARKEDPLACE_SUCCESS,
  payload,
});
export const createMarkedPlaceFail = (payload) => ({
  type: types.CREATE_MARKEDPLACE_FAIL,
  payload,
});

// updateMarkedPlace
export const updateMarkedPlace = (payload) => ({
  type: types.UPDATE_MARKEDPLACE,
  payload,
});
export const updateMarkedPlaceSuccess = (payload) => ({
  type: types.UPDATE_MARKEDPLACE_SUCCESS,
  payload,
});
export const updateMarkedPlaceFail = (payload) => ({
  type: types.UPDATE_MARKEDPLACE_FAIL,
  payload,
});

// removeMarkedPlace
export const removeMarkedPlace = (payload) => ({
  type: types.REMOVE_MARKEDPLACE,
  payload,
});
export const removeMarkedPlaceSuccess = (payload) => ({
  type: types.REMOVE_MARKEDPLACE_SUCCESS,
  payload,
});
export const removeMarkedPlaceFail = (payload) => ({
  type: types.REMOVE_MARKEDPLACE_FAIL,
  payload,
});

// shareMarkedPlace
export const shareMarkedPlace = (payload) => ({
  type: types.SHARE_MARKED_PLACE,
  payload,
});
export const shareMarkedPlaceSuccess = (payload) => ({
  type: types.SHARE_MARKED_PLACE_SUCCESS,
  payload,
});
export const shareMarkedPlaceFail = (payload) => ({
  type: types.SHARE_MARKED_PLACE_FAIL,
  payload,
});

// resetSelectedMarkedPlace
export const resetSelectedMarkedPlace = (payload) => ({
  type: types.RESET_SELECTED_MARKEDPLACE,
  payload,
});
