import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const ordersApi = {
  getAllOrdersRequest({ params }) {
    return http({ url: API_URLS.getHuntOrders, params });
  },
  getAllOrdersByCompanyRequest({ id, params }) {
    return http({ url: API_URLS.getHuntOrdersByCompany(id), params });
  },
  getOrderByIdRequest({ id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}` });
  },
  getUnViewedCountRequest() {
    return http({ url: `${API_URLS.getHuntOrders}/un-viewed` });
  },
  getOrderByIdByCompanyRequest({ companyId, id }) {
    return http({ url: `${API_URLS.getHuntOrdersByCompany(companyId)}/${id}` });
  },
  createOrderRequest(data) {
    return http({ url: API_URLS.getHuntOrders, method: 'POST', data });
  },
  updateOrderRequest({ data, id }) {
    return http({ url: `${API_URLS.getHuntOrders}/${id}`, method: 'PATCH', data });
  },
};
