import * as types from './types';

import { initialStore } from '../initial-store';

export const huntAdOfferReducer = (state = initialStore.huntAdOffer, { type, payload }) => {
  switch (type) {
    case types.GET_HUNT_AD_OFFER_BY_ID_SUCCESS: {
      return { ...state, currentHuntAdOffer: payload };
    }

    default: {
      return state;
    }
  }
};
