import { classNames } from 'utils/classNames';
import { getAvatarImage } from 'utils/get-images';

const Avatar = ({ avatarUrl, ...rest }) => (
  <div className={classNames({ avatar: true, 'avatar__no-avatar': !avatarUrl })} {...rest}>
    {avatarUrl && <img src={getAvatarImage(avatarUrl, new Date().getTime())} alt="avatar" />}
  </div>
);

export default Avatar;
