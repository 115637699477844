import ReactDOM from 'react-dom';
import { useEffect, useRef, useMemo } from 'react';
import { classNames } from 'utils/classNames';
import { CloseIcon } from 'assets/svg';
import { useResizeParams } from 'components/hooks/useResizeParams';

const Modal = ({
  closeEvent,
  children,
  className = '',
  closeIcon = true,
  maxWidth = 541,
}) => {
  const modal = useRef();
  const { width: windowWidth } = useResizeParams();

  const width = useMemo(() => {
    if (windowWidth >= maxWidth) return maxWidth;

    return windowWidth - (20 * 2);
  }, [windowWidth, maxWidth]);

  const handleClickOutside = (event) => {
    if (modal.current) {
      if (event.target instanceof HTMLElement && !modal.current.contains(event.target)) closeEvent();
    }
  };

  useEffect(() => {
    const collection = document.getElementsByTagName('body');
    if (collection.length !== 0) collection[0].setAttribute('style', 'overflow: hidden');
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      if (collection.length !== 0) collection[0].setAttribute('style', 'overflow: initial');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return ReactDOM.createPortal(
    <div role="button" tabIndex="0" onClick={(event) => event.stopPropagation()} className="modal-wrapper">
      <div style={{ width }} className={classNames('modal', className)} ref={modal}>
        {closeIcon && (
        <div onClick={closeEvent} role="button" tabIndex="0" className="modal__closeIcon">
          <CloseIcon />
        </div>
        )}
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
