const Svg = ({ width = 15, height = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99997 8.58597L1.70697 5.29297L0.292969 6.70697L4.99997
      11.414L14.707 1.70697L13.293 0.292969L4.99997 8.58597Z"
      fill="#2C3831"
    />
  </svg>
);

export default Svg;
