import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useMobile } from 'components/hooks/is-mobile';
import { useHeaderLabel } from 'components/hooks/useHeaderLabel';
import { Button, Text } from 'components';
import { Logo, LogoText, MobileLogo } from 'assets/svg';
import { classNames } from 'utils/classNames';
import { useHistory } from 'react-router-dom';
import Nav from './nav';

import './style.scss';

export const Header = ({ config = {} }) => {
  const { t } = useTranslation('header');
  const currentUser = useSelector((s) => s.auth.currentUser);
  const { isMobile, width } = useMobile();

  const label = useHeaderLabel(currentUser, t);

  if (config.hideBreakpoints && width < config.hideBreakpoints) {
    return null;
  }
  const history = useHistory();

  const goToMain = () => {
    history.push('/');
  };

  return (
    <header className="header">
      <div className="header__logo-container" role="button" onClick={goToMain} tabIndex={0}>
        <div className="">
          <div className="header__logo-image">
            {isMobile
              ? (
                <>
                  <div id="mobileLogo"><MobileLogo /></div>
                </>
              ) : <Logo height={39} width={25} />}
          </div>
          <div className="header__mobile-back-button" id="mobileBackButton" />
        </div>
        <div className="header__logo-text">
          <LogoText height={21.5} width={99.5} />
        </div>
        {!isMobile && <Text type="m-caption" uppercase className="header__label">{label}</Text>}
      </div>

      <div className={classNames('header__nav-container', { flex: !currentUser })}>
        {!currentUser ? (
          <Button className="header__right-button" variant="primary" oval onClick={goToMain}>
            {t('back_home')}
          </Button>
        )
          : (
            <Nav
              currentUser={currentUser}
              isMobile={isMobile}
            />
          )}
      </div>
    </header>
  );
};
