import * as types from './types';

// getAllPackages
export const getAllPackages = (payload) => ({
  type: types.GET_ALL_PACKAGES,
  payload,
});
export const getAllPackagesSuccess = (payload) => ({
  type: types.GET_ALL_PACKAGES_SUCCESS,
  payload,
});
export const getAllPackagesFail = (payload) => ({
  type: types.GET_ALL_PACKAGES_FAIL,
  payload,
});

// getPackageById
export const getPackageById = (payload) => ({
  type: types.GET_PACKAGE_BY_ID,
  payload,
});
export const getPackageByIdSuccess = (payload) => ({
  type: types.GET_PACKAGE_BY_ID_SUCCESS,
  payload,
});
export const getPackageByIdFail = (payload) => ({
  type: types.GET_PACKAGE_BY_ID_FAIL,
  payload,
});

// createPackage
export const createPackage = (payload) => ({
  type: types.CREATE_PACKAGE,
  payload,
});
export const createPackageSuccess = (payload) => ({
  type: types.CREATE_PACKAGE_SUCCESS,
  payload,
});
export const createPackageFail = (payload) => ({
  type: types.CREATE_PACKAGE_FAIL,
  payload,
});

// updatePackage
export const updatePackage = (payload) => ({
  type: types.UPDATE_PACKAGE,
  payload,
});
export const updatePackageSuccess = (payload) => ({
  type: types.UPDATE_PACKAGE_SUCCESS,
  payload,
});
export const updatePackageFail = (payload) => ({
  type: types.UPDATE_PACKAGE_FAIL,
  payload,
});

// removePackage
export const removePackage = (payload) => ({
  type: types.REMOVE_PACKAGE,
  payload,
});
export const removePackageSuccess = (payload) => ({
  type: types.REMOVE_PACKAGE_SUCCESS,
  payload,
});
export const removePackageFail = (payload) => ({
  type: types.REMOVE_PACKAGE_FAIL,
  payload,
});
