import * as types from './types';

import { initialStore } from '../initial-store';

export const companyUserReducer = (state = initialStore.companyUsers, { type, payload }) => {
  switch (type) {
    case types.GET_COMPANY_USER_BY_ID:
    case types.GET_ALL_COMPANY_USERS: {
      return { ...state, isFetching: true };
    }

    case types.GET_ALL_COMPANY_USERS_SUCCESS: {
      const { count, rows } = payload;
      return {
        ...state, isFetching: false, usersList: rows, count,
      };
    }

    case types.GET_COMPANY_USER_BY_ID_SUCCESS: {
      const fields = payload.role.reduce((acc, el) => ({ ...acc, [el]: true }), {});
      return { ...state, isFetching: false, selectedUser: { ...payload, ...fields } };
    }

    case types.GET_COMPANY_USER_BY_ID_FAIL:
    case types.GET_ALL_COMPANY_USERS_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.RESET_COMPANY_USER_FIELDS: {
      return { ...state, selectedUser: null };
    }

    case types.CREATE_COMPANY_USER:
    case types.CREATE_COMPANY_USER_SUCCESS:
    case types.CREATE_COMPANY_USER_FAIL:
    case types.UPDATE_COMPANY_USER:
    case types.UPDATE_COMPANY_USER_SUCCESS:
    case types.UPDATE_COMPANY_USER_FAIL:
    case types.REMOVE_COMPANY_USER:
    case types.REMOVE_COMPANY_USER_SUCCESS:
    case types.REMOVE_COMPANY_USER_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
