import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const companyActivationApi = {
  setPaymentConfirmedStatusRequest(id) {
    return http({ url: API_URLS.paymentConfirmedStatus(id), method: 'PATCH' });
  },
  setContractSignedStatusRequest(id) {
    return http({ url: API_URLS.contractSignedStatus(id), method: 'PATCH' });
  },
  setWaitingForPaymentStatus(id) {
    return http({ url: API_URLS.waitingForPaymentStatus(id), method: 'PATCH' });
  },
  setMappingMeetingStatus(id) {
    return http({ url: API_URLS.mappingMeetingStatus(id), method: 'PATCH' });
  },
  setConfirmedStatus(id) {
    return http({ url: API_URLS.confirmedStatus(id), method: 'PATCH' });
  },
  setViewedCompanyRequest(id) {
    return http({ url: API_URLS.markedCompanyAsViewed(id), method: 'PATCH' });
  },
};
