import { useState, forwardRef } from 'react';

import {
  BinocularIcon,
  EyeIcon,
  EyeIconSelected,
  BxCalendar,
} from 'assets/svg';
import { classNames } from 'utils/classNames';

const predefinedIcons = {
  calendar: <BxCalendar />,
};

const CustomInput = forwardRef(({
  inputType = 'primary',
  className,
  type,
  label,
  id,
  placeholder,
  onClick,
  onChange,
  value,
  error,
  touched,
  disabled,
  wrapperClick,
  postFix,
  prefix,
  ...rest
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const setComponent = (p) => (typeof p === 'string' ? predefinedIcons[p] : p);

  const primaryPassword = (
    <>
      <div className={classNames({
        'input-container__primary-password': true,
        'input-container__error': !!(touched && error),
        'input-container__disabled': disabled,
      })}
      >
        <input
          type={showPassword ? 'text' : 'password'}
          className="input-container__primary-input"
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...rest}
        />
        <button className="input-container__icon-button" type="button" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <EyeIconSelected className="input-container__icon" /> : <EyeIcon className="input-container__icon" />}
        </button>
      </div>
      {touched && error && <div className="input-container__error-span"><span>{error}</span></div>}
    </>
  );

  const primary = (containerName, inputName) => (
    <>
      <div className={classNames({
        [`input-container__${containerName}`]: true,
        'input-container__error': !!(touched && error),
        'input-container__disabled': disabled,
      })}
      >
        {label && <label className="input-container__label" htmlFor={id}>{label}</label>}
        {prefix && setComponent(prefix)}
        <input
          type={type}
          className={`input-container__${inputName}`}
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...rest}
        />
        {containerName === 'primary-password'
          && (
          <button type="button" onClick={onClick} className="input-container__search_button">
            <BinocularIcon />
          </button>
          )}
        {postFix && setComponent(postFix)}
      </div>
      {touched && error && <div className="input-container__error-span"><span>{error}</span></div>}
    </>
  );

  const dateInput = (containerName, inputName) => (
    <>
      <div className={classNames({
        [`input-container__${containerName}`]: true,
        'input-container__error': !!(touched && error),
        'input-container__disabled': disabled,
      })}
      >
        {label && <label className="input-container__label" htmlFor={id}>{label}</label>}
        {prefix && setComponent(prefix)}
        <input
          type={type}
          className={`input-container__${inputName}`}
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...rest}
        />
        {postFix && setComponent(postFix)}
      </div>
      {touched && error && <div className="input-container__error-span"><span>{error}</span></div>}
    </>
  );

  const Input = () => {
    switch (inputType) {
      case 'primary': return primary('primary-container', 'primary-input');
      case 'dateInput':
        return dateInput('primary-container', 'primary-input');
      case 'primaryPassword': return primaryPassword;
      case 'secondary': return label
        ? primary('secondary-label', 'secondary-input')
        : primary('secondary', 'secondary-input');
      case 'secondaryGray': return primary('secondary-gray', 'secondary-input-gray');
      case 'searchBlack': return primary('primary-password', 'search-black-input');
      case 'searchGold': return primary('primary-password', 'search-gold-input');
      default: return primary('primary-container', 'primary-input');
    }
  };

  return (
    <div className={classNames('input-container', className)} onClick={wrapperClick} role="button" tabIndex="0" ref={ref}>
      {Input()}
    </div>
  );
});

export default CustomInput;
