// admin signIn
export const ADMIN_SIGN_IN = 'authenticate/ADMIN_SIGN_IN';
export const ADMIN_SIGN_IN_SUCCESS = 'authenticate/ADMIN_SIGN_IN_SUCCESS';
export const ADMIN_SIGN_IN_FAIL = 'authenticate/ADMIN_SIGN_IN_FAIL';
// hunter signIn
export const HUNTER_SIGN_IN = 'authenticate/HUNTER_SIGN_IN';
export const HUNTER_SIGN_IN_SUCCESS = 'authenticate/HUNTER_SIGN_IN_SUCCESS';
export const HUNTER_SIGN_IN_FAIL = 'authenticate/HUNTER_SIGN_IN_FAIL';
// company signIn
export const COMPANY_SIGN_IN = 'authenticate/COMPANY_SIGN_IN';
export const COMPANY_SIGN_IN_SUCCESS = 'authenticate/COMPANY_SIGN_IN_SUCCESS';
export const COMPANY_SIGN_IN_FAIL = 'authenticate/COMPANY_SIGN_IN_FAIL';

// hunter signUp
export const HUNTER_SIGN_UP = 'authenticate/HUNTER_SIGN_UP';
export const HUNTER_SIGN_UP_SUCCESS = 'authenticate/HUNTER_SIGN_UP_SUCCESS';
export const HUNTER_SIGN_UP_FAIL = 'authenticate/HUNTER_SIGN_UP_FAIL';
// company signUp
export const COMPANY_SIGN_UP = 'authenticate/COMPANY_SIGN_UP';
export const COMPANY_SIGN_UP_SUCCESS = 'authenticate/COMPANY_SIGN_UP_SUCCESS';
export const COMPANY_SIGN_UP_FAIL = 'authenticate/COMPANY_SIGN_UP_FAIL';
// logOut
export const LOG_OUT = 'authenticate/LOG_OUT';
export const LOG_OUT_SUCCESS = 'authenticate/LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'authenticate/LOG_OUT_FAIL';
