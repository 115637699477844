import * as types from './types';

// updateCompany
export const updateCompany = (payload) => ({
  type: types.UPDATE_COMPANY,
  payload,
});
export const updateCompanySuccess = (payload) => ({
  type: types.UPDATE_COMPANY_SUCCESS,
  payload,
});
export const updateCompanyFail = (payload) => ({
  type: types.UPDATE_COMPANY_FAIL,
  payload,
});

// submitCompany
export const submitCompany = (payload) => ({
  type: types.SUBMIT_COMPANY,
  payload,
});
export const submitCompanySuccess = (payload) => ({
  type: types.SUBMIT_COMPANY_SUCCESS,
  payload,
});
export const submitCompanyFail = (payload) => ({
  type: types.SUBMIT_COMPANY_FAIL,
  payload,
});

// getCompany
export const getCompany = (payload) => ({
  type: types.GET_COMPANY,
  payload,
});
export const getCompanySuccess = (payload) => ({
  type: types.GET_COMPANY_SUCCESS,
  payload,
});
export const getCompanyFail = (payload) => ({
  type: types.GET_COMPANY_FAIL,
  payload,
});

// getCompanyMap
export const getCompanyMap = (payload) => ({
  type: types.GET_COMPANY_MAP,
  payload,
});
export const getCompanyMapSuccess = (payload) => ({
  type: types.GET_COMPANY_MAP_SUCCESS,
  payload,
});
export const getCompanyMapFail = (payload) => ({
  type: types.GET_COMPANY_MAP_FAIL,
  payload,
});

// updateCompanyMap
export const updateCompanyMap = (payload) => ({
  type: types.UPDATE_COMPANY_MAP,
  payload,
});
export const updateCompanyMapSuccess = (payload) => ({
  type: types.UPDATE_COMPANY_MAP_SUCCESS,
  payload,
});
export const updateCompanyMapFail = (payload) => ({
  type: types.UPDATE_COMPANY_MAP_FAIL,
  payload,
});
// getCompanyList
export const getCompanyList = (payload) => ({
  type: types.GET_COMPANY_LIST,
  payload,
});
export const getCompanyListSuccess = (payload) => ({
  type: types.GET_COMPANY_LIST_SUCCESS,
  payload,
});
export const getCompanyFailList = (payload) => ({
  type: types.GET_COMPANY_LIST_FAIL,
  payload,
});

// getCompanyUnViewedCounts
export const getCompanyUnViewedCounts = (payload) => ({
  type: types.GET_COMPANY_UN_VIEWED_COUNTS,
  payload,
});
export const getCompanyUnViewedCountsSuccess = (payload) => ({
  type: types.GET_COMPANY_UN_VIEWED_COUNTS_SUCCESS,
  payload,
});
export const getCompanyUnViewedCountsFail = (payload) => ({
  type: types.GET_COMPANY_UN_VIEWED_COUNTS_FAIL,
  payload,
});

// other
export const resetSelectedCompany = (payload) => ({
  type: types.RESET_SELECTED_COMPANY,
  payload,
});
