import { DEFAULT_PAGINATION_PARAMS } from 'constants/index';

export const initialStore = {
  app: {
    loading: false,
    toast: null,
    config: {
      areaType: [],
      gameType: [],
      diaryGameType: [],
      huntType: [],
      measureUnits: [],
      POITType: [],
    },
  },
  auth: {
    isFetching: false,
    currentUser: null,
    error: '',
    hunterSignUpSuccess: false,
    companySignUpSuccess: false,
  },
  hunter: {
    isFetching: false,
    companyList: [],
  },
  company: {
    isFetching: false,
    companyList: [],
    count: 0,
    selectedCompany: null,
    unViewedCounts: {
      firstCount: 0,
      secondCount: 0,
    },
  },
  member: {
    member: null,
    isFetching: false,
    selectedMember: null,
    members: {
      rows: [],
      count: 0,
      loading: false,
      page: 1,
    },
  },
  restorePassword: {},
  admin: {
    adminWasChanged: false,
    admin: null,
    isFetching: false,
    admins: {
      rows: [],
      count: 0,
      loading: false,
      page: 1,
    },
  },
  companyUsers: {
    isFetching: false,
    usersList: [],
    count: 0,
    selectedUser: null,
  },
  extra: {
    isFetching: false,
    extrasList: [],
    count: 0,
    selectedExtra: null,
  },
  package: {
    isFetching: false,
    packageList: [],
    count: 0,
    selectedPackage: null,
  },
  priceList: {
    isFetching: false,
    priceList: [],
    count: 0,
    interval: null,
    selectedPriceList: {
      gameType: '',
      measureUnit: '',
      intervals: [],
    },
  },
  huntAdRequest: {
    selectedHuntRequestAdByAdmin: null,
    selectedHuntAd: null,
    isFetching: false,
    error: null,
    count: 0,
    requestList: [],
    fetchHuntAdRequestParams: {
      ...DEFAULT_PAGINATION_PARAMS,
    },
    preview: null,
    offerByRequestId: null,
    unViewedCounts: {
      firstCount: 0,
      secondCount: 0,
    },
  },
  huntAdOffer: {
    currentHuntAdOffer: null,
  },
  orders: {
    orders: [],
    sortedByStatuses: [],
    count: 0,
    unViewedCount: 0,
    isFetching: false,
    order: null,
  },
  hunterDiary: {
    isFetching: false,
    count: 0,
    diaryList: [],
    selectedDiary: null,
  },
  markedPlace: {
    isFetching: false,
    count: 0,
    rows: [],
    selectedMarkedPlace: null,
  },
  messages: {
    isFetching: false,
    messages: [],
    unViewed: null,
    count: 0,
    selectedMessage: null,
    recipientInfo: null,
    isFetchingRecipientInfo: false,
  },
};
