/* eslint-disable no-unused-vars */
import { call, takeEvery, put } from 'redux-saga/effects';

import { setTokenToCookie } from 'utils/cookie-actions';
import { setToast } from '../app/actions';
import * as types from './types';
import * as actions from './actions';
import { authenticateApi } from './api';

function* adminSignIn({ payload }) {
  try {
    const encodedUserData = yield window.btoa(`${payload.email}:${payload.password}`);
    const response = yield call(authenticateApi.adminSignInRequest, encodedUserData);

    yield setTokenToCookie(response.data.accessToken);
    yield put(actions.adminSignInSuccess({ user: response.data.user }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(actions.adminSignInFail({ message }));
  }
}

function* hunterSignIn({ payload }) {
  try {
    const encodedUserData = yield window.btoa(`${payload.email}:${payload.password}`);
    const response = yield call(authenticateApi.hunterSignInRequest, encodedUserData);

    yield setTokenToCookie(response.data.accessToken);
    yield put(actions.hunterSignInSuccess({ user: response.data.user }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(actions.hunterSignInFail({ message }));
  }
}

function* hunterSignUp({ payload }) {
  try {
    const response = yield call(authenticateApi.hunterSignUpRequest, payload);
    yield put(actions.hunterSignUpSuccess(response));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(actions.hunterSignUpFail(message));
  }
}

function* companySignIn({ payload }) {
  try {
    const encodedUserData = yield window.btoa(`${payload.email}:${payload.password}`);
    const response = yield call(authenticateApi.companySignInRequest, encodedUserData);

    yield setTokenToCookie(response.data.accessToken);
    yield put(actions.companySignInSuccess({ user: response.data.user }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(actions.companySignInFail({ message }));
  }
}

function* companySignUp({ payload }) {
  try {
    const response = yield call(authenticateApi.companySignUpRequest, payload);
    yield put(actions.companySignUpSuccess(response));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(actions.companySignUpFail(message));
  }
}

function* logOut() {
  try {
    const response = yield call(authenticateApi.logOutRequest);

    yield put(actions.logOutSuccess(response));
  } catch (error) {
    yield put(actions.logOutFail(error));
  }
}

function* authenticate() {
  yield takeEvery(types.ADMIN_SIGN_IN, adminSignIn);
  yield takeEvery(types.HUNTER_SIGN_IN, hunterSignIn);
  yield takeEvery(types.HUNTER_SIGN_UP, hunterSignUp);
  yield takeEvery(types.COMPANY_SIGN_IN, companySignIn);
  yield takeEvery(types.COMPANY_SIGN_UP, companySignUp);
  yield takeEvery(types.LOG_OUT, logOut);
}

export default authenticate;
