import * as types from './types';

// getAllMessages
export const getAllCompanyMessages = (payload) => ({
  type: types.GET_ALL_COMPANY_MESSAGES,
  payload,
});
export const getAllCompanyMessagesSuccess = (payload) => ({
  type: types.GET_ALL_COMPANY_MESSAGES_SUCCESS,
  payload,
});
export const getAllCompanyMessagesFail = (payload) => ({
  type: types.GET_ALL_COMPANY_MESSAGES_FAIL,
  payload,
});

// getAllMessages
export const getAllHunterMessages = (payload) => ({
  type: types.GET_ALL_HUNTER_MESSAGES,
  payload,
});
export const getAllHunterMessagesSuccess = (payload) => ({
  type: types.GET_ALL_HUNTER_MESSAGES_SUCCESS,
  payload,
});
export const getAllHunterMessagesFail = (payload) => ({
  type: types.GET_ALL_HUNTER_MESSAGES_FAIL,
  payload,
});

// getMessageById
export const getMessageById = (payload) => ({
  type: types.GET_MESSAGE_BY_ID,
  payload,
});
export const getMessageByIdSuccess = (payload) => ({
  type: types.GET_MESSAGE_BY_ID_SUCCESS,
  payload,
});
export const getMessageByIdFail = (payload) => ({
  type: types.GET_MESSAGE_BY_ID_FAIL,
  payload,
});

// createMessage
export const createMessage = (payload) => ({
  type: types.CREATE_MESSAGE,
  payload,
});
export const createMessageSuccess = (payload) => ({
  type: types.CREATE_MESSAGE_SUCCESS,
  payload,
});
export const createMessageFail = (payload) => ({
  type: types.CREATE_MESSAGE_FAIL,
  payload,
});

// getAllMessagesForCompany
export const getAllMessagesForCompany = (payload) => ({
  type: types.GET_ALL_MESSAGES_FOR_COMPANY,
  payload,
});
export const getAllMessagesForCompanySuccess = (payload) => ({
  type: types.GET_ALL_MESSAGES_FOR_COMPANY_SUCCESS,
  payload,
});
export const getAllMessagesForCompanyFail = (payload) => ({
  type: types.GET_ALL_MESSAGES_FOR_COMPANY_FAIL,
  payload,
});

// getRecipientInfo
export const getRecipientInfo = (payload) => ({
  type: types.GET_RECIPIENT_INFO,
  payload,
});
export const getRecipientInfoSuccess = (payload) => ({
  type: types.GET_RECIPIENT_INFO_SUCCESS,
  payload,
});
export const getRecipientInfoFail = (payload) => ({
  type: types.GET_RECIPIENT_INFO_FAIL,
  payload,
});

// viewByCompanyUser
export const viewByCompanyUser = (payload) => ({
  type: types.VIEW_BY_COMPANY_USER,
  payload,
});

// viewByHunter
export const viewByHunter = (payload) => ({
  type: types.VIEW_BY_HUNTER,
  payload,
});
