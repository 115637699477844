import { useEffect, useState } from 'react';

export const useResizeParams = () => {
  const [params, setParams] = useState({
    width: process.browser
      ? window.innerWidth
      : null,
    height: process.browser
      ? window.innerHeight
      : null,
  });

  const resizeCallback = () => setParams({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener('resize', resizeCallback);
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  return params;
};
