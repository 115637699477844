import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const huntAdRequestApi = {
  getHuntAdRequestById({ companyId, huntAdId }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/${huntAdId}` });
  },
  createHuntAdRequest({ companyId, data }) {
    return http({ url: API_URLS.getHuntAdRequest(companyId), method: 'POST', data });
  },
  createHuntAdRequestDraft({ companyId, data }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/draft`, method: 'POST', data });
  },
  uploadHuntAdImageRequest() {
    return () => new Promise((resolve) => setTimeout(() => resolve([]), 1000));
    // return http({ url: API_URLS.getHuntAdUploadImageRequests(companyId), method: 'POST' });
  },
  viewHuntAdRequest({ id, companyId }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/${id}` });
  },
  updateHuntAdRequest({ companyId, huntAdId, data }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/${huntAdId}`, method: 'PATCH', data });
  },
  updateHuntAdRequestDraft({ companyId, huntAdId, data }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/${huntAdId}/draft`, method: 'PATCH', data });
  },
  getHuntAdRequestList({ companyId, params }) {
    return http({ url: API_URLS.getHuntAdRequest(companyId), params });
  },
  getHuntAdRequestListByAdmin(params) {
    return http({ url: API_URLS.huntAdRequest, params });
  },
  getHuntAdRequestByAdminRequest(id) {
    return http({ url: `${API_URLS.huntAdRequest}/${id}` });
  },
  getHuntAdPreview({ companyId, data }) {
    return http({ url: `${API_URLS.getHuntAdRequest(companyId)}/`, method: 'POST', data });
  },
};
