import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from './types';
import { restorePasswordApi } from './api';
import { setToast } from '../app/actions';

function* restorePasswordHunter({ payload }) {
  try {
    const response = yield call(restorePasswordApi.restorePasswordHunterRequest, payload);
    const message = response && response.data ? response.data.message : '';
    yield put(setToast({
      type: 'success',
      text: message.split('#')[0],
      options: {},
    }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* restorePasswordAdmin({ payload }) {
  try {
    const response = yield call(restorePasswordApi.restorePasswordAdminRequest, payload);
    const message = response && response.data ? response.data.message : '';
    yield put(setToast({
      type: 'success',
      text: message.split('#')[0],
      options: {},
    }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* restorePasswordUser({ payload }) {
  try {
    const response = yield call(restorePasswordApi.restorePasswordUserRequest, payload);
    const message = response && response.data ? response.data.message : '';
    yield put(setToast({
      type: 'success',
      text: message.split('#')[0],
      options: {},
    }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* restorePassword() {
  yield takeLatest(types.RESTORE_PASSWORD_HUNTER, restorePasswordHunter);
  yield takeLatest(types.RESTORE_PASSWORD_ADMIN, restorePasswordAdmin);
  yield takeLatest(types.RESTORE_PASSWORD_USER, restorePasswordUser);
}

export default restorePassword;
