// rejectHuntAdOffer
export const REJECT_HUNT_AD_OFFER = 'huntAdRequestActions/REJECT_HUNT_AD_OFFER';

// cancelHuntAdOffer
export const CANCEL_HUNT_AD_OFFER = 'huntAdRequestActions/CANCEL_HUNT_AD_OFFER';

// confirmHuntAdOffer
export const CONFIRM_HUNT_AD_OFFER = 'huntAdRequestActions/CONFIRM_HUNT_AD_OFFER';

// setViewedRequest
export const SET_VIEWED_REQUEST = 'huntAdRequestActions/SET_VIEWED_REQUEST';
export const SET_VIEWED_REQUEST_SUCCESS = 'huntAdRequestActions/SET_VIEWED_REQUEST_SUCCESS';
export const SET_VIEWED_REQUEST_FAIL = 'huntAdRequestActions/SET_VIEWED_REQUEST_FAIL';

// getOfferByRequestId
export const GET_OFFER_BY_REQUEST_ID = 'huntAdRequestActions/GET_OFFER_BY_REQUEST_ID';
export const GET_OFFER_BY_REQUEST_ID_SUCCESS = 'huntAdRequestActions/GET_OFFER_BY_REQUEST_ID_SUCCESS';
export const GET_OFFER_BY_REQUEST_ID_FAIL = 'huntAdRequestActions/GET_OFFER_BY_REQUEST_ID_FAIL';

// getDraftPreview
export const GET_DRAFT_PREVIEW = 'huntAdRequestActions/GET_DRAFT_PREVIEW';
export const GET_DRAFT_PREVIEW_SUCCESS = 'huntAdRequestActions/GET_DRAFT_PREVIEW_SUCCESS';
export const GET_DRAFT_PREVIEW_FAIL = 'huntAdRequestActions/GET_DRAFT_PREVIEW_FAIL';

// getUnViewedCounts
export const GET_UN_VIEWED_COUNTS = 'huntAdRequestActions/GET_UN_VIEWED_COUNTS';
export const GET_UN_VIEWED_COUNTS_SUCCESS = 'huntAdRequestActions/GET_UN_VIEWED_COUNTS_SUCCESS';
export const GET_UN_VIEWED_COUNTS_FAIL = 'huntAdRequestActions/GET_UN_VIEWED_COUNTS_FAIL';
