// updateHunter
export const UPDATE_HUNTER = 'hunter/UPDATE_HUNTER';
export const UPDATE_HUNTER_SUCCESS = 'hunter/UPDATE_HUNTER_SUCCESS';
export const UPDATE_HUNTER_FAIL = 'hunter/UPDATE_HUNTER_FAIL';

// get company list
export const GET_COMPANY_LIST = 'hunter/GET_COMPANY_LIST';
export const GET_COMPANY_LIST_SUCCESS = 'hunter/GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAIL = 'hunter/GET_COMPANY_LIST_FAIL';

// updateCompletedHunter
export const UPDATE_COMPLETED_HUNTER = 'hunter/UPDATE_COMPLETED_HUNTER';
export const UPDATE_COMPLETED_HUNTER_SUCCESS = 'hunter/UPDATE_COMPLETED_HUNTER_SUCCESS';
export const UPDATE_COMPLETED_HUNTER_FAIL = 'hunter/UPDATE_COMPLETED_HUNTER_FAIL';
