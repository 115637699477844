import { getHuntAdRequestImage, getTemporaryImage } from 'utils/get-images';

import * as types from './types';
import {
  GET_OFFER_BY_REQUEST_ID,
  GET_OFFER_BY_REQUEST_ID_FAIL,
  GET_OFFER_BY_REQUEST_ID_SUCCESS,
  GET_DRAFT_PREVIEW,
  GET_DRAFT_PREVIEW_FAIL,
  GET_DRAFT_PREVIEW_SUCCESS,
  GET_UN_VIEWED_COUNTS_SUCCESS,
} from '../hunt-ad-request-actions/types';

import { initialStore } from '../initial-store';

export const huntAdRequestReducer = (state = initialStore.huntAdRequest, { type, payload }) => {
  switch (type) {
    case types.GET_HUNT_AD_PREVIEW:
    case GET_OFFER_BY_REQUEST_ID:
    case GET_DRAFT_PREVIEW:
    case types.GET_HUNT_AD_REQUEST_BY_ADMIN:
    case types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN:
    case types.GET_HUNT_AD_REQUEST_BY_ID:
    case types.GET_HUNT_AD_REQUEST_LIST: {
      return { ...state, isFetching: true };
    }

    case types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_SUCCESS:
    case types.GET_HUNT_AD_REQUEST_LIST_SUCCESS: {
      const { rows, count } = payload;
      return {
        ...state, isFetching: false, count, requestList: rows,
      };
    }

    case types.GET_HUNT_AD_REQUEST_BY_ID_FAIL:
    case types.GET_HUNT_AD_REQUEST_BY_ADMIN_FAIL:
    case types.GET_HUNT_AD_PREVIEW_FAIL:
    case types.GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_FAIL:
    case types.GET_HUNT_AD_REQUEST_LIST_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_HUNT_AD_REQUEST_BY_ADMIN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        selectedHuntRequestAdByAdmin: {
          ...payload,
          logs: payload.logs
            .map((el, i) => ((i === payload.logs.length - 1) ? ({ ...el, last: true }) : ({ ...el, last: false })))
            .reverse(),
        },
      };
    }

    case types.UPDATE_HUNT_AD_REQUEST_DRAFT_SUCCESS:
    case types.CREATE_HUNT_AD_DRAFT_SUCCESS:
    case types.GET_HUNT_AD_REQUEST_BY_ID_SUCCESS: {
      return { ...state, isFetching: false, selectedHuntAd: payload };
    }

    case types.GET_HUNT_AD_PREVIEW_SUCCESS: {
      const { data } = payload;
      return { ...state, isFetching: false, preview: data };
    }

    case types.UPDATE_HUNT_AD_REQUEST_DRAFT:
    case types.UPDATE_HUNT_AD_REQUEST_DRAFT_FAIL:
    case types.CREATE_HUNT_AD_DRAFT:
    case types.CREATE_HUNT_AD_DRAFT_FAIL:
    case types.CREATE_HUNT_AD_REQUEST:
    case types.CREATE_HUNT_AD_REQUEST_SUCCESS:
    case types.CREATE_HUNT_AD_REQUEST_FAIL:
    case types.UPDATE_HUNT_AD_REQUEST:
    case types.UPDATE_HUNT_AD_REQUEST_SUCCESS:
    case types.UPDATE_HUNT_AD_REQUEST_FAIL: {
      return { ...state };
    }

    case types.CHANGE_FETCH_PARAMS: {
      return { ...state, fetchHuntAdRequestParams: { ...state.fetchHuntAdRequestParams, ...payload } };
    }

    case GET_OFFER_BY_REQUEST_ID_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        offerByRequestId: {
          ...payload,
          huntAdContent: {
            ...payload.huntAdContent,
            images: payload.huntAdContent.images.map((el) => ({
              original: getHuntAdRequestImage(payload.huntAdRequest, el),
              thumbnail: getHuntAdRequestImage(payload.huntAdRequest, el),
            })),
          },
        },
      };
    }

    case GET_DRAFT_PREVIEW_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        offerByRequestId: {
          ...payload,
          huntAdContent: {
            ...payload.huntAdContent,
            images: payload.huntAdContent.images.map((el) => ({
              original: getTemporaryImage(payload.company.id, el),
              thumbnail: getTemporaryImage(payload.company.id, el),
            })),
          },
        },
      };
    }

    case GET_UN_VIEWED_COUNTS_SUCCESS: {
      return { ...state, unViewedCounts: payload };
    }

    case GET_DRAFT_PREVIEW_FAIL:
    case GET_OFFER_BY_REQUEST_ID_FAIL: {
      return { ...state, isFetching: false, error: payload };
    }

    case types.RESET_SELECTED_HUNT_AD: {
      return { ...state, selectedHuntAd: initialStore.huntAdRequest.selectedHuntAd };
    }

    default:
      return state;
  }
};
