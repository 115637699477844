import * as types from './types';

// rejectHuntAdOffer
export const rejectHuntAdOffer = (payload) => ({
  type: types.REJECT_HUNT_AD_OFFER,
  payload,
});

// cancelHuntAdOffer
export const cancelHuntAdOffer = (payload) => ({
  type: types.CANCEL_HUNT_AD_OFFER,
  payload,
});

// confirmHuntAdOffer
export const confirmHuntAdOffer = (payload) => ({
  type: types.CONFIRM_HUNT_AD_OFFER,
  payload,
});

// setViewedRequest
export const setViewedRequest = (payload) => ({
  type: types.SET_VIEWED_REQUEST,
  payload,
});
export const setViewedRequestSuccess = (payload) => ({
  type: types.SET_VIEWED_REQUEST_SUCCESS,
  payload,
});
export const setViewedRequestFail = (payload) => ({
  type: types.SET_VIEWED_REQUEST_FAIL,
  payload,
});

// getOfferByRequestId
export const getOfferByRequestId = (payload) => ({
  type: types.GET_OFFER_BY_REQUEST_ID,
  payload,
});
export const getOfferByRequestIdSuccess = (payload) => ({
  type: types.GET_OFFER_BY_REQUEST_ID_SUCCESS,
  payload,
});
export const getOfferByRequestIdFail = (payload) => ({
  type: types.GET_OFFER_BY_REQUEST_ID_FAIL,
  payload,
});

// getDraftPreview
export const getDraftPreview = (payload) => ({
  type: types.GET_DRAFT_PREVIEW,
  payload,
});
export const getDraftPreviewSuccess = (payload) => ({
  type: types.GET_DRAFT_PREVIEW_SUCCESS,
  payload,
});
export const getDraftPreviewFail = (payload) => ({
  type: types.GET_DRAFT_PREVIEW_FAIL,
  payload,
});

// getUnViewedCounts
export const getUnViewedCounts = (payload) => ({
  type: types.GET_UN_VIEWED_COUNTS,
  payload,
});
export const getUnViewedCountsSuccess = (payload) => ({
  type: types.GET_UN_VIEWED_COUNTS_SUCCESS,
  payload,
});
export const getUnViewedCountsFail = (payload) => ({
  type: types.GET_UN_VIEWED_COUNTS_FAIL,
  payload,
});
