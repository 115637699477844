import {
  call, takeLatest, put, delay, select,
} from 'redux-saga/effects';

import { URLS } from 'constants/index';
import { API_URLS } from 'constants/api-endpoints';
import { USER_PREFIX } from 'constants/roles';
import * as types from './types';
import * as actions from './actions';
import { setToast } from '../app/actions';
import { huntAdRequestActionsApi } from './api';

function* rejectHuntAdOffer({ payload }) {
  const { data, history } = payload;
  try {
    const huntAdRequest = yield select((s) => s.huntAdRequest.selectedHuntRequestAdByAdmin);
    yield call(huntAdRequestActionsApi.rejectHuntAdOfferRequest, { id: huntAdRequest.id, data });

    if (history) {
      yield delay(500);
      yield history.push(URLS.adminHuntAdRequestManagement);
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* cancelHuntAdOffer({ payload }) {
  const { data, history } = payload;
  try {
    const huntAdRequest = yield select((s) => s.huntAdRequest.selectedHuntRequestAdByAdmin);
    yield call(huntAdRequestActionsApi.cancelHuntAdOfferRequest, { id: huntAdRequest.id, data });

    if (history) {
      yield delay(500);
      yield history.push(URLS.adminHuntAdRequestManagement);
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* confirmHuntAdOffer({ payload }) {
  const { history } = payload;
  try {
    const huntAdRequest = yield select((s) => s.huntAdRequest.selectedHuntRequestAdByAdmin);
    yield call(huntAdRequestActionsApi.confirmHuntAdOfferRequest, huntAdRequest.id);

    if (history) {
      yield delay(500);
      yield history.push(URLS.adminHuntAdRequestManagement);
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* setViewedRequest({ payload }) {
  try {
    yield call(huntAdRequestActionsApi.setViewedRequest, payload.id);
    yield put(actions.setViewedRequestSuccess(payload));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(actions.setViewedRequestFail(message));
  }
}

function* getUnViewedCounts({ payload }) {
  try {
    const response = yield call(huntAdRequestActionsApi.getUnViewedCountsRequest, payload);
    yield put(actions.getUnViewedCountsSuccess(response.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(actions.getUnViewedCountsFail(message));
  }
}

function* getDraftPreview({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, id: payload.id };
    const response = yield call(huntAdRequestActionsApi.getDraftPreviewRequest, data);
    yield put(actions.getDraftPreviewSuccess(response.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(actions.getDraftPreviewFail(message));
  }
}

function* getOfferByRequestId({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const url = currentUser.id.includes(USER_PREFIX)
      ? `${API_URLS.getHuntAdRequest(currentUser.company.id)}/${payload.id}/offer`
      : `${API_URLS.huntAdRequest}/${payload.id}/offer`;

    const result = yield call(huntAdRequestActionsApi.getOfferByRequestIdRequest, url);
    yield put(actions.getOfferByRequestIdSuccess(result.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(actions.getOfferByRequestIdFail(typeof message === 'string' ? message : message.toString()));
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* huntAdRequestActionsSaga() {
  yield takeLatest(types.REJECT_HUNT_AD_OFFER, rejectHuntAdOffer);
  yield takeLatest(types.CANCEL_HUNT_AD_OFFER, cancelHuntAdOffer);
  yield takeLatest(types.CONFIRM_HUNT_AD_OFFER, confirmHuntAdOffer);
  yield takeLatest(types.GET_OFFER_BY_REQUEST_ID, getOfferByRequestId);
  yield takeLatest(types.SET_VIEWED_REQUEST, setViewedRequest);
  yield takeLatest(types.GET_DRAFT_PREVIEW, getDraftPreview);
  yield takeLatest(types.GET_UN_VIEWED_COUNTS, getUnViewedCounts);
}

export default huntAdRequestActionsSaga;
