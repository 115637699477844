import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import { URLS } from 'constants/index';
import * as types from './types';
import * as actions from './actions';
import { markedPlaceApi } from './api';

function* getAllMarkedPlaces({ payload }) {
  try {
    const response = yield call(markedPlaceApi.getAllMarkedPlacesRequest, payload);

    yield put(actions.getAllMarkedPlacesSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllMarkedPlacesFail(error));
  }
}

function* getMarkedPlaceById({ payload }) {
  try {
    const response = yield call(markedPlaceApi.getMarkedPlaceByIdRequest, payload);

    yield put(actions.getMarkedPlaceByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getMarkedPlaceByIdFail(error));
  }
}

function* createMarkedPlace({ payload }) {
  const { history, data } = payload;
  try {
    const response = yield call(markedPlaceApi.createMarkedPlaceRequest, data);

    yield put(actions.createMarkedPlaceSuccess(response));
    if (history) history.push(URLS.hunterMarkedPlacesList);
  } catch (error) {
    yield put(actions.createMarkedPlaceFail(error));
  }
}

function* updateMarkedPlace({ payload }) {
  const { data, cb } = payload;
  try {
    const selectedMarkedPlace = yield select((s) => s.markedPlace.selectedMarkedPlace);
    const response = yield call(markedPlaceApi.updateMarkedPlaceRequest, { data, id: selectedMarkedPlace.id });

    yield put(actions.updateMarkedPlaceSuccess(response));
    if (cb && typeof cb === 'function') cb(selectedMarkedPlace.id);
  } catch (error) {
    yield put(actions.updateMarkedPlaceFail(error));
  }
}

function* removeMarkedPlace({ payload }) {
  try {
    const response = yield call(markedPlaceApi.removeMarkedPlaceRequest, payload);

    yield put(actions.removeMarkedPlaceSuccess(response));
    yield put(actions.getAllMarkedPlaces());
  } catch (error) {
    yield put(actions.removeMarkedPlaceFail(error));
  }
}

function* shareMarkedPlace({ payload }) {
  const { share } = payload;
  try {
    const selectedMarkedPlace = yield select((s) => s.markedPlace.selectedMarkedPlace);
    const data = { id: selectedMarkedPlace.id, share };
    yield call(markedPlaceApi.shareMarkedPlaceRequest, data);
    yield put(actions.shareMarkedPlaceSuccess({ share }));
  } catch (error) {
    yield put(actions.shareMarkedPlaceFail());
  }
}

function* markedPlace() {
  yield takeLatest(types.GET_ALL_MARKEDPLACES, getAllMarkedPlaces);
  yield takeLatest(types.GET_MARKEDPLACE_BY_ID, getMarkedPlaceById);
  yield takeLatest(types.CREATE_MARKEDPLACE, createMarkedPlace);
  yield takeLatest(types.UPDATE_MARKEDPLACE, updateMarkedPlace);
  yield takeLatest(types.REMOVE_MARKEDPLACE, removeMarkedPlace);
  yield takeLatest(types.SHARE_MARKED_PLACE, shareMarkedPlace);
}

export default markedPlace;
