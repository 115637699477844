const Svg = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 77 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8262 3.588C24.7297 8.54443 27.744 15.32 27.744 22.7934C27.744 24.5122 27.5842 26.1946 27.2784 27.8268C25.246 23.285 21.2614 19.6633 16.0825 18.275L14.763 23.2017C21.0844 24.8963 24.8716 31.33 23.3288 37.6583C18.3939 45.2282 9.79807 50.2484 0 50.2484V55.3489C18.1141 55.3489 32.8421 40.7973 32.8421 22.7934C32.8421 13.9155 29.2541 5.86723 23.4496 0L19.8262 3.588Z" fill="#2C3831" />
    <path d="M43.7201 32.397L45.2651 33.9426C48.1093 36.7882 48.1093 41.4016 45.2651 44.2471L44.4644 45.0482C62.4962 47.2259 76.4696 62.5882 76.4696 81.2157C76.4696 85.4752 75.739 89.564 74.3962 93.3634C65.7458 77.1199 42.2395 69.7488 26.9407 76.1263C22.5709 77.9479 15.0273 80.9746 10.761 76.7063L5.09619 71.0389L43.7201 32.397Z" fill="#BF8F60" />
    <path d="M34.5485 74.1361C54.6781 85.0752 45.5601 111.901 26.6504 115.101C30.7989 116.745 35.3211 117.649 40.0543 117.649C55.9082 117.649 69.3959 107.513 74.3959 93.3645C67.0844 79.6352 49.1598 72.2443 34.5485 74.1361Z" fill="#F3ECE0" />
    <path d="M44.3305 84.3784C42.9279 84.2143 41.501 84.1299 40.0544 84.1299C27.1862 84.1299 15.8769 90.8077 9.3999 100.889C13.4945 107.261 19.5204 112.274 26.6504 115.1C40.9567 112.679 49.6583 96.7349 44.3305 84.3784Z" fill="#BF8F60" />
  </svg>
);

export default Svg;
