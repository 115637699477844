// getHuntAdRequestById
export const GET_HUNT_AD_REQUEST_BY_ID = 'huntAdRequest/GET_HUNT_AD_REQUEST_BY_ID';
export const GET_HUNT_AD_REQUEST_BY_ID_SUCCESS = 'huntAdRequest/GET_HUNT_AD_REQUEST_BY_ID_SUCCESS';
export const GET_HUNT_AD_REQUEST_BY_ID_FAIL = 'huntAdRequest/GET_HUNT_AD_REQUEST_BY_ID_FAIL';

// getHuntAdRequestByAdmin
export const GET_HUNT_AD_REQUEST_BY_ADMIN = 'huntAdRequest/GET_HUNT_AD_REQUEST_BY_ADMIN';
export const GET_HUNT_AD_REQUEST_BY_ADMIN_SUCCESS = 'huntAdRequest/GET_HUNT_AD_REQUEST_BY_ADMIN_SUCCESS';
export const GET_HUNT_AD_REQUEST_BY_ADMIN_FAIL = 'huntAdRequest/GET_HUNT_AD_REQUEST_BY_ADMIN_FAIL';

// getHuntAdRequestList
export const GET_HUNT_AD_REQUEST_LIST = 'huntAdRequest/GET_HUNT_AD_REQUEST_LIST';
export const GET_HUNT_AD_REQUEST_LIST_SUCCESS = 'huntAdRequest/GET_HUNT_AD_REQUEST_LIST_SUCCESS';
export const GET_HUNT_AD_REQUEST_LIST_FAIL = 'huntAdRequest/GET_HUNT_AD_REQUEST_LIST_FAIL';

// getHuntAdRequestListByAdmin
export const GET_HUNT_AD_REQUEST_LIST_BY_ADMIN = 'huntAdRequest/GET_HUNT_AD_REQUEST_LIST_BY_ADMIN';
export const GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_SUCCESS = 'huntAdRequest/GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_SUCCESS';
export const GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_FAIL = 'huntAdRequest/GET_HUNT_AD_REQUEST_LIST_BY_ADMIN_FAIL';

// createHuntAdRequest
export const CREATE_HUNT_AD_REQUEST = 'huntAdRequest/CREATE_HUNT_AD_REQUEST';
export const CREATE_HUNT_AD_REQUEST_SUCCESS = 'huntAdRequest/CREATE_HUNT_AD_REQUEST_SUCCESS';
export const CREATE_HUNT_AD_REQUEST_FAIL = 'huntAdRequest/CREATE_HUNT_AD_REQUEST_FAIL';

// createHuntAdRequest
export const CREATE_HUNT_AD_DRAFT = 'huntAdRequest/CREATE_HUNT_AD_DRAFT';
export const CREATE_HUNT_AD_DRAFT_SUCCESS = 'huntAdRequest/CREATE_HUNT_AD_DRAFT_SUCCESS';
export const CREATE_HUNT_AD_DRAFT_FAIL = 'huntAdRequest/CREATE_HUNT_AD_DRAFT_FAIL';

// updateHuntAdRequest
export const UPDATE_HUNT_AD_REQUEST = 'huntAdRequest/UPDATE_HUNT_AD_REQUEST';
export const UPDATE_HUNT_AD_REQUEST_SUCCESS = 'huntAdRequest/UPDATE_HUNT_AD_REQUEST_SUCCESS';
export const UPDATE_HUNT_AD_REQUEST_FAIL = 'huntAdRequest/UPDATE_HUNT_AD_REQUEST_FAIL';

// updateHuntAdRequestDraft
export const UPDATE_HUNT_AD_REQUEST_DRAFT = 'huntAdRequest/UPDATE_HUNT_AD_REQUEST_DRAFT';
export const UPDATE_HUNT_AD_REQUEST_DRAFT_SUCCESS = 'huntAdRequest/UPDATE_HUNT_AD_REQUEST_DRAFT_SUCCESS';
export const UPDATE_HUNT_AD_REQUEST_DRAFT_FAIL = 'huntAdRequest/UPDATE_HUNT_AD_REQUEST_DRAFT_FAIL';

// getHuntAdPreview
export const GET_HUNT_AD_PREVIEW = 'huntAdRequest/GET_HUNT_AD_PREVIEW';
export const GET_HUNT_AD_PREVIEW_SUCCESS = 'huntAdRequest/GET_HUNT_AD_PREVIEW_SUCCESS';
export const GET_HUNT_AD_PREVIEW_FAIL = 'huntAdRequest/GET_HUNT_AD_PREVIEW_FAIL';

// other
export const CHANGE_FETCH_PARAMS = 'huntAdRequest/CHANGE_FETCH_PARAMS';

export const RESET_SELECTED_HUNT_AD = 'hundAdRequest/RESET_SELECTED_HUNT_AD';
