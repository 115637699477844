// getAllDiarys
export const GET_ALL_DIARY = 'diary/GET_ALL_DIARYS';
export const GET_ALL_DIARY_SUCCESS = 'diary/GET_ALL_DIARYS_SUCCESS';
export const GET_ALL_DIARY_FAIL = 'diary/GET_ALL_DIARYS_FAIL';
// getDiaryById
export const GET_DIARY_BY_ID = 'diary/GET_DIARY_BY_ID';
export const GET_DIARY_BY_ID_SUCCESS = 'diary/GET_DIARY_BY_ID_SUCCESS';
export const GET_DIARY_BY_ID_FAIL = 'diary/GET_DIARY_BY_ID_FAIL';
// createDiary
export const CREATE_DIARY = 'diary/CREATE_DIARY';
export const CREATE_DIARY_SUCCESS = 'diary/CREATE_DIARY_SUCCESS';
export const CREATE_DIARY_FAIL = 'diary/CREATE_DIARY_FAIL';
// updateDiary
export const UPDATE_DIARY = 'diary/UPDATE_DIARY';
export const UPDATE_DIARY_SUCCESS = 'diary/UPDATE_DIARY_SUCCESS';
export const UPDATE_DIARY_FAIL = 'diary/UPDATE_DIARY_FAIL';
// removeDiary
export const REMOVE_DIARY = 'diary/REMOVE_DIARY';
export const REMOVE_DIARY_SUCCESS = 'diary/REMOVE_DIARY_SUCCESS';
export const REMOVE_DIARY_FAIL = 'diary/REMOVE_DIARY_FAIL';
// shareDiary
export const SHARE_DIARY = 'diary/SHARE_DIARY';
export const SHARE_DIARY_SUCCESS = 'diary/SHARE_DIARY_SUCCESS';
export const SHARE_DIARY_FAIL = 'diary/SHARE_DIARY_FAIL';
// resetSelectedDiary
export const RESET_SELECTED_DIARY = 'hundAdRequest/RESET_SELECTED_DIARY';
