const Svg = ({ fill = '#2C3831' }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1377 7H11.1377V11H7.1377V13H11.1377V17H13.1377V13H17.1377V11H13.1377V7Z"
      fill={fill}
    />
    <path
      d="M12.1377 2C6.6237 2 2.1377 6.486 2.1377 12C2.1377 17.514 6.6237 22 12.1377 22C17.6517 22 22.1377 17.514 22.1377 12C22.1377 6.486 17.6517 2 12.1377 2ZM12.1377 20C7.7267 20 4.1377 16.411 4.1377 12C4.1377 7.589 7.7267 4 12.1377 4C16.5487 4 20.1377 7.589 20.1377 12C20.1377 16.411 16.5487 20 12.1377 20Z"
      fill={fill}
    />
  </svg>
);

export default Svg;
