import * as types from './types';

// getAllExtras
export const getAllExtras = (payload) => ({
  type: types.GET_ALL_EXTRAS,
  payload,
});
export const getAllExtrasSuccess = (payload) => ({
  type: types.GET_ALL_EXTRAS_SUCCESS,
  payload,
});
export const getAllExtrasFail = (payload) => ({
  type: types.GET_ALL_EXTRAS_FAIL,
  payload,
});

// getExtraById
export const getExtraById = (payload) => ({
  type: types.GET_EXTRA_BY_ID,
  payload,
});
export const getExtraByIdSuccess = (payload) => ({
  type: types.GET_EXTRA_BY_ID_SUCCESS,
  payload,
});
export const getExtraByIdFail = (payload) => ({
  type: types.GET_EXTRA_BY_ID_FAIL,
  payload,
});

// createExtra
export const createExtra = (payload) => ({
  type: types.CREATE_EXTRA,
  payload,
});
export const createExtraSuccess = (payload) => ({
  type: types.CREATE_EXTRA_SUCCESS,
  payload,
});
export const createExtraFail = (payload) => ({
  type: types.CREATE_EXTRA_FAIL,
  payload,
});

// updateExtra
export const updateExtra = (payload) => ({
  type: types.UPDATE_EXTRA,
  payload,
});
export const updateExtraSuccess = (payload) => ({
  type: types.UPDATE_EXTRA_SUCCESS,
  payload,
});
export const updateExtraFail = (payload) => ({
  type: types.UPDATE_EXTRA_FAIL,
  payload,
});

// removeExtra
export const removeExtra = (payload) => ({
  type: types.REMOVE_EXTRA,
  payload,
});
export const removeExtraSuccess = (payload) => ({
  type: types.REMOVE_EXTRA_SUCCESS,
  payload,
});
export const removeExtraFail = (payload) => ({
  type: types.REMOVE_EXTRA_FAIL,
  payload,
});
