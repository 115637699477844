import {
  call, takeEvery, put, select, delay,
} from 'redux-saga/effects';

import * as types from './types';
import * as actions from './actions';
import { memberApi } from './api';
import { setToast } from '../app/actions';
import { getMembersFail, getMembersSuccess } from './actions';
import { URLS } from '../../constants';

function* getMembers({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(
      memberApi.getMembersRequest, { companyId: currentUser.company.id, params: payload },
    );

    yield put(getMembersSuccess(response.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(getMembersFail());
  }
}

function* deleteMember({ payload }) {
  try {
    yield call(memberApi.deleteMemberRequest, payload);

    yield put(setToast({
      type: 'success',
      text: 'Member was deleted successfully',
      options: {},
    }));

    yield put(actions.getMembers(payload.companyId, { page: 1 }));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getMemberById({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(
      memberApi.getMemberByIdRequest, { memberId: payload.id, companyId: currentUser.company.id },
    );

    yield put(actions.getMemberByIdSuccess(response));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* createMember({ payload }) {
  try {
    // eslint-disable-next-line no-console
    console.log('saga createMember');
    const currentUser = yield select((s) => s.auth.currentUser);
    yield call(memberApi.createMemberRequest, { data: payload.data, companyId: currentUser.company.id });

    yield put(setToast({
      type: 'success',
      text: 'Member was created successfully',
      options: {},
    }));

    yield delay(500);
    yield payload.history.push(URLS.companyMemberList);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* updateMember({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    yield call(memberApi.updateMemberRequest,
      { data: payload.data, memberId: payload.memberId, companyId: currentUser.company.id });

    yield put(setToast({
      type: 'success',
      text: 'Member was updated successfully',
      options: {},
    }));

    yield delay(500);
    yield payload.history.push(`${URLS.memberView}?id=${payload.memberId}`);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* member() {
  yield takeEvery(types.CREATE_MEMBER, createMember);
  yield takeEvery(types.UPDATE_MEMBER, updateMember);
  yield takeEvery(types.DELETE_MEMBER, deleteMember);
  yield takeEvery(types.GET_MEMBERS, getMembers);
  yield takeEvery(types.GET_MEMBER_BY_ID, getMemberById);
}

export default member;
