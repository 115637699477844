const Svg = ({ width = 16, height = 11 }) => (
  <svg width={width} height={height} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.5C1.5 1.5 5.49813 4.16667 8 9.5C10.5018 4.16667 14.5 1.5 14.5 1.5"
      stroke="#BF8F60"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default Svg;
