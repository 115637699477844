import {
  call, takeEvery, put, select, delay,
} from 'redux-saga/effects';

import { URLS } from 'constants/index';
import { setTokenToCookie } from 'utils/cookie-actions';

import * as types from './types';
import * as actions from './actions';
import { setToast, getMeSuccess } from '../app/actions';
import { companyUserApi } from './api';

function* getAllCompanyUsers({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(companyUserApi.getAllCompanyUsersRequest, currentUser.company.id, payload);

    yield put(actions.getAllCompanyUsersSuccess(response.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(actions.getAllCompanyUsersFail(error));
  }
}

function* getCompanyUserById({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(
      companyUserApi.getCompanyUserByIdRequest,
      currentUser.company.id,
      payload.id,
    );

    yield put(actions.getCompanyUserByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getCompanyUserByIdFail(error));
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* createCompanyUser({ payload }) {
  const { values, history } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, data: values };
    yield call(companyUserApi.createCompanyUserRequest, data);

    if (payload.toast) {
      yield put(setToast({
        type: 'success',
        text: 'User created successfully',
        options: {},
      }));
    }

    if (history) {
      yield delay(500);
      yield history.push(URLS.companyAdmin);
    }

    yield put(actions.createCompanyUserSuccess());
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* updateCompanyUser({ payload }) {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = {
      companyId: currentUser.company.id,
      userId: payload.id,
      data: payload.data,
    };
    const response = yield call(companyUserApi.updateCompanyUserRequest, data);

    if (currentUser.id === payload.id) {
      yield setTokenToCookie(response.data.accessToken);
      yield put(getMeSuccess(response.data.user));
    }

    if (payload.toast) {
      yield put(setToast({
        type: 'success',
        text: 'User updated successfully',
        options: {},
      }));
    }

    if (payload.history) {
      yield delay(500);
      yield payload.history.push(URLS.companyAdmin);
    }
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* removeCompanyUser({ payload }) {
  const { fetchUsersParams, id } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    const data = { companyId: currentUser.company.id, userId: id };
    yield call(companyUserApi.removeCompanyUserRequest, data);

    yield put(setToast({
      type: 'success',
      text: 'User was deleted successfully',
      options: {},
    }));

    yield put(actions.getAllCompanyUsers(fetchUsersParams));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* companyUser() {
  yield takeEvery(types.GET_ALL_COMPANY_USERS, getAllCompanyUsers);
  yield takeEvery(types.GET_COMPANY_USER_BY_ID, getCompanyUserById);
  yield takeEvery(types.CREATE_COMPANY_USER, createCompanyUser);
  yield takeEvery(types.UPDATE_COMPANY_USER, updateCompanyUser);
  yield takeEvery(types.REMOVE_COMPANY_USER, removeCompanyUser);
}

export default companyUser;
