import * as types from './types';
import { initialStore } from '../initial-store';

export const hunterReducer = (state = initialStore.hunter, action) => {
  switch (action.type) {
    case types.UPDATE_HUNTER:
    case types.UPDATE_HUNTER_SUCCESS:
    case types.UPDATE_HUNTER_FAIL: {
      return { ...state };
    }

    case types.GET_COMPANY_LIST: {
      return { ...state, isFetching: true };
    }
    case types.GET_COMPANY_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state, isFetching: false, companyList: data,
      };
    }
    case types.GET_COMPANY_LIST_FAIL: {
      return { ...state, isFetching: false };
    }

    default:
      return state;
  }
};
