import * as types from './types';

// GET ORDERS
export const getAllOrders = (payload) => ({
  type: types.GET_ALL_ORDERS,
  payload,
});
export const getAllOrdersSuccess = (payload) => ({
  type: types.GET_ALL_ORDERS_SUCCESS,
  payload,
});
export const getAllOrdersFail = () => ({
  type: types.GET_ALL_ORDERS_FAIL,
});

// GET ORDER BY ID
export const getOrderById = (payload) => ({
  type: types.GET_ORDER_BY_ID,
  payload,
});
export const getOrderByIdSuccess = (payload) => ({
  type: types.GET_ORDER_BY_ID_SUCCESS,
  payload,
});
export const getOrderByIdFail = () => ({
  type: types.GET_ORDER_BY_ID_FAIL,
});

// GET ORDER BY ID
export const getUnViewedCount = (payload) => ({
  type: types.GET_UN_VIEWED_COUNT_FOR_ORDER,
  payload,
});
export const getUnViewedCountSuccess = (payload) => ({
  type: types.GET_UN_VIEWED_COUNT_FOR_ORDER_SUCCESS,
  payload,
});
export const getUnViewedCountFail = () => ({
  type: types.GET_UN_VIEWED_COUNT_FOR_ORDER_FAIL,
});

// createOrder
export const createOrder = (payload) => ({
  type: types.CREATE_ORDER,
  payload,
});
export const createOrderSuccess = (payload) => ({
  type: types.CREATE_ORDER_SUCCESS,
  payload,
});
export const createOrderFail = () => ({
  type: types.CREATE_ORDER_FAIL,
});

// updateOrder
export const updateOrder = (payload) => ({
  type: types.UPDATE_ORDER,
  payload,
});
export const updateOrderSuccess = (payload) => ({
  type: types.UPDATE_ORDER_SUCCESS,
  payload,
});
export const updateOrderFail = () => ({
  type: types.UPDATE_ORDER_FAIL,
});

// other
export const resetOrder = () => ({
  type: types.RESET_ORDER,
});
