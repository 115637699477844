import {
  call, takeLatest, put, delay,
} from 'redux-saga/effects';

import { URLS } from 'constants/index';
import * as types from './types';
import * as actions from './actions';
import { setToast } from '../app/actions';
import { companyActivationApi } from './api';

function* setPaymentConfirmedStatus({ payload }) {
  try {
    yield call(companyActivationApi.setPaymentConfirmedStatusRequest, payload.id);

    // yield put(setToast({
    //   type: 'success',
    //   text: 'Admin created successfully',
    //   options: {},
    // }));

    yield delay(500);
    yield payload.history.push(URLS.adminFinanceRegistration);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* setWaitingForPaymentStatus({ payload }) {
  try {
    yield call(companyActivationApi.setWaitingForPaymentStatus, payload.id);

    yield delay(500);
    yield payload.history.push(URLS.adminSignUps);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* setMappingMeetingStatus({ payload }) {
  try {
    yield call(companyActivationApi.setMappingMeetingStatus, payload.id);

    yield delay(500);
    yield payload.history.push(URLS.adminSignUps);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* setConfirmedStatus({ payload }) {
  try {
    yield call(companyActivationApi.setConfirmedStatus, payload.id);

    yield delay(500);
    yield payload.history.push(URLS.adminSignUps);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* setContractSignedStatus({ payload }) {
  try {
    yield call(companyActivationApi.setContractSignedStatusRequest, payload.id);

    yield delay(500);
    yield payload.history.push(URLS.adminMapMapSignUps);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* setViewedCompany({ payload }) {
  try {
    yield call(companyActivationApi.setViewedCompanyRequest, payload.id);
    yield put(actions.setViewedCompanySuccess(payload));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';
    yield put(actions.setViewedCompanyFail(message));
  }
}

function* companyActivation() {
  yield takeLatest(types.SET_PAYMENT_CONFIRMED_STATUS, setPaymentConfirmedStatus);
  yield takeLatest(types.SET_CONTRACT_SIGNED_STATUS, setContractSignedStatus);
  yield takeLatest(types.SET_WAITING_FOR_PAYMENT_STATUS, setWaitingForPaymentStatus);
  yield takeLatest(types.SET_MAPPING_MEETING_STATUS, setMappingMeetingStatus);
  yield takeLatest(types.SET_CONFIRMED_STATUS, setConfirmedStatus);
  yield takeLatest(types.SET_VIEWED_COMPANY, setViewedCompany);
}

export default companyActivation;
