/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuIcon, Ellipsis } from 'assets/svg';
import { classNames } from 'utils/classNames';
import { setTokenToCookie, setLocaleToCookie } from 'utils/cookie-actions';
import { IDS } from 'constants/ids';
import { Text } from 'components';
import { DialogModal } from 'components/dialog-modal';

import Avatar from '../../avatar';
import { Links } from '../links';
import { NavigationLink } from './link';

import './style.scss';

const Nav = ({ currentUser, isMobile }) => {
  const { t } = useTranslation('header');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isShowButton, setShowButton] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);

  const links = new Links(currentUser, t);

  const mainMenuRef = useRef(null);
  const navRef = useRef(null);
  const dropdownRef = useRef(null);
  const menuInfo = useRef({ breakWidths: [], numOfItems: 0, totalSpace: 0 });

  const handleClickOutside = (event) => {
    if (dropdownRef.current) {
      if (event.target instanceof HTMLElement && !dropdownRef.current.contains(event.target)) setIsDropDownOpen(false);
    }
    if (mainMenuRef.current) {
      if (event.target instanceof HTMLElement && !mainMenuRef.current.contains(event.target)) setShowMainMenu(false);
    }
  };

  const logOutHandler = () => {
    setTokenToCookie(null);
    setLocaleToCookie(null);

    window.location.href = process.env.REACT_APP_NEXT_APP_URL;
  };

  const checkResize = () => {
    if (!navRef.current || isMobile) return;
    // Get instant state
    const availableSpace = navRef.current.getBoundingClientRect().width;
    const numOfVisibleItems = navRef.current.querySelectorAll('[data-show="true"]').length;

    const { breakWidths } = menuInfo.current;
    const requiredSpace = breakWidths[numOfVisibleItems - 1];

    const showArray = navRef.current.querySelectorAll('[data-show="true"]');

    // There is not enought space
    if (requiredSpace > availableSpace) {
      setShowButton(true);
      const lastElement = showArray[showArray.length - 1];
      lastElement.setAttribute('data-show', false);

      const elem = dropdownRef.current.querySelector(`[id="${lastElement.id}"]`);
      elem.classList.remove('hidden');
      elem.setAttribute('data-show', true);
      checkResize();
      // There is more than enough space
    } else if (availableSpace > breakWidths[numOfVisibleItems]) {
      const visibleItemsInDropDown = dropdownRef.current.querySelectorAll('[data-show="true"]');
      if (visibleItemsInDropDown.length === 1) setShowButton(false);
      const firstElementInDropDown = visibleItemsInDropDown[0];
      firstElementInDropDown.setAttribute('data-show', false);
      firstElementInDropDown.classList.add('hidden');
      navRef.current.querySelector(`[id="${firstElementInDropDown.id}"]`).setAttribute('data-show', true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', checkResize);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', checkResize);
    };
  }, []);

  useEffect(() => {
    checkResize();
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      if (navRef.current) {
        menuInfo.current = [...navRef.current.children].reduce((acc, el, i) => {
          const totalSpace = acc.totalSpace + el.offsetWidth;
          return {
            breakWidths: acc.breakWidths.concat(totalSpace),
            totalSpace,
            numOfItems: i + 1,
          };
        }, { breakWidths: [], numOfItems: 0, totalSpace: 0 });
        checkResize();
      }
    }, 100);
  }, []);

  return isMobile
    ? (
      <>
        <button id={IDS.binocular} className="btn nav__icon">&nbsp;</button>
        <div id={IDS.mobileMenu} className="nav__mobileMenu" />
        <button className="btn nav__icon" onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
          <div className="nav__dropdown-wrapper">
            <MenuIcon />
            <div className={classNames('nav__dropdown', { open: isDropDownOpen })} ref={dropdownRef}>
              {links.getMergedLinks.map((el) => <NavigationLink key={el.id} dropdown={false} {...el} />)}
              <Text className="cursor-pointer" style={{ paddingRight: '10px', paddingTop: '15px' }} onClick={() => setLogOutModal(true)}>{t('exit')}</Text>
            </div>
          </div>
        </button>
        {logOutModal && (
        <DialogModal
          className="modal-dialog-overwrite"
          closeEvent={() => setLogOutModal(false)}
          successAction={logOutHandler}
          denyAction={() => setLogOutModal(false)}
          translations={{
            modalTitle: t('logOut_message'),
            modalAccept: t('access_btn'),
            modalDeny: t('deny_btn'),
          }}
        />
        )}
      </>
    )
    : (
      <>
        <div className="nav__list-gap">
          {' '}
        </div>
        <div ref={navRef} className="nav__list">
          {links.getMergedLinks.map((el) => <NavigationLink key={el.id} dropdown={false} {...el} />)}
        </div>
        <div className="nav__dropdown-wrapper">
          {isShowButton && (
          <div role="button" tabIndex={0} className="nav__dropdown-btn" onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
            <Ellipsis />
          </div>
          )}
          <div className={classNames('nav__dropdown', { open: isDropDownOpen })} ref={dropdownRef}>
            {links.getMergedLinks.map((el) => <NavigationLink key={el.id} dropdown {...el} />)}
          </div>
        </div>
        <div className="avatar-box">
          <Avatar avatarUrl={currentUser.avatarUrl} onClick={() => setShowMainMenu(true)} />
          <div className={classNames('avatar-box__dropdown', { 'avatar-box__menu-open': showMainMenu })} ref={mainMenuRef}>
            <Text className="cursor-pointer" onClick={() => setLogOutModal(true)}>{t('exit')}</Text>
          </div>
        </div>

        {logOutModal && (
        <DialogModal
          className="modal-dialog-overwrite"
          closeEvent={() => setLogOutModal(false)}
          successAction={logOutHandler}
          denyAction={() => setLogOutModal(false)}
          translations={{
            modalTitle: t('logOut_message'),
            modalAccept: t('access_btn'),
            modalDeny: t('deny_btn'),
          }}
        />
        )}
      </>
    );
};

export default Nav;
