import * as types from './types';

// getAllCompanyUsers
export const getAllCompanyUsers = (payload) => ({
  type: types.GET_ALL_COMPANY_USERS,
  payload,
});
export const getAllCompanyUsersSuccess = (payload) => ({
  type: types.GET_ALL_COMPANY_USERS_SUCCESS,
  payload,
});
export const getAllCompanyUsersFail = (payload) => ({
  type: types.GET_ALL_COMPANY_USERS_FAIL,
  payload,
});

// getCompanyUserById
export const getCompanyUserById = (payload) => ({
  type: types.GET_COMPANY_USER_BY_ID,
  payload,
});
export const getCompanyUserByIdSuccess = (payload) => ({
  type: types.GET_COMPANY_USER_BY_ID_SUCCESS,
  payload,
});
export const getCompanyUserByIdFail = (payload) => ({
  type: types.GET_COMPANY_USER_BY_ID_FAIL,
  payload,
});

// createCompanyUser
export const createCompanyUser = (payload) => ({
  type: types.CREATE_COMPANY_USER,
  payload,
});
export const createCompanyUserSuccess = (payload) => ({
  type: types.CREATE_COMPANY_USER_SUCCESS,
  payload,
});
export const createCompanyUserFail = (payload) => ({
  type: types.CREATE_COMPANY_USER_FAIL,
  payload,
});

// updateCompanyUser
export const updateCompanyUser = (payload) => ({
  type: types.UPDATE_COMPANY_USER,
  payload,
});
export const updateCompanyUserSuccess = (payload) => ({
  type: types.UPDATE_COMPANY_USER_SUCCESS,
  payload,
});
export const updateCompanyUserFail = (payload) => ({
  type: types.UPDATE_COMPANY_USER_FAIL,
  payload,
});

// removeCompanyUser
export const removeCompanyUser = (payload) => ({
  type: types.REMOVE_COMPANY_USER,
  payload,
});
export const removeCompanyUserSuccess = (payload) => ({
  type: types.REMOVE_COMPANY_USER_SUCCESS,
  payload,
});
export const removeCompanyUserFail = (payload) => ({
  type: types.REMOVE_COMPANY_USER_FAIL,
  payload,
});

// other
export const resetCompanyUserFields = (payload) => ({
  type: types.RESET_COMPANY_USER_FIELDS,
  payload,
});
