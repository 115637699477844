import * as types from './types';

// InitialApp
export const initialApp = (payload) => ({
  type: types.INITIAL_APP,
  payload,
});
export const initialAppSuccess = (payload) => ({
  type: types.INITIAL_APP_SUCCESS,
  payload,
});
export const initialAppFail = (payload) => ({
  type: types.INITIAL_APP_FAIL,
  payload,
});

// getMe
export const getMe = (payload) => ({
  type: types.GET_ME,
  payload,
});
export const getMeSuccess = (payload) => ({
  type: types.GET_ME_SUCCESS,
  payload,
});
export const getMeFail = (payload) => ({
  type: types.GET_ME_FAIL,
  payload,
});

// getGlobalConfig
export const getGlobalConfig = (payload) => ({
  type: types.GET_GLOBAL_CONFIG,
  payload,
});
export const getGlobalConfigSuccess = (payload) => ({
  type: types.GET_GLOBAL_CONFIG_SUCCESS,
  payload,
});
export const getGlobalConfigFail = (payload) => ({
  type: types.GET_GLOBAL_CONFIG_FAIL,
  payload,
});

// toast
export const setToast = (payload) => ({
  type: types.SET_TOAST,
  payload,
});
