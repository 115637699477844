import * as types from './types';

// getAdminById
export const getAdminById = (payload) => ({
  type: types.GET_ADMIN_BY_ID,
  payload,
});
export const getAdminByIdSuccess = (payload) => ({
  type: types.GET_ADMIN_BY_ID_SUCCESS,
  payload,
});
export const getAdminByIdFail = (payload) => ({
  type: types.GET_ADMIN_BY_ID_FAIL,
  payload,
});

// createAdmin
export const createAdmin = (payload) => ({
  type: types.CREATE_ADMIN,
  payload,
});
export const createAdminSuccess = (payload) => ({
  type: types.CREATE_ADMIN_SUCCESS,
  payload,
});
export const createAdminFail = (payload) => ({
  type: types.CREATE_ADMIN_FAIL,
  payload,
});

// updateAdmin
export const updateAdmin = (payload) => ({
  type: types.UPDATE_ADMIN,
  payload,
});
export const updateAdminSuccess = (payload) => ({
  type: types.UPDATE_ADMIN_SUCCESS,
  payload,
});
export const updateAdminFail = (payload) => ({
  type: types.UPDATE_ADMIN_FAIL,
  payload,
});

// adminList
export const getAdminList = (payload) => ({
  type: types.GET_ADMINS_LIST,
  payload,
});
export const getAdminListSuccess = (payload) => ({
  type: types.GET_ADMINS_LIST_SUCCESS,
  payload,
});
export const getAdminListFail = (payload) => ({
  type: types.GET_ADMINS_LIST_FAIL,
  payload,
});

export const deleteAdmin = (payload) => ({
  type: types.DELETE_ADMIN,
  payload,
});
export const deleteAdminSuccess = (payload) => ({
  type: types.DELETE_ADMIN_SUCCESS,
  payload,
});
export const deleteAdminFail = (payload) => ({
  type: types.DELETE_ADMIN_FAIL,
  payload,
});

// other
export const resetAdminField = (payload) => ({
  type: types.RESET_ADMIN_FIELD,
  payload,
});
