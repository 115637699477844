import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const restorePasswordApi = {
  restorePasswordHunterRequest(data) {
    return http({ url: API_URLS.resetPasswordHunter, method: 'POST', data });
  },
  restorePasswordAdminRequest(data) {
    return http({ url: API_URLS.resetPasswordAdmin, method: 'POST', data });
  },
  restorePasswordUserRequest(data) {
    return http({ url: API_URLS.resetPasswordUser, method: 'POST', data });
  },
};
