import { getLocaleFromCookie } from 'utils/cookie-actions';

/**
 * list of api endpoints
 * for adding new one, you need to create getter for new field in "Map"
 */
class API {
  constructor() {
    this.urls = new Map([
      ['adminSignIn', '/auth/admin/sign-in'],
      ['hunterSignIn', '/auth/hunter/sign-in'],
      ['hunterSignUp', '/auth/hunter/sign-up'],
      ['getMe', '/me'],
      ['companySignIn', '/auth/user/sign-in'],
      ['companySignUp', '/auth/user/sign-up'],
      ['updateCompany', '/company/:id'],
      ['updateCompanyUser', '/company/:id/users/:userId'],
      ['getListOfCompanyUsers', '/company/:id/users'],
      ['createCompanyUser', '/company/:id/users'],
      ['getCompanyUserById', '/company/:id/users/:userId'],
      ['removeCompanyUser', '/company/:id/users/:userId'],
      ['getCompany', '/company/:id'],
      ['getCompanyMap', '/company/:id/map'],
      ['updateCompanyStatusSubmitted', '/company/:companyId/status/submitted'],
      ['companyList', '/company'],
      ['getAvatar', '/upload/avatar'],
      ['updateHunter', '/hunter/:id'],
      ['verifyHunter', '/hunter/:id/verify'],
      ['resetPasswordAdmin', '/reset-password/admin'],
      ['resetPasswordHunter', '/reset-password/hunter'],
      ['resetPasswordUser', '/reset-password/user'],
      ['createAdmin', '/admin'],
      ['getAdminsList', '/admin'],
      ['updateAdmin', '/admin/:id'],
      ['deleteAdmin', '/admin/:id'],
      ['getAdminById', '/admin/:id'],
      ['viewedCompany', '/company/:id/viewed'],
      ['paymentConfirmedStatus', '/company/:id/status/payment_confirmed'],
      ['contractSignedStatus', '/company/:id/status/contract_signed'],
      ['waitingForPaymentStatus', '/company/:id/status/waiting_for_payment'],
      ['mappingMeetingStatus', '/company/:id/status/mapping_meeting'],
      ['confirmedStatus', '/company/:id/status/confirmed'],
      ['getMembers', '/company/:id/member'],
      ['deleteMember', '/company/:id/member/:memberId'],
      ['getMemberById', '/company/:id/member/:memberId'],
      ['membersCSV', '/company/:id/member/csv'],
      ['createMember', '/company/:id/member'],
      ['updateMember', '/company/:id/member/:memberId'],
      ['extra', '/company/:id/extra'],
      ['package', '/company/:id/package'],
      ['priceList', '/company/:id/price'],
      ['getGlobalConfig', '/global-config/:lang'],
      ['uploadHuntAdRequest', '/upload/hunt-ad-request'],
      ['huntAdRequest', 'company/:id/hunt-ad-request'],
      ['huntAdRequestByAdmin', 'hunt-ad-request'],
      ['getHuntAdOfferRequest', '/company/:companyId/hunt-ad-offer/:hundAdOfferId/preview'],
      ['huntOrder', '/hunt-order'],
      ['huntOrderByCompany', '/company/:id/hunt-order'],
      ['getHunterDiary', '/hunter/diary'],
      ['getHunterCompanyList', '/hunter/company'],
      ['getAllCompanyMessages', '/messages/hunter/received'],
      ['getAllHunterMessages', '/messages/hunter'],
      ['getMessageHunter', '/messages/hunter'],
      ['getAllMessagesForCompany', '/messages/company'],
      ['getAllReceivedMessagesForCompany', '/messages/company/received'],
      ['getMarkedPlaces', '/hunter/place'],
      ['uploadDiaryImage', '/upload/hunter/diary'],
      ['uploadMarkedPlaceImage', '/upload/hunter/place'],
      ['orderDocuments', '/upload/order'],
    ]);
  }

  get adminSignIn() {
    return this.urls.get('adminSignIn');
  }

  get hunterSignIn() {
    return this.urls.get('hunterSignIn');
  }

  get hunterSignUp() {
    return this.urls.get('hunterSignUp');
  }

  get getMe() {
    return this.urls.get('getMe');
  }

  get companySignIn() {
    return this.urls.get('companySignIn');
  }

  get companySignUp() {
    return this.urls.get('companySignUp');
  }

  updateCompany(id) {
    return this.urls.get('updateCompany').replace(':id', id);
  }

  updateCompanyUser(id, userId) {
    return this.urls.get('updateCompanyUser').replace(':id', id).replace(':userId', userId);
  }

  updateCompanyStatusSubmitted(companyId) {
    return this.urls.get('updateCompanyStatusSubmitted').replace(':companyId', companyId);
  }

  getCompany(companyId) {
    return this.urls.get('getCompany').replace(':id', companyId);
  }

  getCompanyMap(companyId) {
    return this.urls.get('getCompanyMap').replace(':id', companyId);
  }

  get getCompanyList() {
    return this.urls.get('companyList');
  }

  get getAvatar() {
    return this.urls.get('getAvatar');
  }

  updateHunter(id) {
    return this.urls.get('updateHunter').replace(':id', id);
  }

  verifyHunter(id) {
    return this.urls.get('verifyHunter').replace(':id', id);
  }

  get resetPasswordAdmin() {
    return this.urls.get('resetPasswordAdmin');
  }

  get resetPasswordHunter() {
    return this.urls.get('resetPasswordHunter');
  }

  get resetPasswordUser() {
    return this.urls.get('resetPasswordUser');
  }

  get createAdmin() {
    return this.urls.get('createAdmin');
  }

  get getAdminsList() {
    return this.urls.get('getAdminsList');
  }

  updateAdmin(id) {
    return this.urls.get('updateAdmin').replace(':id', id);
  }

  getAdminById(id) {
    return this.urls.get('getAdminById').replace(':id', id);
  }

  paymentConfirmedStatus(id) {
    return this.urls.get('paymentConfirmedStatus').replace(':id', id);
  }

  contractSignedStatus(id) {
    return this.urls.get('contractSignedStatus').replace(':id', id);
  }

  waitingForPaymentStatus(id) {
    return this.urls.get('waitingForPaymentStatus').replace(':id', id);
  }

  mappingMeetingStatus(id) {
    return this.urls.get('mappingMeetingStatus').replace(':id', id);
  }

  confirmedStatus(id) {
    return this.urls.get('confirmedStatus').replace(':id', id);
  }

  deleteAdmin(id) {
    return this.urls.get('deleteAdmin').replace(':id', id);
  }

  markedCompanyAsViewed(id) {
    return this.urls.get('viewedCompany').replace(':id', id);
  }

  getMembers(companyId) {
    return this.urls.get('getMembers').replace(':id', companyId);
  }

  deleteMember({ companyId, memberId }) {
    return this.urls.get('deleteMember').replace(':id', companyId).replace(':memberId', memberId);
  }

  getMemberById({ companyId, memberId }) {
    return this.urls.get('getMemberById').replace(':id', companyId).replace(':memberId', memberId);
  }

  createMember(companyId) {
    // eslint-disable-next-line no-console
    console.log('endpoint createMember');
    return this.urls.get('createMember').replace(':id', companyId);
  }

  updateMember({ companyId, memberId }) {
    return this.urls.get('updateMember').replace(':id', companyId).replace(':memberId', memberId);
  }

  getListOfCompanyUsers(id) {
    return this.urls.get('getListOfCompanyUsers').replace(':id', id);
  }

  getMembersCSV(companyId) {
    return this.urls.get('membersCSV').replace(':id', companyId);
  }

  removeCompanyUser(companyId, userId) {
    return this.urls.get('removeCompanyUser').replace(':id', companyId).replace(':userId', userId);
  }

  getCompanyUserById(companyId, userId) {
    return this.urls.get('getCompanyUserById').replace(':id', companyId).replace(':userId', userId);
  }

  createCompanyUser(companyId) {
    return this.urls.get('createCompanyUser').replace(':id', companyId);
  }

  getExtra(companyId) {
    return this.urls.get('extra').replace(':id', companyId);
  }

  getPackage(companyId) {
    return this.urls.get('package').replace(':id', companyId);
  }

  getPriceList(companyId) {
    return this.urls.get('priceList').replace(':id', companyId);
  }

  get getGlobalConfig() {
    const lang = getLocaleFromCookie();
    return this.urls.get('getGlobalConfig').replace(':lang', lang);
  }

  get getHuntAdUploadImageRequest() {
    return this.urls.get('uploadHuntAdRequest');
  }

  getHuntAdRequest(id) {
    return this.urls.get('huntAdRequest').replace(':id', id);
  }

  get huntAdRequest() {
    return this.urls.get('huntAdRequestByAdmin');
  }

  get getAllCompanyMessages() {
    return this.urls.get('getAllCompanyMessages');
  }

  get getAllHunterMessages() {
    return this.urls.get('getAllHunterMessages');
  }

  get getMessageHunter() {
    return this.urls.get('getMessageHunter');
  }

  get getAllMessagesForCompany() {
    return this.urls.get('getAllMessagesForCompany');
  }

  get getAllReceivedMessagesForCompany() {
    return this.urls.get('getAllReceivedMessagesForCompany');
  }

  getHuntAdOfferRequest(companyId, hundAdOfferId) {
    return this.urls
      .get('getHuntAdOfferRequest')
      .replace(':companyId', companyId)
      .replace(':hundAdOfferId', hundAdOfferId);
  }

  get getHuntOrders() {
    return this.urls.get('huntOrder');
  }

  getHuntOrdersByCompany(id) {
    return this.urls.get('huntOrderByCompany').replace(':id', id);
  }

  get getHunterDiary() {
    return this.urls.get('getHunterDiary');
  }

  get getMarkedPlaces() {
    return this.urls.get('getMarkedPlaces');
  }

  getHunterCompanyList() {
    return this.urls.get('getHunterCompanyList');
  }

  get uploadDiaryImage() {
    return this.urls.get('uploadDiaryImage');
  }

  get uploadMarkedPlaceImage() {
    return this.urls.get('uploadMarkedPlaceImage');
  }

  get orderDocuments() {
    return this.urls.get('orderDocuments');
  }
}

export const API_URLS = new API();
