const Svg = ({ width = 346, height = 133 }) => (
  <svg width={width} height={height} viewBox="0 0 346 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M24.8846 54.0968H33.2639L38.4699 64.9162H312.679L312.68 55.1787C312.68 55.1787 322.633 61.1658 345.138 68.162C322.633 75.408 312.68 81.1452 312.68 81.1452L312.679 71.4078H38.4699L33.2639 82.2271H24.8846L30.0907 71.4078H25.5983L20.7641 82.2271H12.9833L17.8175 71.4078H13.5853L8.37925 82.2271H0L6.76786 68.162H7.5734V68.162H6.76787L1.1417e-05 54.0968H8.37926L13.5853 64.9162H17.8174L12.9832 54.0968H20.764L25.5982 64.9162H30.0907L24.8846 54.0968Z" fill="#EBEBE8" />
    <rect x="103.063" width="166.618" height="93.0465" transform="rotate(15 103.063 0)" fill="#E8DCC9" />
    <path d="M78.9813 89.876L239.922 133L178.773 39.3282L78.9813 89.876Z" fill="#F0E6D6" />
    <path d="M264.004 43.124L103.063 0L167.852 80.0859L264.004 43.124Z" fill="#F3ECE0" />
    <path d="M167.033 83.1419C175.614 85.4411 184.43 80.6049 186.669 72.2467C188.909 63.8886 183.692 55.2923 175.112 52.9932C166.531 50.694 157.715 55.5302 155.476 63.8884C153.236 72.2465 158.453 80.8428 167.033 83.1419Z" fill="#D1A377" stroke="#BF8F60" strokeWidth="2" />
    <path d="M167.627 56.2624C168.669 57.3157 169.309 58.7555 169.309 60.3436C169.309 60.7088 169.275 61.0663 169.21 61.4132C168.778 60.448 167.932 59.6784 166.831 59.3834L166.551 60.4303C167.894 60.7904 168.699 62.1576 168.371 63.5024C167.322 65.111 165.496 66.1778 163.414 66.1778V67.2616C167.263 67.2616 170.392 64.1694 170.392 60.3436C170.392 58.457 169.63 56.7468 168.397 55.5L167.627 56.2624Z" fill="#BF8F60" />
    <path fillRule="evenodd" clipRule="evenodd" d="M173.464 64.0666C173.511 63.7909 173.483 63.5051 173.38 63.2418C173.304 63.0491 173.189 62.8685 173.033 62.7127L172.705 62.3843L164.497 70.5957L165.701 71.8C166.607 72.707 168.21 72.0638 169.139 71.6767C169.642 71.467 170.187 71.3272 170.756 71.2536C171.886 71.868 172.536 72.6093 172.834 73.4304C172.536 73.3955 172.233 73.3776 171.926 73.3776C169.191 73.3776 166.788 74.7966 165.412 76.9388C166.282 78.293 167.562 79.3583 169.077 79.9587C169.68 80.1975 170.32 80.3628 170.986 80.4434C171.294 80.4808 171.607 80.5 171.926 80.5C175.295 80.5 178.161 78.3461 179.223 75.3396C179.25 75.2639 179.276 75.1876 179.3 75.1109C179.536 74.369 179.664 73.5785 179.664 72.7582C179.664 69.2342 177.31 66.2601 174.09 65.3234C173.82 65.2448 173.543 65.1805 173.262 65.1315C173.13 65.1085 172.997 65.0889 172.863 65.0727L173.033 64.9024C173.269 64.6662 173.413 64.3727 173.464 64.0666ZM174.078 76.4868C174.531 74.6809 174.127 72.683 172.578 71.2334C174.758 71.4495 177.003 72.4923 178.43 74.1756C178.37 74.45 178.294 74.718 178.202 74.9783C177.339 77.4203 175.093 79.2062 172.409 79.3989C173.228 78.5894 173.808 77.5648 174.078 76.4868Z" fill="#BF8F60" />
  </svg>
);

export default Svg;
