import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const hunterApi = {
  updateHunterRequest(id, data) {
    return http({ url: API_URLS.updateHunter(id), method: 'PATCH', data });
  },
  verifyHunterRequest(id) {
    return http({ url: API_URLS.verifyHunter(id), method: 'PATCH' });
  },
  getCompanyListRequest() {
    return http({ url: API_URLS.getHunterCompanyList() });
  },
};
