// getAllMarkedPlaces
export const GET_ALL_MARKEDPLACES = 'markedPlace/GET_ALL_MARKEDPLACES';
export const GET_ALL_MARKEDPLACES_SUCCESS = 'markedPlace/GET_ALL_MARKEDPLACES_SUCCESS';
export const GET_ALL_MARKEDPLACES_FAIL = 'markedPlace/GET_ALL_MARKEDPLACES_FAIL';
// getMarkedPlaceById
export const GET_MARKEDPLACE_BY_ID = 'markedPlace/GET_MARKEDPLACE_BY_ID';
export const GET_MARKEDPLACE_BY_ID_SUCCESS = 'markedPlace/GET_MARKEDPLACE_BY_ID_SUCCESS';
export const GET_MARKEDPLACE_BY_ID_FAIL = 'markedPlace/GET_MARKEDPLACE_BY_ID_FAIL';
// createMarkedPlace
export const CREATE_MARKEDPLACE = 'markedPlace/CREATE_MARKEDPLACE';
export const CREATE_MARKEDPLACE_SUCCESS = 'markedPlace/CREATE_MARKEDPLACE_SUCCESS';
export const CREATE_MARKEDPLACE_FAIL = 'markedPlace/CREATE_MARKEDPLACE_FAIL';
// updateMarkedPlace
export const UPDATE_MARKEDPLACE = 'markedPlace/UPDATE_MARKEDPLACE';
export const UPDATE_MARKEDPLACE_SUCCESS = 'markedPlace/UPDATE_MARKEDPLACE_SUCCESS';
export const UPDATE_MARKEDPLACE_FAIL = 'markedPlace/UPDATE_MARKEDPLACE_FAIL';
// removeMarkedPlace
export const REMOVE_MARKEDPLACE = 'markedPlace/REMOVE_MARKEDPLACE';
export const REMOVE_MARKEDPLACE_SUCCESS = 'markedPlace/REMOVE_MARKEDPLACE_SUCCESS';
export const REMOVE_MARKEDPLACE_FAIL = 'markedPlace/REMOVE_MARKEDPLACE_FAIL';
// shareDiary
export const SHARE_MARKED_PLACE = 'diary/SHARE_MARKED_PLACE';
export const SHARE_MARKED_PLACE_SUCCESS = 'diary/SHARE_MARKED_PLACE_SUCCESS';
export const SHARE_MARKED_PLACE_FAIL = 'diary/SHARE_MARKED_PLACE_FAIL';
// resetSelectedMarkedPlace
export const RESET_SELECTED_MARKEDPLACE = 'markedPlace/RESET_SELECTED_MARKEDPLACE';
