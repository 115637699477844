// initialApp
export const INITIAL_APP = 'app/INITIAL_APP';
export const INITIAL_APP_SUCCESS = 'app/INITIAL_APP_SUCCESS';
export const INITIAL_APP_FAIL = 'app/INITIAL_APP_FAIL';

// getMe
export const GET_ME = 'app/GET_ME';
export const GET_ME_SUCCESS = 'app/GET_ME_SUCCESS';
export const GET_ME_FAIL = 'app/GET_ME_FAIL';

// getGlobalConfig
export const GET_GLOBAL_CONFIG = 'app/GET_GLOBAL_CONFIG';
export const GET_GLOBAL_CONFIG_SUCCESS = 'app/GET_GLOBAL_CONFIG_SUCCESS';
export const GET_GLOBAL_CONFIG_FAIL = 'app/GET_GLOBAL_CONFIG_FAIL';

export const SET_TOAST = 'app/SET_TOAST';
