const Svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect width="32" height="32" rx="5" fill="white" />
      <rect x="7.28418" y="15.1729" width="40" height="40" rx="3" transform="rotate(45 7.28418 15.1729)" fill="#AEB8AF" />
      <rect x="18.2861" y="9.25781" width="40" height="40" rx="3" transform="rotate(29.3081 18.2861 9.25781)" fill="#2C3831" />
      <circle cx="8.5" cy="7.5" r="3.5" fill="#AEB8AF" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Svg;
