import { ADMIN_PREFIX, HUNTER_PREFIX, USER_PREFIX } from 'constants/roles';

export const useHeaderLabel = (currentUser, t) => {
  if (!currentUser) return '';

  if (currentUser.id.includes(ADMIN_PREFIX)) return t('admin_label');
  if (currentUser.id.includes(USER_PREFIX)) return t('user_label');
  if (currentUser.id.includes(HUNTER_PREFIX)) return t('hunter_label');

  return '';
};
