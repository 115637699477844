import * as types from './types';

import { initialStore } from '../initial-store';

export const adminReducer = (state = initialStore.admin, { payload, type }) => {
  switch (type) {
    case types.GET_ADMIN_BY_ID: {
      return { ...state, isFetching: true, admin: null };
    }
    case types.GET_ADMIN_BY_ID_SUCCESS: {
      const fields = payload.role.reduce((acc, el) => ({ ...acc, [el]: true }), {});
      return { ...state, isFetching: false, admin: { ...payload, ...fields } };
    }
    case types.GET_ADMIN_BY_ID_FAIL: {
      return { ...state, isFetching: false, admin: null };
    }
    case types.GET_ADMINS_LIST: {
      const admins = { ...state.admins, loading: true, page: payload.page };
      return { ...state, admins };
    }
    case types.GET_ADMINS_LIST_SUCCESS: {
      const admins = { ...state.admins, ...payload, loading: false };
      return { ...state, admins };
    }

    case types.RESET_ADMIN_FIELD: {
      return { ...state, admin: null };
    }

    case types.CREATE_ADMIN:
    case types.CREATE_ADMIN_SUCCESS:
    case types.CREATE_ADMIN_FAIL:
    case types.UPDATE_ADMIN:
    case types.UPDATE_ADMIN_SUCCESS:
    case types.UPDATE_ADMIN_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
