import { classNames } from 'utils/classNames';

const CustomCheckbox = ({
  text,
  className,
  id,
  error,
  touched,
  value,
  name,
  onChange,
}) => (
  <div className={classNames('checkbox-container', className)}>
    <div className="input-wrapper">
      <input
        id={id || text}
        type="checkbox"
        value={value}
        checked={value}
        name={name}
        onChange={onChange}
        className={
          classNames({
            'checkbox-container__checkbox': true,
            'checkbox-container__error': error && touched,
          })
        }
      />
    </div>
    <label htmlFor={id || text} className="checkbox-container__label">{text}</label>
  </div>
);

export default CustomCheckbox;
