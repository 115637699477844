import { memo } from 'react';
import { Text } from 'components';
import { classNames } from 'utils/classNames';

export default memo(({
  className,
  name,
  rows,
  cols,
  placeholder,
  disabled,
  value,
  onChange,
  id,
  error,
  touched,
  label,
}) => (
  <div className={classNames('text-area-wrapper', className)}>
    {label && <label className="text-area-wrapper__label" htmlFor={id}>{label}</label>}
    <textarea
      className={
        classNames('text-area-wrapper__text-area',
          { 'text-area-wrapper__text-area-error': error && touched })
      }
      id={id}
      name={name}
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
    {touched && error && (
      <div className="text-area-wrapper__error-span">
        <Text type="sm-paragraph" color="meat">{error}</Text>
      </div>
    )}
  </div>
));
