import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import * as types from './types';
import { companyApi } from './api';
import { setToast } from '../app/actions';
import {
  submitCompanySuccess,
  submitCompany,
  getCompanySuccess,
  getCompanyFailList,
  getCompanyListSuccess,
  getCompanyMapSuccess,
  updateCompanyMapSuccess,
  getCompanyUnViewedCountsSuccess,
  getCompanyUnViewedCountsFail,
} from './actions';

function* updateCompany({ payload }) {
  try {
    const { companyData, status } = payload;
    const currentUser = yield select((s) => s.auth.currentUser);
    const response = yield call(companyApi.updateCompanyRequest, currentUser.company.id, companyData);

    yield put(submitCompanySuccess({ ...response.data }));
    if (status) yield put(submitCompany(status));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* updateCompanyStatus() {
  try {
    const currentUser = yield select((s) => s.auth.currentUser);
    yield call(companyApi.updateCompanyStatusRequest, currentUser.company.id);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getCompany({ payload }) {
  try {
    const response = yield call(companyApi.getCompanyRequest, payload.id);
    yield put(getCompanySuccess(response.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getCompanyList({ payload }) {
  try {
    const response = yield call(companyApi.getCompanyListRequest, payload);

    yield put(getCompanyListSuccess(response.data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
    yield put(getCompanyFailList());
  }
}

function* getCompanyMap({ payload }) {
  try {
    const { data } = yield call(companyApi.getCompanyMapRequest, payload.id);
    yield put(getCompanyMapSuccess(data));
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* updateCompanyMap({ payload }) {
  try {
    const { id, body, callback } = payload;
    const { data } = yield call(companyApi.updateCompanyMapRequest, id, body);

    yield put(updateCompanyMapSuccess({ ...data.map }));
    callback();
    localStorage.removeItem(id);
  } catch (error) {
    const message = error.response && error.response.data ? error.response.data.message : '';

    yield put(setToast({
      type: 'error',
      text: typeof message === 'string' ? message : message.toString(),
      options: {},
    }));
  }
}

function* getCompanyUnViewedCounts({ payload }) {
  try {
    const response = yield call(companyApi.getCompanyUnViewedCountsRequest, payload);

    yield put(getCompanyUnViewedCountsSuccess(response.data));
  } catch (error) {
    yield put(getCompanyUnViewedCountsFail());
  }
}

function* company() {
  yield takeLatest(types.UPDATE_COMPANY, updateCompany);
  yield takeLatest(types.SUBMIT_COMPANY, updateCompanyStatus);
  yield takeLatest(types.GET_COMPANY, getCompany);
  yield takeLatest(types.GET_COMPANY_MAP, getCompanyMap);
  yield takeLatest(types.UPDATE_COMPANY_MAP, updateCompanyMap);
  yield takeLatest(types.GET_COMPANY_LIST, getCompanyList);
  yield takeLatest(types.GET_COMPANY_UN_VIEWED_COUNTS, getCompanyUnViewedCounts);
}

export default company;
