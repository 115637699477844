import * as types from './types';

import { initialStore } from '../initial-store';

export const messageReducer = (state = initialStore.messages, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_MESSAGES_FOR_COMPANY:
    case types.GET_ALL_COMPANY_MESSAGES: {
      return { ...state, isFetching: true };
    }

    case types.GET_ALL_MESSAGES_FOR_COMPANY_SUCCESS:
    case types.GET_ALL_COMPANY_MESSAGES_SUCCESS: {
      const { rows, count, unViewedCount } = payload;
      return {
        ...state,
        isFetching: false,
        messages: rows,
        count,
        unViewed: unViewedCount || null,
      };
    }

    case types.GET_ALL_MESSAGES_FOR_COMPANY_FAIL:
    case types.GET_ALL_COMPANY_MESSAGES_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_ALL_HUNTER_MESSAGES: {
      return { ...state, isFetching: true };
    }
    case types.GET_ALL_HUNTER_MESSAGES_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        isFetching: false,
        messages: [
          ...data.rows,
        ],
        count: data.count,
      };
    }
    case types.GET_ALL_HUNTER_MESSAGES_FAIL: {
      return { ...state };
    }

    case types.GET_MESSAGE_BY_ID: {
      return { ...state, isFetching: true };
    }
    case types.GET_MESSAGE_BY_ID_SUCCESS: {
      return { ...state, selectedMessage: payload, isFetching: false };
    }
    case types.GET_MESSAGE_BY_ID_FAIL: {
      return { ...state, isFetching: false };
    }

    case types.GET_RECIPIENT_INFO: {
      return { ...state, isFetchingRecipientInfo: true };
    }
    case types.GET_RECIPIENT_INFO_SUCCESS: {
      return {
        ...state,
        recipientInfo: payload.filter((e) => e.viewed), // we need only viewed records
        isFetchingRecipientInfo: false,
      };
    }
    case types.GET_RECIPIENT_INFO_FAIL: {
      return { ...state, isFetchingRecipientInfo: false };
    }

    case types.CREATE_MESSAGE:
    case types.CREATE_MESSAGE_SUCCESS:
    case types.CREATE_MESSAGE_FAIL: {
      return { ...state };
    }

    default:
      return state;
  }
};
