// getAllPriceLists
export const GET_ALL_PRICE_LISTS = 'pricelist/GET_ALL_PRICE_LISTS';
export const GET_ALL_PRICE_LISTS_SUCCESS = 'pricelist/GET_ALL_PRICE_LISTS_SUCCESS';
export const GET_ALL_PRICE_LISTS_FAIL = 'pricelist/GET_ALL_PRICE_LISTS_FAIL';
// getPriceListById
export const GET_PRICE_LIST_BY_ID = 'pricelist/GET_PRICE_LIST_BY_ID';
export const GET_PRICE_LIST_BY_ID_SUCCESS = 'pricelist/GET_PRICE_LIST_BY_ID_SUCCESS';
export const GET_PRICE_LIST_BY_ID_FAIL = 'pricelist/GET_PRICE_LIST_BY_ID_FAIL';
// createPriceList
export const CREATE_PRICE_LIST = 'pricelist/CREATE_PRICE_LIST';
export const CREATE_PRICE_LIST_SUCCESS = 'pricelist/CREATE_PRICE_LIST_SUCCESS';
export const CREATE_PRICE_LIST_FAIL = 'pricelist/CREATE_PRICE_LIST_FAIL';
// updatePriceList
export const UPDATE_PRICE_LIST = 'pricelist/UPDATE_PRICE_LIST';
export const UPDATE_PRICE_LIST_SUCCESS = 'pricelist/UPDATE_PRICE_LIST_SUCCESS';
export const UPDATE_PRICE_LIST_FAIL = 'pricelist/UPDATE_PRICE_LIST_FAIL';
// removePriceList
export const REMOVE_PRICE_LIST = 'pricelist/REMOVE_PRICE_LIST';
export const REMOVE_PRICE_LIST_SUCCESS = 'pricelist/REMOVE_PRICE_LIST_SUCCESS';
export const REMOVE_PRICE_LIST_FAIL = 'pricelist/REMOVE_PRICE_LIST_FAIL';
// other
export const RESET_PRICE_LIST_FIELDS = 'admin/RESET_PRICE_LIST_FIELDS';
export const CHANGE_PRICE_LIST_FIELDS = 'admin/CHANGE_PRICE_LIST_FIELDS';
export const SET_INTERVAL = 'admin/SET_INTERVAL';
export const ADD_NEW_INTERVAL = 'admin/ADD_NEW_INTERVAL';
