import {
  call, takeLatest, put, select,
} from 'redux-saga/effects';

import { HUNTER_PREFIX, USER_PREFIX } from 'constants/roles';
import { setToast } from '../app/actions';
import * as types from './types';
import * as actions from './actions';
import { messageApi } from './api';

function* getAllCompanyMessages({ payload }) {
  try {
    const response = yield call(messageApi.getAllCompanyMessagesRequest, payload);

    yield put(actions.getAllCompanyMessagesSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllCompanyMessagesFail(error));
  }
}

function* getAllHunterMessages({ payload }) {
  try {
    const response = yield call(messageApi.getAllHunterMessagesRequest, payload);
    yield put(actions.getAllHunterMessagesSuccess(response));
  } catch (error) {
    yield put(actions.getAllHunterMessagesFail(error));
  }
}

function* getMessageById({ payload }) {
  const { id, received } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);

    const func = currentUser.id.includes(HUNTER_PREFIX)
      ? messageApi.getMessageByIdHunterRequest
      : messageApi.getMessageByIdCompanyUserRequest;

    const response = yield call(func, { id, received });

    yield put(actions.getMessageByIdSuccess(response.data));
  } catch (error) {
    yield put(actions.getMessageByIdFail(error));
  }
}

function* getAllMessagesForCompany({ payload }) {
  try {
    const { received, params } = payload;

    const func = received
      ? messageApi.getAllReceivedMessagesForCompanyRequest
      : messageApi.getAllMessagesForCompanyRequest;

    const response = yield call(func, params);

    yield put(actions.getAllMessagesForCompanySuccess(response.data));
  } catch (error) {
    yield put(actions.getAllMessagesForCompanyFail(error));
  }
}

function* viewByCompanyUser({ payload }) {
  const { cb } = payload;
  try {
    const selectedMessage = yield select((s) => s.messages.selectedMessage);
    yield call(messageApi.viewByCompanyUserRequest, { id: selectedMessage.id });

    if (cb && typeof cb === 'function') yield cb();
  } catch (error) {
    const msg = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof msg === 'string' ? msg : msg.toString(),
      options: {},
    }));
  }
}

function* viewByHunter({ payload }) {
  const { cb, id } = payload;
  try {
    yield call(messageApi.viewByHunterRequest, { id });

    if (cb && typeof cb === 'function') yield cb();
  } catch (error) {
    const msg = error.response && error.response.data ? error.response.data.message : '';
    yield put(setToast({
      type: 'error',
      text: typeof msg === 'string' ? msg : msg.toString(),
      options: {},
    }));
  }
}

function* getRecipientInfo({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(messageApi.getRecipientInfoRequest, { id });

    yield put(actions.getRecipientInfoSuccess(response.data));
  } catch (error) {
    const msg = error.response && error.response.data ? error.response.data.message : '';
    yield put(actions.getRecipientInfoSuccess(msg));
  }
}

function* createMessage({ payload }) {
  const { data, cb } = payload;
  try {
    const currentUser = yield select((s) => s.auth.currentUser);

    const func = currentUser.id.includes(USER_PREFIX)
      ? messageApi.createCompanyMessageRequest
      : () => {}; // TODO add create message function for another userType

    const response = yield call(func, { data });

    if (cb && typeof cb === 'function') yield cb();
    yield put(actions.createMessageSuccess(response.data));
  } catch (error) {
    yield put(actions.createMessageFail(error));
  }
}

function* message() {
  yield takeLatest(types.GET_ALL_COMPANY_MESSAGES, getAllCompanyMessages);
  yield takeLatest(types.GET_ALL_HUNTER_MESSAGES, getAllHunterMessages);
  yield takeLatest(types.GET_MESSAGE_BY_ID, getMessageById);
  yield takeLatest(types.GET_ALL_MESSAGES_FOR_COMPANY, getAllMessagesForCompany);
  yield takeLatest(types.VIEW_BY_COMPANY_USER, viewByCompanyUser);
  yield takeLatest(types.GET_RECIPIENT_INFO, getRecipientInfo);
  yield takeLatest(types.CREATE_MESSAGE, createMessage);
  yield takeLatest(types.VIEW_BY_HUNTER, viewByHunter);
}

export default message;
