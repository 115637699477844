import axios from 'axios';

import { getTokenFromCookie } from 'utils/cookie-actions';

const instance = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 10000,
  headers: { 'content-type': 'application/json' },
};

export const AxiosInstance = axios.create(instance);

AxiosInstance.interceptors.request.use(
  (config) => {
    // case for exclude Authorization property from request
    if (config.headers.Authorization === false) {
      const { Authorization, ...rest } = config.headers;
      return {
        ...config,
        headers: { ...rest },
      };
    }
    // check for Authorization property
    if (config.headers.Authorization) return config;

    const token = getTokenFromCookie();
    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  },
  (error) => Promise.reject(error),
);

AxiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject(error.response ? error : { ...error, response: 'Some internal error occurred' }),
);

export const http = async ({
  url, method = 'GET', params, data, ...rest
}) => {
  const i = await AxiosInstance({
    url, method, params, data, ...rest,
  });
  return i;
};
