/* eslint-disable no-useless-escape */
export const ACCESS_TOKEN_NAME = 'access_token';
export const LOCALE = 'next-i18next';
export const BOOKED_OFFER = 'booked-offer';

export const setTokenToCookie = (token, nextjs = false) => {
  const updatedCookie = `${encodeURIComponent(ACCESS_TOKEN_NAME)}=${encodeURIComponent(token)}`;
  const domain = nextjs ? process.env.NEXT_PUBLIC_DOMAIN_NAME : process.env.REACT_APP_DOMAIN_NAME;
  document.cookie = `${updatedCookie}; domain=${domain}; path=/; SameSite=None; Secure=true`;
};

export const getTokenFromCookie = () => {
  if (!process.browser) return null;
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${ACCESS_TOKEN_NAME.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));

  const result = matches && decodeURIComponent(matches[1]);
  return !result || result === 'null' ? undefined : result;
};

export const getLocaleFromCookie = () => {
  const defaultLang = 'hu';
  if (!process.browser) return defaultLang;
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${LOCALE.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));

  const result = matches && decodeURIComponent(matches[1]);
  return !result || result === 'null' ? defaultLang : result;
};

export const setLocaleToCookie = (lang, nextjs = false) => {
  const updatedCookie = `${encodeURIComponent(LOCALE)}=${encodeURIComponent(lang)}`;
  const domain = nextjs ? process.env.NEXT_PUBLIC_DOMAIN_NAME : process.env.REACT_APP_DOMAIN_NAME;
  document.cookie = `${updatedCookie}; domain=${domain}; path=/`;
};

export const setOfferToCookie = (offer, nextjs = false) => {
  const updatedCookie = `${encodeURIComponent(BOOKED_OFFER)}=${offer ? JSON.stringify(offer) : null}`;
  const domain = nextjs ? process.env.NEXT_PUBLIC_DOMAIN_NAME : process.env.REACT_APP_DOMAIN_NAME;
  document.cookie = `${updatedCookie}; domain=${domain}; path=/; SameSite=None; Secure=true`;
};

export const getOfferFromCookie = () => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${BOOKED_OFFER.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
