import { http } from 'utils/axios';
import { API_URLS } from 'constants/api-endpoints';

export const appApi = {
  getMeRequest() {
    return http({ url: API_URLS.getMe });
  },
  getGlobalConfigRequest() {
    return http({
      url: API_URLS.getGlobalConfig,
      headers: {
        'content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_MASTER_KEY,
      },
    });
  },
};
